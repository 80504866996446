import passwordRevealInput from '../../shared/password_reveal_input'

export const wifi_networks = {
	new() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	show() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	create() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	update() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	wireAndUpdateForm() {
		$('#wifi_network_security_type,' +
		  '[id*=wifi_network_accept_], ' +
		  '#wifi_network_eap_fast_use_pac, ' +
		  '#wifi_network_eap_fast_provision_pac, ' +
		  '#wifi_network_connection_type, ' +
		  '#wifi_network_proxy_type, ' +
		  '#wifi_network_is_hotspot, ' +
		  '#wifi_network_one_time_password').change(this.updateForm);

		this.updateForm();
	},
	updateForm: function() {
		const select = function(name) {
			return $('div.form-group.wifi_network_' + name);
		};

		// Security

		const securityType = $('#wifi_network_security_type').val();
		const isEnterprise = securityType === 'enterprise';
		const tls = $('#wifi_network_accept_tls').is(':checked') && isEnterprise;
		const leap    = $('#wifi_network_accept_leap').is(':checked') && isEnterprise;
		const eapSim  = $('#wifi_network_accept_eap_sim').is(':checked') && isEnterprise;
		const eapAka  = $('#wifi_network_accept_eap_aka').is(':checked') && isEnterprise;
		const eapFast = $('#wifi_network_accept_eap_fast').is(':checked') && isEnterprise;
		const ttls    = $('#wifi_network_accept_ttls').is(':checked') && isEnterprise;
		const peap    = $('#wifi_network_accept_peap').is(':checked') && isEnterprise;

		const tlsTrustedCertOptions = $('.wifi_network_tls_trusted_server_names, .wifi_network_tls_allow_trust_exceptions')
		isEnterprise ? tlsTrustedCertOptions.show() : tlsTrustedCertOptions.hide()

		const eapTypes = $('#wifi_network_accepted_eap_types');
		if (securityType === 'personal') {
			eapTypes.hide();
		} else {
			eapTypes.show();
		}

		const eapFastConfig = $('#wifi_network_eap_fast_config');
		if (eapFast) {
			eapFastConfig.show();
		} else {
			eapFastConfig.hide();
		}

		const usePac = $('#wifi_network_eap_fast_use_pac');
		const provisionPac            = $('#wifi_network_eap_fast_provision_pac');
		const provisionPacAnonymously = $('#wifi_network_eap_fast_provision_pac_anonymously');

		if (usePac.is(':checked')) {
			provisionPac.removeAttr('disabled');
			if (provisionPac.is(':checked')) {
				provisionPacAnonymously.removeAttr('disabled');
			} else {
				provisionPacAnonymously.prop('checked', false).attr('disabled', true);
			}
		} else {
			provisionPac.prop('checked', false).attr('disabled', true);
			provisionPacAnonymously.prop('checked', false).attr('disabled', true);
		}

		if (leap || eapFast || ttls || peap) {
			select('username').show();
			select('one_time_password').show();
		} else {
			select('username').hide();
			select('one_time_password').hide();
		}


		const password = select('password');
		if (leap || eapFast || ttls || peap || securityType === 'personal') {
			password.show();

			const passwordInput = password.find('input:not(#wifi_network_fake_password)');
			if ($('#wifi_network_one_time_password').is(':checked')) {
				passwordInput.attr('disabled', true).val('');
			} else {
				passwordInput.removeAttr('disabled');
			}
		} else {
			password.hide();
		}

		const clientCertificate = select('certificate_id');
		if (tls || eapFast || ttls || peap) {
			clientCertificate.show();
		} else {
			clientCertificate.hide();
		}

		const innerAuthentication = select('ttls_inner_authentication');
		if (ttls) {
			innerAuthentication.show();
		} else {
			innerAuthentication.hide();
		}

		const outerIdentity = select('outer_identity');
		if (ttls || peap || eapFast) {
			outerIdentity.show();
		} else {
			outerIdentity.hide();
		}

		const tlsMinimum = select('tls_minimum_version');
		const tlsMaximum = select('tls_maximum_version');
		if (tls || eapFast || ttls || peap) {
			tlsMinimum.show();
			tlsMaximum.show();
		} else {
			tlsMinimum.hide();
			tlsMaximum.hide();
		}

		const allowRand = select('eap_sim_allow_two_rands');
		if (eapSim) {
			allowRand.show();
		} else {
			allowRand.hide();
		}


		// HotSpot 2.0

		const hotspotEnabled = $('#wifi_network_is_hotspot').val() == "true";

		if (hotspotEnabled) {
			select('domain_name').show();
			select('hessid').show();
			select('service_provider_roaming_enabled').show();
			select('roaming_consortium_ois').show();
			select('nai_realm_names').show();
			select('mcc_and_mncs').show();
			select('displayed_operator_name').show();
		} else {
			select('domain_name').hide();
			select('hessid').hide();
			select('service_provider_roaming_enabled').hide();
			select('roaming_consortium_ois').hide();
			select('nai_realm_names').hide();
			select('mcc_and_mncs').hide();
			select('displayed_operator_name').hide();
		}

		// Proxy Type

		const proxyType = $('#wifi_network_proxy_type').val();

		switch (proxyType) {
			case 'none':
				select('proxy_server').hide();
				select('proxy_port').hide();
				select('proxy_username').hide();
				select('proxy_password').hide();
				select('proxy_url').hide();
				break;
			case 'manual':
				select('proxy_server').show();
				select('proxy_port').show();
				select('proxy_username').show();
				select('proxy_password').show();
				select('proxy_url').hide();
				break;
			case 'auto':
				select('proxy_server').hide();
				select('proxy_port').hide();
				select('proxy_username').hide();
				select('proxy_password').hide();
				select('proxy_url').show();
				break;
		}
	}
};