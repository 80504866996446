import passwordRevealInput from '../../shared/password_reveal_input'
import { hide, show } from '../../shared/functions'

export const webhook_endpoints = {
    new() {
        this.wireAndUpdateForm();
        passwordRevealInput()
    },
    show() {
        this.wireAndUpdateForm();
        passwordRevealInput()
    },
    create() {
        this.wireAndUpdateForm();
        passwordRevealInput()
    },
    update() {
        this.wireAndUpdateForm();
        passwordRevealInput()
    },
    wireAndUpdateForm() {
        const authTypeSelect = document.getElementById('webhook_endpoint_authentication')

        authTypeSelect.addEventListener('change', this.updateForm)

        this.updateForm()
    },
    updateForm() {
        const authType      = document.getElementById('webhook_endpoint_authentication').value
        const basicAuthRow  = document.getElementById('basic_auth_row')
        const headerAuthRow = document.getElementById('header_auth_row')
        const headersField  = document.getElementById('headers_text')
        const editor        = ace.edit("editor")

        editor.setTheme("ace/theme/tomorrow")
        editor.session.setUseWorker(false)
        editor.session.setMode("ace/mode/json")

        editor.getSession().setValue(headersField.value)
        editor.getSession().on('change', () => {
            headersField.value = editor.getSession().getValue()
        })

        switch (authType) {
            case 'none':
                hide(basicAuthRow)
                hide(headerAuthRow)
                document.querySelectorAll('#basic_auth_row input, header_auth_row input').forEach(input => input.value = '')
                editor.getSession().setValue('');
                break
            case 'basic':
                show(basicAuthRow)
                hide(headerAuthRow)
                headerAuthRow.querySelectorAll('input').forEach(input => input.value = '')
                editor.getSession().setValue('');
                break
            case 'header':
                hide(basicAuthRow)
                show(headerAuthRow)
                basicAuthRow.querySelectorAll('input').forEach(input => input.value = '')
                break
        }
    }
};