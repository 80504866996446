export const gatekeeper_policies = {
    show() {
        const enableAssessmentCheckbox = document.getElementById("gatekeeper_policy_enable_assessment")

        this.toggleInputDisabled(!enableAssessmentCheckbox.checked)

        enableAssessmentCheckbox.addEventListener("change", (e) => {
            this.toggleInputDisabled(!e.target.checked)
        })
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },

    toggleInputDisabled(isDisabled) {
        document.getElementById("gatekeeper_policy_allow_identified_developers").disabled = isDisabled
    }
};