import React from "react";
import { devices as ajaxClient } from '../../ajax_clients/devices'
import { bindMethods } from "../../shared/functions";
import FormGroup from "../forms/form_group";
import RadioButtonGroup from "../forms/radio_button_group";
import Select from "../shared/select";
import { visitURL } from "../../shared/query";

export default class OSUpdate extends React.Component {

    constructor(props) {
        super(props)
        let defaultOSUpdateMode
        // If we are updating multiple devices we should use the default install mode
        // otherwise we need to choose a default based on what os update modes are visible to the user
        const allDeviceOSTypes  = [...new Set(props.devices.map(d => d.os))]
        let deviceOS = allDeviceOSTypes && allDeviceOSTypes.length === 1 ? allDeviceOSTypes[0] : null

        if (!deviceOS) {
            defaultOSUpdateMode = "schedule_install"
        } else if (deviceOS === "macOS") {
            defaultOSUpdateMode = 'smart_update'
        } else {
            defaultOSUpdateMode = "install_asap"
        }

        this.state = {
            deviceOS,
            deviceIds: props.devices.map(d => d.id),
            osUpdateMode: defaultOSUpdateMode,
            maxUserDeferrals: 3,
            versionType: 'minor'
        }

        if (this.props.osOptions !== undefined && this.props.osOptions.length > 0) {
            this.state.version = this.props.osOptions[0].value
        }

        bindMethods(this);
    }

    updateOSUpdateMode({ value: osUpdateMode }) {
        this.setState({ osUpdateMode: osUpdateMode })
    }

    updateAllowedDeferrals(e) {
        this.setState({ maxUserDeferrals: e.target.value })
    }

    updateOSVersion(value) {
        this.setState({ version: value })
    }

    updateOSType({ value }) {
        this.setState({ versionType: value })
    }

    goBack() {
        if (this.state.deviceIds.length === 1) {
            visitURL(AdminRoutes.adminDevicePath(this.state.deviceIds[0], { format: null }))
        } else {
            visitURL(AdminRoutes.adminDevicesPath({ format: null }))
        }
    }

    cancel(e) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel()
        } else {
            this.goBack()
        }
    }


    submit(e) {
        e.preventDefault();
        const { osUpdateMode: osUpdateMode, maxUserDeferrals, version, versionType } = this.state;
        const options = { osUpdateMode: osUpdateMode, maxUserDeferrals, version, versionType, flash: this.props.flash }

        ajaxClient.pushOSUpdate(this.state.deviceIds, options).then(() => {
            if (this.props.onOSUpdate) {
                this.props.onOSUpdate()
            } else {
                this.goBack()
            }
        }).catch(({ responseJSON }) => {
            if (responseJSON.error) {
                addFlashAlert('error', responseJSON.error.message)
            }
            if (this.props.onError) {
                this.props.onError(responseJSON.error)
            }
        })
    }

    renderAllowedDeferrals() {
        if (this.state.osUpdateMode === 'smart_update') {
            return <FormGroup title="Allowed deferrals">
                <select onChange={ this.updateAllowedDeferrals } value={ this.state.maxUserDeferrals }>
                    <option value="none">None</option>
                    {
                        Array.from(
                            Array(10),
                            (_, x) => x + 1).map(i => <option key={ i } value={ i }>{ i }</option>)
                    }
                </select>
            </FormGroup>
        }
    }

    renderOsOptions() {
        if (this.state.version !== undefined) {
            return <FormGroup title={ "OS Update version" }>
                <Select
                    name={ 'version' }
                    options={ this.props.osOptions }
                    value={ this.state.version }
                    selectedValue={ this.state.version }
                    onChange={ this.updateOSVersion }
                />
            </FormGroup>
        }
    }

    renderOSVersioning() {
        if (this.state.version === undefined) {
            return <RadioButtonGroup
                title={ 'Update Version types' }
                value={ this.state.versionType }
                name={ 'versionType' }
                onChange={ this.updateOSType }
                options={
                    [
                        {
                            text: "Latest Minor Version",
                            value: "minor",
                            hint: "Updates to the latest minor version i.e. 12.5 -> 12.6.1"
                        },
                        {
                            text: "Latest Major Version",
                            value: "major",
                            hint: "Updates to the latest major version i.e. 12.5 -> 13.0.1"
                        },
                    ]

                }
            />
        }
    }

    renderUpdateModes() {

        if (!this.state.deviceOS) {
            return null
        }

        const osUpdateOptions = [
            {
                text: "Smart Update (Install Later)",
                value: "smart_update",
                hint: "Install the update when macOS deems it to be an opportune time.",
                supportedOS: ["macOS"]
            },
            {
                text: "Notify Only",
                value: "notify_only",
                hint: "Download the software update and notify the user through the App Store.",
                supportedOS: ["macOS"]
            },
            {
                text: "Download Only",
                value: "download_only",
                hint: "Download the software update without installing it.",
                supportedOS: ["iOS", "macOS", "tvOS"]
            },
            {
                text: "Install As Soon As Possible",
                value: "install_asap",
                hint: "When the OS update has finished downloading, the user will be prompted and shown a timer before a restart occurs.",
                supportedOS: ["iOS", "macOS", "tvOS"]
            },
            {
                text: 'Force Update',
                value: 'force_update',
                hint: "Download and install the update immediately.",
                supportedOS: ["macOS"]
            }
        ].filter(updateMode => updateMode.supportedOS.includes(this.state.deviceOS))

        return <RadioButtonGroup
            title={ 'OS Update Mode' }
            value={ this.state.osUpdateMode }
            name={ 'mode' }
            onChange={ this.updateOSUpdateMode }
            options={ osUpdateOptions }
        />
    }

    render() {
        return <div className="os-update multi-select-panel">
            <h1>Update OS Version</h1>

            <div className="well">
                <p>
                    This will send an OS update command to the selected devices.
                </p>
                <form className="simple_form form-horizontal">
                    { this.renderOsOptions() }
                    { this.renderOSVersioning() }
                    { this.renderUpdateModes() }
                    { this.renderAllowedDeferrals() }
                </form>

                <div className="buttons">
                    <a href="#cancel" className="btn btn-default" onClick={ this.cancel }>Cancel</a>
                    <a href="#submit" className="btn btn-primary" onClick={ this.submit }>Update Devices</a>
                </div>
            </div>
        </div>
    }
}