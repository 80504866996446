import React, { useCallback, useMemo } from 'react'
import DynamicList from '../shared/dynamic_list'

function LaunchdConfigurations(props) {
  const { data, policy, meta: { options } } = props

  const launchdConfigurations = data?.launchdConfigurations || []
  const stateItems = launchdConfigurations.map((item) => {
    return {
      id: item.id,
      errors: item.errors,
      asset_data: item.assetData,
      asset_data_cache: item.assetDataCache,
      context: item.context || "",
    }
  })

  const [state, setState] = React.useState({items: stateItems})

  const onAddItem = useCallback(() => {
    const newItem = {
      id: null,
      asset_data: "",
      asset_data_cache: "",
      context: "",
    }
    setState({ items: [...state.items, newItem]})
  }, [state.items])

  const onRemoveItem = useCallback((removedItem, removedIndex) => {
    if (removedItem.id) {
      onChangeItem({ ...removedItem, _deleted: true }, removedIndex);
    } else {
      const newItems = state.items.filter((_item, index) => index !== removedIndex)
      setState({ items: newItems })
    }
  }, [state.items])

  const onChangeItem = useCallback((updatedItem, updatedIndex) => {
    setState({
      items: state.items.map((item, index) => {
        if (index === updatedIndex) {
          return updatedItem
        }
        return item
      })
    })
  }, [state.items])

  const headers = useMemo(() => {
    return [
      {
        displayName: "Context",
        key: "context",
        options: options,
        dropdown: true,
        tooltip: 'Indicates whether the launchd configuration file is applied to the system daemon, or system agent domain.'
      },
      {
        displayName: "File",
        key: "asset_data",
        fileInput: {
          accept: ".plist,application/xml,text/xml",
          showCurrentFile: true,
          includeCache: true,
        },
        tooltip: "The file must be a property list file conforming to the launchd.plist format."
      },
    ]
  }, [options])


  return (
    <DynamicList
      rows={state.items}
      onRemoveItem={onRemoveItem}
      onAddItem={onAddItem}
      onChangeItem={onChangeItem}
      headers={headers}
      policy={policy}
      fieldName="background_task[launchd_configurations_attributes]"
    />
  );
}

export default LaunchdConfigurations;