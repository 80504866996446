import passwordRevealInput from '../../shared/password_reveal_input'

export const groups = {
	new() {
	},
	show() {
		this.fn.wireForms();
		passwordRevealInput()
	},
	update() {
		this.fn.wireForms();
		passwordRevealInput()
	},
	fn: {
		wireForms() {
			$('.enable-app-lock').change(this.showOrHideAppLockDetails);

			this.showOrHideAppLockDetails();
		},
		showOrHideAppLockDetails() {
			const details = $('.app-lock-details');
			if ($('.enable-app-lock').prop('checked') || $('.enable-app-lock').attr('disabled')) {
				details.show('fast');
			} else {
				details.hide('fast');
			}
		}
	}
};
