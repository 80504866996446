import React from 'react';
import PropTypes from "prop-types";
import { bindMethods } from "../../shared/functions";
import FormGroup from "../forms/form_group";

export default class ProfileScope extends React.Component {
    constructor(props) {
        super(props);
        bindMethods(this)
    }

    disabled() {
        return this.props.policy && !this.props.policy.update
    }
    selectMinimumOSVersion(e) {
        const value           = e.target.value === "none" ? null : e.target.value
        const minMacosVersion = this.props.minimumMacosVersions.find(minMacosVersion => minMacosVersion.version === value)
        this.props.selectMinVersion(minMacosVersion)
    }

    selectMaximumOSVersion(e) {
        const value           = e.target.value === "none" ? null : e.target.value
        const maxMacosVersion = this.props.maximumMacosVersions.find(maxVersion => maxVersion.version === value)
        this.props.selectMaxVersion(maxMacosVersion)
    }

    selectAllowedMacosArchitecture(e) {
        const allowedMacosArchitecture = e.target.value
        this.props.selectAllowedMacosArchitecture(allowedMacosArchitecture)
    }

    disableMacOsScope() {
        return this.disabled() || !this.props.allowedPlatforms.some(os => os.name == 'macos')
    }

    selectAllowedOs(e) {
        this.props.selectAllowedOs(e.target.value)
    }

    allowedOSCheckboxes() {
        return this.props.supportedPlatformOptions.map(supportedOS => {
            return (
                <span className="checkbox" key={ supportedOS.name }>
                    <label>
                        <input name={ supportedOS.name }
                               type="checkbox"
                               className="check_boxes optional"
                               defaultChecked={ this.props.allowedPlatforms.find(os => os.name == supportedOS.name) }
                               onChange={ this.selectAllowedOs }
                               value={ supportedOS.name }
                               disabled={ this.disabled() || !this.props.supportedPlatforms.some(os => os.name == supportedOS.name) }
                        />
                        { supportedOS.displayName }
                    </label>
                </span>
            )
        })
    }

    render() {
        const minimumMacosVersions = this.props.minimumMacosVersions.map(
            minimumMacosVersion => {
                const version = minimumMacosVersion.version || "none";
                return <option key={ `os-version-${ version }` } value={ version }>
                    { minimumMacosVersion.displayName }
                </option>;
            }
        )

        const maximumMacosVersions      = this.props.maximumMacosVersions.map(
            maximumMacosVersion => {
                const version = maximumMacosVersion.version || "none";
                return <option key={ `os-version-${ version }` } value={ version }>
                    { maximumMacosVersion.displayName }
                </option>;
            }
        )
        const allowedMacosArchitectures = Object.entries(this.props.allowedMacosArchitectures).map(([name, value]) => {
            return <option key={ `allowed-architecture-${ value }` } value={ value }>
                { name }
            </option>;
        })

        return <div id="profile-scope" className="form profile-scope">
            <h3>Scope</h3>
            <p>Specify conditions that assigned devices must meet to receive this profile.</p>

            <FormGroup title="OS"
                       inputClasses=""
                       error={ this.getError('allowedPlatforms') }>
                <div>
                    { this.allowedOSCheckboxes() }
                </div>
            </FormGroup>

            <div className="form-group select optional minimum_os_version">
                <FormGroup title="Min macOS version"
                           groupClasses={ [] }
                           labelClasses={ ["col-sm-2", "control-label", "select", "optional"] }
                           inputClasses={ ['form-control', 'select', 'optional'] }
                           disabled={ this.disabled() }
                >
                    <select
                        onChange={ this.selectMinimumOSVersion }
                        value={ this.props.minVersion.version || "none" }
                        name="minimum_os_version"
                        id="minimum_os_version"
                        disabled={ this.disableMacOsScope() }>
                        { minimumMacosVersions }
                    </select>
                </FormGroup>

                <FormGroup title="Max macOS version"
                           groupClasses={ [] }
                           labelClasses={ ["col-sm-2", "control-label", "select", "optional"] }
                           inputClasses={ ['form-control', 'select', 'optional'] }
                           error={ this.getError('maximumMacosVersion') }
                >
                    <select
                        onChange={ this.selectMaximumOSVersion }
                        value={ this.props.maxVersion.version || "none" }
                        name="maximum_os_version"
                        id="maximum_os_version"
                        disabled={ this.disableMacOsScope() }>
                        { maximumMacosVersions }
                    </select>
                </FormGroup>


                <FormGroup title="Mac Architecture"
                           groupClasses={ [] }
                           labelClasses={ ["col-sm-2", "control-label", "select", "optional"] }
                           inputClasses={ ['form-control', 'select', 'optional'] }
                >
                    <select
                        className="form-control select optional"
                        onChange={ this.selectAllowedMacosArchitecture }
                        value={ this.props.allowedArchitecture || "any" }
                        name="allowed_macos_architecture"
                        id="allowed_macos_architecture"
                        disabled={ this.disableMacOsScope() }>
                        { allowedMacosArchitectures }
                    </select>
                </FormGroup>
            </div>
        </div>
    }

    getError(key) {
        return this.props.errors && this.props.errors[key] ? this.props.errors[key][0] : null
    }

}

const macosVersionShape = PropTypes.shape({
    name:    PropTypes.string.isRequired,
    version: PropTypes.string
})

const OsPlatformShape = PropTypes.shape({
    name:    PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
})

ProfileScope.propTypes  = {
    selectMaxVersion:          PropTypes.func.isRequired,
    selectMinVersion:          PropTypes.func.isRequired,
    selectAllowedOs:           PropTypes.func.isRequired,
    minVersion:                macosVersionShape.isRequired,
    maxVersion:                macosVersionShape.isRequired,
    allowedArchitecture:       PropTypes.string,
    allowedMacosArchitectures: PropTypes.object,
    allowedPlatforms:          PropTypes.arrayOf(OsPlatformShape),
    supportedPlatformOptions:  PropTypes.arrayOf(OsPlatformShape).isRequired,
    supportedPlatforms:        PropTypes.arrayOf(OsPlatformShape).isRequired,
    minimumMacosVersions:      PropTypes.arrayOf(macosVersionShape).isRequired,
    maximumMacosVersions:      PropTypes.arrayOf(macosVersionShape).isRequired
}