import React from "react";
import { bindMethods } from "../../shared/functions";
import { PagedTableContext } from "../paged_table/layout";

export default class PagedTableEditor extends React.Component {
    static contextType = PagedTableContext;

    constructor(props) {
        super(props)
        bindMethods(this);
    }

    toggleColumn(column) {
        const keyExists   = this.context.currentUser?.preferences?.deviceColumns?.some(otherColumn => column.key === otherColumn.key);
        if (keyExists) {
            this.context.disableDeviceColumn(column)

        } else {
            this.context.enableDeviceColumn(column)
        }
    }

    isChecked(key) {
        return this.context.currentUser?.preferences?.deviceColumns.some(column => column.key === key || column.displayName === key) === true
    }

    deviceColumns() {
        return this.context.currentUser?.preferences?.allowedColumns || []
    }

    renderAllowedColumns() {
        return this.deviceColumns().map(this.renderColumn)
    }

    renderColumn(column) {
        return <div key={ column.key } className="form-group row">
            <label className=""><span>{ column.displayName }</span>
                <input
                    className="boolean optional"
                    type="checkbox"
                    name={ column.key }
                    checked={ this.isChecked(column.key) }
                    onChange={ () => this.toggleColumn(column) }
                    disabled={ column.key === 'name' }
                />
            </label>
        </div>;
    }

    render() {
        // stopping propagation here because there is a handler to close this editor if someone clicks outside this div
        return <div className="well paged-table-editor" onClick={ e => e.stopPropagation() }>
            <h3>Device Columns</h3>
            <a href="#close" onClick={ this.props.close } className="close-button">
                <span className="glyphicon glyphicon-remove"/>
            </a>
            <form>
                { this.renderAllowedColumns() }
            </form>
        </div>
    }
}


