import React from 'react';
import AppIcon from "./app_icon";
import { ReactSortable } from "react-sortablejs";
import OpenFolder from "./open_folder";
import FormGroup from "../forms/form_group";
import { bindMethods } from "../../shared/functions";
import { allHomeScreenLayouts as ajaxClients } from '../../ajax_clients/profile_data/home_screen_layouts'
import { backPath, errorHandler, getAjaxClient, successHandler } from "../profiles/profile_utilities";

const MAX_ICONS_PER_PAGE = 30;
const MAX_ICONS_ON_DOCK = 15;
// https://github.com/SortableJS/Sortable/issues/1571
// forcing sortablejs fallback for safari
export const FORCE_FALLBACK = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf('Chrome') === -1;

export default class HomePageLayouts extends React.Component {

    constructor(props) {
        super(props);
        const { dock, pages, name, description, errors } = props.data.homeScreenLayout;
        const { availableIcons } = props.data;
        this.state = {
            availableIcons, errors,
            name: name || "",
            description: description || "",
            dock: dock || [],
            pages: pages || [],
            currentPageNumber: 0,
        };

        this.ajaxClient = getAjaxClient(props.assignableObject, ajaxClients)
        bindMethods(this);
    }

    currentPage() {
        return this.state.pages[this.state.currentPageNumber] || [];
    }

    setCurrentPage(currentPage) {
        this.setState((currentState) => ({
            pages: Object.assign([], currentState.pages, { [this.state.currentPageNumber]: currentPage })
        }))
    }

    setAvailableIcons(availableIcons) {
        this.setState({
            availableIcons: availableIcons.flatMap(icon => {
                if (icon.pages) {
                    return icon.pages.flat()
                } else {
                    return icon
                }
            })
        });
    }

    setDock(dock) {
        this.setState({ dock });
    }

    onFolderNameChange(folder, name) {
        const updatedFolder = { ...folder, name };
        const folderIndex = this.currentPage().indexOf(folder);
        this.updateCurrentPageItem(folderIndex, updatedFolder);
    }

    onSave(e) {
        e.preventDefault();
        const id = this.props.data.homeScreenLayout.id;
        const { dock, name, description } = this.state;
        // Filter out any empty pages the user my have created by accident.
        // These appear to be ignored by the device, but might make the user expect blank pages to show up
        const pages = this.state.pages.filter(page => page.length > 0)

        let ajaxCall;
        if (id) {
            ajaxCall = this.ajaxClient.update(id, { dock, pages, name, description })
        } else {
            ajaxCall = this.ajaxClient.create({ dock, pages, name, description })
        }

        ajaxCall.then(successHandler(this)).catch(errorHandler(this));
    }

    updateCurrentPageItem(updatedIndex, updatedItem) {
        const currentPage = this.currentPage();
        const updatedPage = currentPage.map((item, index) => index === updatedIndex ? updatedItem : item);
        this.setCurrentPage(updatedPage);
    }

    setFolderData(folder, data) {
        const updatedFolder = { ...folder, ...data };
        const currentPageIndex = this.currentPage().indexOf(folder);
        if (currentPageIndex >= 0) {
            this.updateCurrentPageItem(currentPageIndex, updatedFolder);
        } else {
            const dockIndex = this.state.dock.indexOf(folder);
            if (dockIndex >= 0) {
                this.setState({
                    dock: Object.assign([], this.state.dock, { [dockIndex]: updatedFolder })
                })
            }
        }
    }

    setName(e) {
        this.setState({ name: e.target.value });
    }

    setDescription(e) {
        this.setState({ description: e.target.value })
    }

    previousPage(e) {
        e.preventDefault();
        let { currentPageNumber } = this.state;
        currentPageNumber = currentPageNumber <= 0 ? currentPageNumber : currentPageNumber - 1;
        this.closeFolders(() => this.setState({ currentPageNumber }));
    }

    nextPage(e) {
        if (e) {
            e.preventDefault();
        }

        this.closeFolders(() => {
            const currentPageNumber = this.state.currentPageNumber + 1;
            if (currentPageNumber >= this.state.pages.length) {
                this.setState({
                    pages: [...this.state.pages, []],
                    currentPageNumber
                })
            } else {
                this.setState({ currentPageNumber })
            }
        });
    }

    closeFolders(callback) {
        const closeFolder = icon => {
            if (icon.iconType === 'folder' && icon.opened) {
                return {
                    ...icon,
                    opened: false,
                    name: /^\s*$/.test(icon.name) ? "Unnamed Folder" : icon.name
                }
            } else {
                return icon
            }
        };
        this.setState({
            pages: this.state.pages.map(page => page.map(closeFolder)),
            dock: this.state.dock.map(closeFolder)
        }, callback)
    }

    openedFolder() {
        const folderFinder = (icon) => icon.iconType === 'folder' && icon.opened;
        const pagesFolder = this.currentPage().find(folderFinder);
        if (pagesFolder) {
            return pagesFolder;
        }
        return this.state.dock.find(folderFinder);
    }

    onAddFolder(e) {
        e.preventDefault();
        const newFolder = {
            name: "New Folder",
            iconType: 'folder',
            id: `new-folder-${ (new Date()).getTime() }`,
            iconPath: this.props.folderIconPath,
            opened: true,
            pages: []
        };
        this.closeFolders(() => {
            this.setCurrentPage(this.currentPage().concat(newFolder));
        });
    }

    onClickIcon(icon) {
        if (icon.iconType === 'folder') {
            this.closeFolders(() => this.setFolderData(icon, { opened: true }));
        }
    }

    currentPageFilled() {
        return this.currentPage().length >= MAX_ICONS_PER_PAGE;
    }

    screenEnabled() {
        if (!this.currentPageFilled()) {
            if (document.getElementsByClassName('folder').length > 0) {
                return ['folder'];
            } else {
                return true;
            }
        }
        return false;
    }

    dockFilled() {
        return this.state.dock.length >= MAX_ICONS_ON_DOCK;
    }

    dockEnabled() {
        return !this.dockFilled();
    }

    onStart() {
        this.backup();
    }

    onEnd(e) {
        // we cant allow dragging folders onto the dock so undo the action
        if (e.to.parentElement && e.to.parentElement.className === 'folder' && /folder-icon/.test(e.item.className)) {
            this.undo();
        }
    }

    backup() {
        const { availableIcons, dock, pages } = this.state;
        this.setState({
            backupState: { availableIcons, dock, pages }
        })
    }

    undo() {
        if (this.state.backupState) {
            this.setState(this.state.backupState)
        }
    }

    renderButtons() {
        const path = backPath(this.props.assignableObject);
        const backButton = <a className="btn btn-default" href={ path }>Back</a>;
        const saveButtons = (
            <React.Fragment>
                <a className="btn btn-default" href={ path }>Cancel</a>
                <a href="#save" onClick={ this.onSave } className="btn btn-primary save-button">Save</a>
            </React.Fragment>
        );
        return (
            <div className="buttons">
                { this.props.policy.update ? saveButtons : backButton }
            </div>
        )
    }

    renderOpenFolder() {
        const openFolder = this.openedFolder();
        if (openFolder) {
            return <OpenFolder
                folder={ openFolder }
                onCloseFolder={ () => this.closeFolders() }
                onStart={ this.onStart }
                onEnd={ this.onEnd }
                onFolderNameChange={ this.onFolderNameChange }
                setFolderPages={ this.setFolderData }
            />
        }
    }

    render() {
        const disabled = !this.props.policy.update;
        const screenStatus = this.screenEnabled();
        const currentPage = this.currentPage();
        const filter = this.openedFolder() ? '.folder-icon' : null; //dont allow dragging folders into opened folder
        const { errors } = this.state;
        let error;
        if (errors) {
            error = Array.isArray(errors.name) ? errors.name[0] : errors.name;
        }

        return (
            <div id="home-screen-layout-form" className="form form-horizontal">
                <h3>General</h3>
                <FormGroup disabled={ disabled } error={ error } title="Name" required={ true }>
                    <input type="text"
                           name="name"
                           onChange={ this.setName }
                           value={ this.state.name }
                           disabled={ disabled }
                           className="name"
                    />
                </FormGroup>

                <FormGroup
                    disabled={ disabled }
                    title={ "Description" }
                    hint="Optional description presented to the end user."
                >
                    <input disabled={ disabled }
                           name="description"
                           value={ this.state.description || "" }
                           onChange={ this.setDescription } />
                </FormGroup>

                <h3>Screen Layout</h3>

                <div id="home-screen-layout">
                    <div id="screen-details">
                        <ReactSortable
                            forceFallback={ FORCE_FALLBACK }
                            key={ `screen-enabled-${ screenStatus }` /* fixes bug where group object props not updated */ }
                            group={ { name: 'screen', put: screenStatus } }
                            id="screen"
                            onStart={ this.onStart }
                            onEnd={ this.onEnd }
                            filter={ filter }
                            list={ currentPage }
                            setList={ this.setCurrentPage }>
                            { AppIcon.renderIcons(currentPage, this.onClickIcon) }
                        </ReactSortable>

                        <ReactSortable
                            forceFallback={ FORCE_FALLBACK }
                            key={ `dock-enabled-${ this.dockEnabled() }` /* fixes bug where group object props not updated */ }
                            group={ { name: 'dock', put: this.dockEnabled() } }
                            id="dock"
                            onStart={ this.onStart }
                            onEnd={ this.onEnd }
                            filter={ filter }
                            list={ this.state.dock }
                            setList={ this.setDock }>
                            { AppIcon.renderIcons(this.state.dock, this.onClickIcon) }
                        </ReactSortable>

                        { this.renderOpenFolder() }
                    </div>

                    <ReactSortable id="available-icons"
                                   forceFallback={ FORCE_FALLBACK }
                                   group={ { name: 'available-icons', put: true } }
                                   list={ this.state.availableIcons }
                                   onStart={ this.onStart }
                                   onEnd={ this.onEnd }
                                   setList={ this.setAvailableIcons }>
                        { AppIcon.renderIcons(this.state.availableIcons, this.onClickIcon) }
                    </ReactSortable>

                    <div id="screen-control" className="pull-left">
                        <span
                            onClick={ this.previousPage }
                            className="btn btn-default previous-screen btn-sm">
                            <span className="glyphicon glyphicon-chevron-left"/>
                        </span>

                        <div className="current-screen">
                            page { this.state.currentPageNumber + 1 }
                        </div>

                        <span
                            onClick={ this.nextPage }
                            className="btn btn-default next-screen btn-sm">
                            <span className="glyphicon glyphicon-chevron-right"/>
                        </span>

                        <a href="#add-folder"
                           disabled={ this.currentPageFilled() }
                           className="btn btn-default btn-sm add-folder pull-right"
                           onClick={ this.onAddFolder }>
                            Add Folder
                        </a>
                    </div>
                    { this.renderButtons() }
                </div>
            </div>
        )
    }

}