import React from 'react';
import { devices as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, truncate } from '../../shared/functions';
import StatusLabel from "../profiles/status_label";
import allSettled from "promise.allsettled";

const formatProfile = (profile) => ({ ...profile, checkDisabled: !profile.directAssignment || profile.type === "CachedProfile" })

const sortAssignments = (profileA, profileB) => {
    if (profileA.type === "CachedProfile" || profileB.type === 'CachedProfile') {
        return profileB.type === "CachedProfile" ? -1 : 1
    } else if (profileA.directAssignment !== profileB.directAssignment) {
        return profileA.directAssignment ? -1 : 1
    } else if (profileA.displayName !== profileB.displayName) {
        return profileA.displayName.localeCompare(profileB.displayName)
    } else {
        return profileA.name.localeCompare(profileB.name)
    }
};

export const formatAssignments = (response) => {
    const data = response.data.reduce((array, profileType) => {
        return array.concat(
            profileType.profilesToInstall.map(formatProfile),
            profileType.profilesToSkip.map(formatProfile)
        )
    }, []).sort(sortAssignments);
    return { ...response, data }
}

const getAjaxClient = ({ deviceId }) => ({
    read() {
        return ajaxClient.readProfileAssignments(deviceId).then(formatAssignments)
    },
    destroyMultiple: (ids) => allSettled(ids.map(id => ajaxClient.unassignProfile(deviceId, id)))
});

class DeviceProfileAssignmentsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Unassign",
                policy: 'assignProfiles'
            }
        ];

        return <ActionDropDown
            text="Actions"
            items={ items }
            policy={ this.props.policy }
            disabled={ !this.props.hasCheckedItems() }
        />
    }

    removeItems(e) {
        this.props.removeItems(e, 'This will remove the selected profiles from the device. Are you sure?')
    }

    profileAssignmentPath() {
        return AdminRoutes.adminDeviceAssignProfilesPath(this.props.deviceId, {format: null})
    }

    renderAssignProfileButton() {
        return <a className="btn btn-default pull-right"
                  href={ this.profileAssignmentPath() }>Assign Profile</a>
    }

    statusLabels(row) {
        return (
            <div style={{display: 'flex', gap: 5}}>
                { row.statusReason == null ? null : <StatusLabel { ...row } /> }
                { row.installsViaDdm ? <StatusLabel statusReason={{description: 'Installs via DDM', severity: 'info', shortText: 'DDM'}} /> : null }
            </div>
        )
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Type", key: "displayName" },
                { displayName: "Assigned Via", key: "directAssignment" },
                { displayName: "Status", key: "statusReason" },
            ],
            showCheck: this.props.policy.assignProfiles === true,
            renderRow: (row) => {
                const isCachedProfile = row.type === 'CachedProfile'
                const truncatedNameOrUuid = truncate(row.name ?? row.payloadUuid, 50)
                const name = isCachedProfile ? truncatedNameOrUuid : <a href={ row.originalRecord }>{ truncatedNameOrUuid }</a>
                const displayName = isCachedProfile ? '' : row.displayName
                const directAssignment = (() => {
                    if (row.directAssignment) return "Direct"
                    if (isCachedProfile) return row.statusReason === 'delete_pending' ? '' : "Local"
                    if (row.source != null) {
                        return <a href={ row.source.url }>{ truncate(row.source.name, 50) }</a>
                    }

                    return <a href={ AdminRoutes.adminGroupPath(this.props.group.id, {format: null}) }>{ truncate(this.props.group.name, 50) }</a>
                })()

                return { ...row, name, directAssignment, statusReason: this.statusLabels(row), displayName }
            },
        }
    }

    render() {
        let actions = <React.Fragment>
            { this.renderActionButton() }
            { this.renderAssignProfileButton() }
        </React.Fragment>;

        const assignLink = this.props.policy.assignProfiles ? <><a href={ this.profileAssignmentPath() }>Assign the first profile</a>.</> : null;
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No profiles are currently assigned. {assignLink}
            </div>
        );

        return (
            <div id="profile-assignments-table">
                { this.props.renderTopSection(null, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(DeviceProfileAssignmentsPagedTable, getAjaxClient, undefined, null);