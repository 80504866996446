import { toggleDOMElement } from "../../shared/functions";
export const background_tasks = {
    show() {
        const executableAssetToggle = document.getElementById('background_task_include_executable_asset');
        const executableAssetFields = document.querySelector('.background_task_asset_data');
        toggleDOMElement(executableAssetToggle, executableAssetFields);
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    }
};