import React from 'react';
import { SearchBar, SearchBarResult } from '../../search'
import * as ajaxClients from '../../../ajax_clients';
import { bindMethods } from "../../../shared/functions";

export default class AssignmentGroupAvailableDevices extends React.Component {
    static isDevice(item) {
        return typeof item.serialNumber !== 'undefined';
    }

    constructor(props) {
        super(props);
        this.state               = {
            search: null
        };
        bindMethods(this);
    }

    filterAssigned(allItems, assignedIds) {
        return allItems.filter(deviceOrGroup => !assignedIds.includes(deviceOrGroup.id));
    }

    searchResults() {
        if (this.state.search === null) {
            return this.devicesAndGroups().slice(0, 5);
        } else {
            return this.devicesAndGroups()
                .filter(item => this.state.search.test(Object.values(item).join(' ')))
                .slice(0, 101);
        }

    }

    onSearchChange(search) {
        this.setState({ search })
    }

    devicesAndGroups() {
        const availableGroups  = this.filterAssigned(this.props.groups, this.props.assignmentGroup.groupIds);
        const availableDevices = this.filterAssigned(this.props.devices, this.props.assignmentGroup.deviceIds);
        return availableGroups.concat(availableDevices);
    }

    assignDevice(device) {
        ajaxClients.assignmentGroups.assignDevice(this.props.assignmentGroup.id, device.id)
            .then(this.props.updateAssignmentGroup);
    }

    assignGroup(group) {
        ajaxClients.assignmentGroups.assignGroup(this.props.assignmentGroup.id, group.id)
            .then(this.props.updateAssignmentGroup);
    }

    assignDeviceOrGroup(deviceOrGroup) {
        AssignmentGroupAvailableDevices.isDevice(deviceOrGroup) ? this.assignDevice(deviceOrGroup) : this.assignGroup(deviceOrGroup);
    }

    render() {
        return (
            <div className="available-devices">
                <SearchBar
                    placeholderText={ 'Search for a group or device to add' }
                    inputClass="form-control string"
                    onSearchChange={ this.onSearchChange }
                >
                    {
                        this.searchResults().map((deviceOrGroup) => {
                                const type = AssignmentGroupAvailableDevices.isDevice(deviceOrGroup) ? 'device' : 'group';
                                return <SearchBarResult
                                    key={ `${ deviceOrGroup.id }-${ deviceOrGroup.name }` }
                                    onChooseItem={ () => this.assignDeviceOrGroup(deviceOrGroup) }>
                                    { deviceOrGroup.name }
                                    <span className="type">{ type }</span>
                                </SearchBarResult>
                            }
                        )
                    }
                </SearchBar>
            </div>
        )
    }

}