import React from 'react';
import { appInstalledApplications as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, boolToString, callWithIds, truncate } from '../../shared/functions';

const getAjaxClient = ({ appGroupId }) => {
    const callWithAppGroupIdAndIds = (func) => {
        return (installedApplications) => callWithIds(installedApplications, (ids) => func(appGroupId, ids));
    };

    return {
        read:            (query) => ajaxClient.readForAppGroup(appGroupId, query),
        destroyMultiple: callWithAppGroupIdAndIds(ajaxClient.destroyMultipleForAppGroup),
        updateMultiple:  callWithAppGroupIdAndIds(ajaxClient.updateMultiple),
    }
};

class AppsInstalledApplicationsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    renderActionButton() {
        const actions = []

        if (this.props.policy.edit) {
            const items = [
                {
                    href:   "#update",
                    action: this.updateItems,
                    text:   "Install Update",
                    policy: 'edit'
                },
                {
                    href:   "#delete",
                    action: this.removeItems,
                    text:   "Uninstall",
                    policy: 'destroy',
                }
            ];

            actions.push(
                    <ActionDropDown text="Actions"
                                    items={ items }
                                    policy={ this.props.policy }
                                    disabled={ !this.props.hasCheckedItems() }/>
            )
        }

        if (!this.props.dataNotFound) {
            actions.push(
                <a key="export-devices" className="btn btn-default pull-right"
                   href={ AdminRoutes.adminAppGroupInstalledApplicationsPath(this.props.appGroupId, { format: 'csv' }) }>
                    Export List
                </a>
            )
        }

        return actions
    }

    removeItems(e) {
        this.props.removeItems(e, "This will uninstall the app from the selected devices. Are you sure?");
    }

    updateItems(e) {
        e.preventDefault()
        this.props.callWithItems('updateMultiple')
    }

    pagedTableProps() {
        const props = {
            headers:   [
                { displayName: 'Device Name', key: 'deviceName', orderable: true },
                { displayName: 'Version (build)', key: 'version', orderable: true },
                { displayName: 'Short Version', key: 'shortVersion', orderable: true },
                { displayName: 'Bundle Identifier', key: 'identifier' },
                { displayName: 'Managed?', key: 'managed', orderable: true },
                { displayName: 'Discovered', key: 'discovered', orderable: true },
                { displayName: 'Device Last Seen', key: 'deviceLastSeen', orderable: true },
            ],
            renderRow: (row) => {
                const name           = truncate(row.name, 50);
                const managed        = boolToString(row.managed);
                const deviceName     = <a href={ AdminRoutes.adminDevicePath(row.device.id, {format: null}) }>{ row.device.name }</a>;
                const deviceLastSeen = row.device.lastSeenAt;
                return Object.assign({}, row, { name, managed, deviceName, deviceLastSeen });
            },
        };

        if (!this.props.policy.edit) {
            props.showCheck = false;
        }

        return props
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No installed applications found
            </div>
        );

        let tabDescription = <p>
            This tab shows installed instances of the app according to the most recent device inventory data.
            Please note that inventory data may sometimes be outdated.
            Read more in our <a
            href="https://simplemdm.zendesk.com/hc/en-us/articles/9355267128987"
            target="_blank">documentation</a>.</p>

        let message;
        if (this.props.isPackage) {
            message = <React.Fragment>
                { tabDescription }
                <p>
                    <strong>Note:</strong> macOS binaries do not always install with the same bundle identifier as
                    specified by the binary. As a result, installations may not appear below.
                </p>
            </React.Fragment>
        } else {
            message = tabDescription;
        }

        let actions = this.renderActionButton();

        return (
            <React.Fragment>
                <div className="clearfix">
                    <div className={ `col-xs-9` }>
                        { message }
                    </div>
                    <div className={ `col-xs-3` }>
                        { actions }
                    </div>
                </div>
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

const initialQuery = {
    order_d:        'asc',
    order:          'deviceName',
    page:           1,
    per:            20,
};

export default pagedTableLayout(AppsInstalledApplicationsPagedTable, getAjaxClient, initialQuery, null);