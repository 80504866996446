export const login_and_background_item_managements = {
    show() {
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    }
};