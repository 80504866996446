import allSettled from "promise.allsettled";

export const accountProviders = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminAccountProviderPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminAccountProvidersPath(idOrOptions));
        }
    },

    destroyMultiple(accountProviders) {
        if(accountProviders.some(accountProvider => !accountProvider.originalRecord)) {
            throw "Missing original record url"
        }
        return allSettled(accountProviders.map(accountProvider => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      accountProvider.originalRecord
        })));
    }

};