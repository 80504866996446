export const mediaVppLicenses = {
    read(mediaId, options) {
        return $.getJSON(AdminRoutes.adminMediaVPPLicensesPath(mediaId, options));
    },

    destroyMultiple(mediaId, ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.revokeMultipleAdminMediaVPPLicensesPath(mediaId),
            data:        JSON.stringify({ ids })
        })
    },
}