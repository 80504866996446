const createInput = function (form, name, value) {
    if (typeof value !== 'undefined') {
        var input = document.createElement('input');
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.appendChild(input);
    }
};

const populateForm = (formName) => {
    return () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'sourcesStoreLoaded'
        });
        const form = document.getElementById(`new_${formName}`);
        const lastSource = window.sourcesStore.getLastNonDirect();

        createInput(form, `${ formName }[analytics][cid]`, lastSource.cid || "not_set");
        createInput(form, `${ formName }[analytics][gclid]`, window.sourcesStore.getLastParameter('gclid') || "not_set");
        createInput(form, `${ formName }[analytics][msclkid]`, window.sourcesStore.getLastParameter('msclkid') || "not_set");
        createInput(form, `${ formName }[analytics][fbclid]`, window.sourcesStore.getLastParameter('fbclid') || "not_set");
        createInput(form, `${ formName }[analytics][rdt_cid]`, window.sourcesStore.getLastParameter('rdt_cid') || "not_set");
        createInput(form, `${ formName }[analytics][li_fat_id]`, window.sourcesStore.getLastParameter('li_fat_id') || "not_set");
        createInput(form, `${ formName }[analytics][utm_campaign]`, window.sourcesStore.getLastParameter('utm_campaign') || "not_set");
        createInput(form, `${ formName }[analytics][utm_source]`, window.sourcesStore.getLastParameter('utm_source') || "not_set");
        createInput(form, `${ formName }[analytics][utm_medium]`, window.sourcesStore.getLastParameter('utm_medium') || "not_set");
        createInput(form, `${ formName }[analytics][utm_content]`, window.sourcesStore.getLastParameter('utm_content') || "not_set");
        createInput(form, `${ formName }[analytics][utm_term]`, window.sourcesStore.getLastParameter('utm_term') || "not_set");
        createInput(form, `${ formName }[analytics][utm_grouping]`, window.sourcesStore.getLastParameter('utm_grouping') || "not_set");
    }
}

const addScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://d1m09pg603vjt9.cloudfront.net/assets/js/app.bundle.js";
    document.head.appendChild(script)
}

const setAnalyticsFields = (formName) => {
    window._sourcesStoreConfig = {
        additionalParametersToPersist: [
            'effort'
        ],
        callback: populateForm(formName)
    }
    addScript()
}


export const users = {
    new() {
        setAnalyticsFields('user')
    },

    create() {
        this.new();
    }
}