import React from 'react';
import pluralize from 'pluralize'
import { apps as appsClient, appGroups as appGroupsClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { truncate, bindMethods, callWithIds } from '../../shared/functions';
import allSettled from 'promise.allsettled'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchBar from "../search/search_bar"

const setCheckDisabled = (app) => {
    return { ...app, checkDisabled: app.appType !== 'Shared' && (!app.editable || app.processingStatus === 'processing')  }
}

const formatAppData = (appGroup) => {
    let latestVersion = appGroup.apps.find(
        app => app.id === appGroup.latestVersionId)
    latestVersion = setCheckDisabled(latestVersion);

    if (appGroup.official) {
        latestVersion.version = '-'
    }

    return latestVersion
}
const getAjaxClient = () =>
    ({
        ...appsClient,
        read: (appIdOrOptions) => {
            if (typeof appIdOrOptions === 'number') {
                // searching for app by id
                return appsClient.read(appIdOrOptions).then(response => {
                    const data = setCheckDisabled(response.data)
                    return { ...response, data }
                })
            } else {
                // get list of app groups and map to latest app for that group
                return appGroupsClient.read(appIdOrOptions).then(response => {
                    return { ...response, data: response.data.map(formatAppData) }
                })
            }
        },
        destroyMultiple: (collection) => callWithIds(collection, appsClient.destroyMultiple)
    });

export const processAvailableLicenses = (item) => {
    const availableStrings = []

    let availableLicenses = item.availableLicenses;
    if (availableLicenses.available) {
        const available            = availableLicenses.available;
        let availableLicenseString = `${ available } ${ pluralize('license', available) }`;
        availableStrings.push(availableLicenseString);
    }
    let availableCodes = item.availableCodes;
    if (availableCodes.available) {
        const available          = availableCodes.available;
        let availableCodesString = `${ available } ${ pluralize('code', available) }`;
        availableStrings.push(availableCodesString);
    }

    if (availableStrings.length === 0) {
        return "0 licenses available"
    } else {
        return availableStrings.join(" and ") + " available"
    }
};

export const renderCompatibilityLabel = (app, additionalClassNames = "") => {
    const { compatibility } = app
    if (compatibility) {
        const labelContent = <span className={ `install-channel ${additionalClassNames}` }>{ compatibility }</span>
        if (compatibility === 'Intel') {
            return (
                <OverlayTrigger
                    placement="left"
                    overlay={ <Tooltip id={ "tooltip" }>{ "Most Intel apps will run on Apple Silicon devices if Rosetta 2 has been installed." }</Tooltip> }>
                    { labelContent }
                </OverlayTrigger>
            )
        } else {
            return labelContent
        }
    }
}

class AppsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    cancelAppPolling() {
        clearTimeout(this.appPolling);
    }

    pollProcessingApps(processingApps) {
        this.cancelAppPolling();
        this.appPolling = setTimeout(() => {
            allSettled(
                processingApps.map(app => appsClient.read(app.id))
            )
                .then(responses => {
                    const updatedApps = responses.reduce((hash, responseItem) => {
                        const app    = responseItem.value.data
                        hash[app.id] = setCheckDisabled(app);
                        return hash;
                    }, {})
                    const updatedData = this.props.data.map(app => updatedApps[app.id] || app)
                    this.props.updateData(updatedData)
                })
        }, 5000)
    }

    componentWillUnmount() {
        this.cancelAppPolling()
    }

    componentDidUpdate(prevProps) {
        const processingApps = this.props.data.filter((app) => app.processing)
        if (prevProps.data !== this.props.data && processingApps.length > 0) {
            this.pollProcessingApps(processingApps);
        }
    }

    renderAddAppButton() {
        if (!this.props.policy.addApps) {
            return null;
        }
        const items = [
            { text: "Apple Store App", href: AdminRoutes.newAppStoreAppAdminAppsPath({format: null}) },
            { text: "Shared App", href: AdminRoutes.addSharedAppAdminAppsPath({format: null}), policy: 'addSharedApp' },
            { text: "Custom App", href: AdminRoutes.newBinaryAdminAppsPath({format: null}) },
            { text: "NoPkg App", href: AdminRoutes.newNopkgAppAdminAppsPath({format: null}) }
        ];
        return <ActionDropDown text="Add App" items={ items } policy={ this.props.policy } />
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroyMultiple'
            }
        ];
        return <ActionDropDown
            text="Actions"
            items={ items }
            policy={ this.props.policy }
            disabled={ !this.props.hasCheckedItems() }
        />
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "App Name", key: "name" },
                { displayName: "Version", key: "version" },
                { displayName: "Platform", key: "platformSupport" },
                { displayName: "App Type", key: "appType" },
                { displayName: "", key: "metadata" },
            ],
            renderRow: (row) => {
                let metadata     = this.renderMetadata(row);
                let availability = this.renderAvailability(row);
                const name       = (
                    <a href={ AdminRoutes.adminAppPath(row.id, {format: null}) }>
                        <img src={ row.iconUrl }/>{ truncate(row.name, 50) }
                    </a>
                );
                const checked    = row.checked && !row.checkDisabled
                const version    = row.version || "-"
                return Object.assign({}, row, { name, metadata, availability, checked, version });
            }
        };
    }

    renderAvailability(row) {
        let availability;
        // testing for processing first because we dont want to show users processing errors until
        // processing has been completed
        if (row.processing) {
            availability = (
                <span className="label label-success">
                            <span className="glyphicon glyphicon-alert"/>
                    &nbsp;processing
                        </span>
            )
        } else if (row.processingStatus !== 'processed' && row.processingErrors.length > 0) {
            availability = (
                <a href={ AdminRoutes.adminAppPath(row.id, {format: null}) } className="label label-warning">
                    <span className="glyphicon glyphicon-alert"/>
                    &nbsp; processing errors
                </a>
            )
        } else {
            availability = row.appType === 'enterprise' ? row.version : processAvailableLicenses(row);
        }
        return availability;
    }

    renderMetadata(row) {
        // testing for processing first because we dont want to show users processing errors until
        // processing has been completed
        if (row.processing) {
            return (
                <span className="label label-success">
                            <span className="glyphicon glyphicon-alert"/>
                    &nbsp;processing
                        </span>
            )
        } else if (row.processingStatus !== 'processed' && row.processingErrors.length > 0) {
            return (
                <a href={ AdminRoutes.adminAppPath(row.id, {format: null}) } className="label label-warning">
                    <span className="glyphicon glyphicon-alert"/>
                    &nbsp; processing errors
                </a>
            )
        } else {
            if (row.appType === "Apple Store") {
                return processAvailableLicenses(row)
            } else {
                if (row.installationChannels.includes('munki')) {
                    const installChannel = row.installationChannels.includes('standard') ? "Munki" : "Munki Only"
                    const compatibilityLabel = renderCompatibilityLabel(row)

                    return (
                        <div>
                            <span className="install-channel">{ installChannel }</span>
                            { compatibilityLabel }
                        </div>
                    )
                }
                return null;
            }

        }
    }

    onChooseAppTypeFilter(e) {
        this.props.setQuery({
            app_type_filter: e.target.value,
            page:            1
        })
    }

    onChoosePlatformFilter(e) {
        this.props.setQuery({
            platform_filter: e.target.value,
            page:            1
        })
    }

    onSearchChange(searchRegex, search) {
        if (search != this.props.query.search) {
            this.props.setQuery({ search, page: 1 })
        }
    }

    renderSearchBar() {
        return (
            <div className="search-controls">
                <select onChange={ this.onChooseAppTypeFilter }
                        value={ this.props.query.app_type_filter }
                        className="form-control filter">
                        <option value="all">All App Types</option>
                        <option value="app-store">App Store Apps</option>
                        <option value="shared">Shared Apps</option>
                        <option value="custom">Custom Apps</option>
                        <option value="nopkg">NoPkg Apps</option>
                </select>

                <select onChange={ this.onChoosePlatformFilter }
                        value={ this.props.query.platform_filter }
                        className="form-control filter">
                        <option value="all">All Platforms</option>
                        <option value="ios">iOS & iPadOS</option>
                        <option value="macos">macOS</option>
                        <option value="tvos">tvOS</option>
                        <option value="visionos">visionOS</option>
                </select>
                <SearchBar
                    timeout={ 600 }
                    searching={ this.props.searching }
                    initialValue={ this.props.query.search }
                    placeholderText='Filter by name'
                    onSearchChange={ this.onSearchChange }>
                </SearchBar>
            </div>
        )
    }

    renderNotFound() {
        let message
        const query = this.props.query

        if (query.app_type_filter != 'all' || query.platform_filter != 'all' || query.search) {
            message = "No apps match your search query."
        } else {
            message = "You have not added any apps to your catalog."
        }

        return (
            <div className="centered alert alert-info" role="alert">
                { message }
            </div>
        )
    }

    render() {
        let message = <p>Add the apps to your catalog that you wish to distribute to your devices.
            These apps are made available under the &quot;Assignment&quot; section for distribution.
            Apps in associated VPP accounts will be automatically added.</p>;

        let actions = <React.Fragment>
            { this.renderActionButton() }
            { this.renderAddAppButton() }
        </React.Fragment>;

        return (
            <div id="apps-table">
                { this.props.renderTopSection(message, actions) }
                { this.renderSearchBar() }
                { this.props.dataNotFound ? this.renderNotFound() : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

const initialQuery  = {
    order: 'at',
    order_d: 'desc',
    page: 1,
    per: 20,
    saveQuery: true,
    app_type_filter: 'all',
    platform_filter: 'all',
    search:           null
};
export default pagedTableLayout(AppsPagedTable, getAjaxClient, initialQuery, AdminRoutes.adminAppGroupsPath({format: null}));
