import React from 'react';
import FormGroup from "../forms/form_group";

const Checkbox = (props) => {
    return <FormGroup inputClasses={ ["checkbox"] } hint={ props.hint }>
        <div>
            <label className="boolean optional">
                <input name={ props.name }
                       type="checkbox"
                       checked={ props.checked }
                       onChange={ props.onChange }
                       value={ props.value }
                       disabled={ props.disabled }
                />
                { props.children }
            </label>
        </div>
    </FormGroup>
}

export default Checkbox