export const assignmentGroups = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminAssignmentGroupPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminAssignmentGroupsPath(idOrOptions));
        }
    },

    destroy(id) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            url: AdminRoutes.adminAssignmentGroupPath(id)
        })
    },

    push(id) {
        return $.ajax({
            method: 'POST',
            contentType: "application/json",
            dataType: 'json',
            url: AdminRoutes.pushAdminAssignmentGroupPath(id),
        });
    },

    pushUpdates(id) {
        return $.ajax({
            method: 'POST',
            contentType: "application/json",
            dataType: 'json',
            url: AdminRoutes.pushUpdatesAdminAssignmentGroupPath(id),
        });
    },

    updateCatalog(id) {
        return $.ajax({
            method: 'POST',
            contentType: "application/json",
            dataType: 'json',
            url: AdminRoutes.updateCatalogAdminAssignmentGroupPath(id),
        });
    },

    assignApp(id, appId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.appAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                appId: appId
            })
        })
    },

    unassignApp(id, appId) {
        return $.ajax({
            method: 'DELETE',
            contentType: "application/json",
            dataType: 'json',
            url: AdminRoutes.appAdminAssignmentGroupPath(id),
            data: JSON.stringify( {
                appId: appId
            })
        })

    },

    assignMedia(id, mediaId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.mediaAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                mediaId: mediaId
            })
        })
    },

    unassignMedia(id, mediaId) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.mediaAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                mediaId: mediaId
            })
        })
    },

    syncProfiles(id) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.syncProfilesAdminAssignmentGroupPath(id)
        })
    },

    assignProfile(id, profileId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.profileAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                profileId: profileId
            })
        })
    },

    unassignProfile(id, profileId) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.profileAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                profileId: profileId
            })
        })
    },

    assignDevice(id, deviceId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.deviceAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                deviceId: deviceId
            })
        })
    },

    unassignDevice(id, deviceId) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            url: AdminRoutes.deviceAdminAssignmentGroupPath(id),
            data: {
                deviceId: deviceId
            }
        })
    },

    assignGroup(id, groupId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.groupAdminAssignmentGroupPath(id),
            data: JSON.stringify({
                groupId: groupId
            })
        })
    },

    unassignGroup(id, groupId) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            url: AdminRoutes.groupAdminAssignmentGroupPath(id),
            data: {
                groupId: groupId
            }
        })
    },

};
