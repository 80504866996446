import React, { useCallback, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTheme } from "@mui/material/styles"
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui'
import { Controller, useForm } from "react-hook-form"
import { ThemeProvider }  from '../shared/simplemdm_theme'
import { pushCertificates as ajaxClient } from '../../ajax_clients/push_certificates'
import Button from './button'
import Container from './container'
import Header from './header'
import { useRailsErrorHandler } from "./hooks"
import Paper, { borderRadius as paperBorderRadius } from './paper'

const LIST_ITEM_STYLE = { display: "list-item", padding: '2px 2px' }

const FileInputElement = (props) => {
  const {control, label, required, sx, ...inputPropOptions} = props
  const inputProps = {
    type: "file",
    ...inputPropOptions,
  }

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{required: required}}
      render={({ field, fieldState: {error} }) => {
        return (
          <FormControlLabel
            control={
              <>
                <FormHelperText error sx={{marginLeft: '14px'}}>{error && (error.type === 'required' ? 'This field is required' : error.message)}</FormHelperText>
                <Input {...field} disableUnderline inputProps={inputProps} value={field.value || ''}/>
              </>
            }
            label={<InputLabel error={error != null} htmlFor={props.name} required={required} sx={{color: 'black', fontWeight: 700, paddingBottom: 1}}>{label}</InputLabel>}
            labelPlacement="top"
            sx={{ alignItems: 'initial', margin: 0, ...sx}}
          />
      )}}
    />
  )
}

function PushCertificate(props) {
  const theme = useTheme()
  const { pushCertificate } = props
  const [isLoading, setIsLoading] = useState(false)
  const formContext = useForm({ defaultValues: { appleId: pushCertificate.appleId, file: undefined } })
  const { handleSubmit, setError } = formContext
  const handleRailsError = useRailsErrorHandler(setError)

  const onSuccess = useCallback(async ({appleId}, event) => {
    setIsLoading(true)

    const file = Object.fromEntries(new FormData(event.target)).file

    try {
      await ajaxClient.create(appleId, file)
    } catch(e) {
      handleRailsError(e)
    }

    setIsLoading(false)
  }, [handleRailsError, setIsLoading, pushCertificate])

  return (
      <Container>
        <Grid item>
          <Paper sx={{maxWidth: 600}}>
            <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSuccess)} >
              <Grid container direction='column'>
                <Header>Just one more step</Header>
                <p>
                  To communicate with your devices, SimpleMDM requires a connection to Apple.
                  You can use any Apple ID for this purpose.
                  It does not need to be associated with Apple Business Manager or your devices.
                </p>
                <p style={{fontStyle: 'italic'}}>
                  This process must be completed on a desktop or laptop computer.
                </p>
                <List sx={{ listStyle: "decimal", paddingBottom: 4, paddingLeft: '20px' }}>
                  <ListItem sx={LIST_ITEM_STYLE}>
                    Download the&nbsp;
                    <Link fontWeight='bold' href={AdminRoutes.scsrAdminPushCertificatePath()}>
                      certificate signing request
                    </Link>
                    &nbsp;file.
                  </ListItem>
                  <ListItem sx={LIST_ITEM_STYLE}>
                    Visit the&nbsp;
                    <Link fontWeight='bold' href="https://identity.apple.com/pushcert/" target="_blank">
                      Apple Push Certificate Portal
                    </Link>
                    &nbsp;and sign in with your Apple ID. Create an Apple ID&nbsp;
                    <Link fontWeight='bold' href="https://appleid.apple.com/" target="_blank">here</Link>
                    &nbsp;if needed.
                  </ListItem>
                  <ListItem sx={LIST_ITEM_STYLE}>Click 'Create a Certificate' and upload the file downloaded in step 1.</ListItem>
                  <ListItem sx={LIST_ITEM_STYLE}>Click the button to download your certificate from Apple.</ListItem>
                  <ListItem sx={LIST_ITEM_STYLE}>Select the file downloaded from Apple and upload it below.</ListItem>
                </List>
                <Grid item backgroundColor={theme.simplemdm.colors.grey} borderRadius={paperBorderRadius} marginBottom={2} padding={2}>
                  <FileInputElement
                    control={formContext.control}
                    id="file"
                    label="Certificate File"
                    name="file"
                    required
                    sx={{ paddingBottom: 2 }}/>
                  <TextFieldElement
                    id="appleId"
                    fullWidth
                    inputProps={{sx: { backgroundColor: 'white' }}}
                    label="Apple Id"
                    name="appleId"
                    placeholder="Email address of Apple ID used"
                    required
                    sx={{ paddingBottom: 2 }}
                    type='email'
                    variant="outlined"
                  />
                  <FormHelperText>We save this to help remind you which Apple ID you used.</FormHelperText>
                </Grid>
                <Button
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  variant="contained">
                  Upload
                </Button>
                <Link
                  component={ Button }
                  data-method="delete"
                  href={ AdminRoutes.destroyUserSessionPath({ format: null }) }
                  underline="none">
                  Sign out
                </Link>
              </Grid>
            </FormContainer>
          </Paper>
        </Grid>
      </Container>
  )
}

export default (props) => <ThemeProvider typography={{fontFamily:'"Inter", "Helvetica", "Arial", sans-serif'}}><PushCertificate {...props}/></ThemeProvider>
