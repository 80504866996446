import React from 'react';
import PropTypes from 'prop-types';
import { bindMethods } from "../../shared/functions";
import Checkbox from "../forms/checkbox";
import FormGroup from "../forms/form_group";
import AppSearch from "../shared/app_search";

export default class AppNotificationSetting extends React.Component {
    static newAppNotificationSetting = () => {
        return {
            bundleIdentifier:         null,
            name:                     null,
            alertType:                1,
            badgesEnabled:            true,
            criticalAlertEnabled:     false,
            groupingType:             0,
            notificationsEnabled:     true,
            previewType:              0,
            showInCarPlay:            true,
            showInLockScreen:         true,
            showInNotificationCenter: true,
            soundsEnabled:            true,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            appNotificationSetting: Object.assign({}, props.appNotificationSetting),
            showBanners:            props.appNotificationSetting.alertType > 0,
            appError:               false
        }
        bindMethods(this);
    }

    toggleShowBanners(e) {
        const showBanners = e.target.checked
        const alertType   = showBanners ? 1 : 0
        this.editProperty('alertType', alertType)
        this.setState({ showBanners })
    }

    delete(e) {
        e.preventDefault();
        if (confirm("Are you sure?")) {
            this.props.delete()
        }
    }

    complete(e) {
        e.preventDefault();
        this.props.complete(Object.assign({}, this.state.appNotificationSetting))
    }

    cancel(e) {
        e.preventDefault()
        this.props.cancel()
    }

    editProperty(name, value) {
        const appNotificationSetting = Object.assign({}, this.state.appNotificationSetting, {
            [name]: value
        });

        this.setState({ appNotificationSetting })
    }

    renderSelect(title, property, options, hint = null) {
        return <FormGroup title={ title } hint={ hint }>
            <select value={ this.state.appNotificationSetting[property] }
                    disabled={ this.props.policy.update !== true || !this.state.appNotificationSetting.notificationsEnabled }
                    onChange={ (e) => this.editProperty(property, parseInt(e.target.value)) }>
                {
                    options.map((text, value) => <option key={ `option-${ value }` } value={ value }>{ text }</option>)
                }
            </select>
        </FormGroup>
    }

    selectApp(app) {
        const { name, bundleIdentifier } = app
        const appNotificationSetting     = Object.assign({}, this.state.appNotificationSetting, {
            name, bundleIdentifier
        });
        let appError                     = false;
        this.props.appNotificationSettings.forEach(existingAppNotificationSetting => {
            if (existingAppNotificationSetting.name === appNotificationSetting.name) {
                appError = "An app with the same name has already been added"
            }

            if (existingAppNotificationSetting.bundleIdentifier === appNotificationSetting.bundleIdentifier) {
                appError = "An app with the same bundle id has already been added"
            }
        })

        this.setState({ appNotificationSetting, appError })
    }

    renderButtons(saveButtonClasses) {
        const saveButton = this.props.policy.update ?
            <a className={ saveButtonClasses.join(' ') }
               onClick={ this.complete }>{ this.props.newRecord ? "Add" : "Update" }</a> : null
        const cancelText = this.props.policy.update ? "Cancel" : "Back"
        return <div className="buttons">
            <a className="btn btn-default" href="#cancel" onClick={ this.cancel }>{ cancelText }</a>
            { saveButton }
        </div>;
    }

    render() {
        const cantUpdate        = this.props.policy.update !== true;
        const setting           = this.state.appNotificationSetting;
        const disabled          = cantUpdate || !setting.notificationsEnabled;
        const saveButtonClasses = ["btn", "btn-primary"]
        if (setting.name == null || this.state.appError) {
            saveButtonClasses.push("disabled")
        }
        const deleteButton = (this.props.newRecord || cantUpdate) ? null :
            <a className="btn btn-danger pull-right" href="#delete" onClick={ this.delete }>Delete</a>
        return <React.Fragment>
            <h1>{ setting.name || "New" } <small>App Notification Settings</small></h1>
            <div className="well">
                <form id="app-notification-setting" className="simple_form form-horizontal">
                    { deleteButton }
                    <h3>App Notification Settings</h3>
                    <p>Configure App specific notification settings</p>

                    <AppSearch
                        selectedApp={ {
                            name:             this.state.appNotificationSetting.name,
                            bundleIdentifier: this.state.appNotificationSetting.bundleIdentifier
                        } }
                        selectApp={ this.selectApp }
                        newRecord={ this.props.newRecord }
                        error={ this.state.appError }
                    />

                    <Checkbox name="critical_alert_enabled"
                              checked={ setting.criticalAlertEnabled }
                              disabled={ cantUpdate }
                              hint="Critical alerts appear on the lock screen and play a sound even if Do Not Disturb is on or iPhone is muted"
                              onChange={ (e) => this.editProperty("criticalAlertEnabled", e.target.checked) }>
                        Allow Critical Alerts
                    </Checkbox>

                    <Checkbox name="notifications_enabled"
                              disabled={ cantUpdate }
                              checked={ setting.notificationsEnabled }
                              onChange={ (e) => this.editProperty("notificationsEnabled", e.target.checked) }>
                        Allow Notifications
                    </Checkbox>

                    <Checkbox name="show_in_lock_screen"
                              checked={ setting.showInLockScreen }
                              disabled={ disabled }
                              onChange={ (e) => this.editProperty("showInLockScreen", e.target.checked) }>
                        Show on Lock Screen
                    </Checkbox>

                    <Checkbox name="show_in_notification_center"
                              checked={ setting.showInNotificationCenter }
                              disabled={ disabled }
                              onChange={ (e) => this.editProperty("showInNotificationCenter", e.target.checked) }>
                        Show in Notification Center
                    </Checkbox>

                    <Checkbox name="show_banners"
                              checked={ this.state.showBanners }
                              disabled={ disabled }
                              onChange={ this.toggleShowBanners }>
                        Show Banners
                    </Checkbox>

                    { this.state.showBanners ?
                        this.renderSelect("Banner Style", "alertType", ["None", "Temporary", "Persistent"]) : null
                    }

                    <Checkbox name="show_in_car_play"
                              checked={ setting.showInCarPlay }
                              disabled={ disabled }
                              onChange={ (e) => this.editProperty("showInCarPlay", e.target.checked) }>
                        Show in CarPlay
                    </Checkbox>

                    <Checkbox name="sounds_enabled"
                              checked={ setting.soundsEnabled }
                              disabled={ disabled }
                              onChange={ (e) => this.editProperty("soundsEnabled", e.target.checked) }>
                        Sounds
                    </Checkbox>

                    <Checkbox name="badges_enabled"
                              checked={ setting.badgesEnabled }
                              disabled={ disabled }
                              onChange={ (e) => this.editProperty("badgesEnabled", e.target.checked) }>
                        Badges
                    </Checkbox>

                    { this.renderSelect("Preview type", "previewType",
                        ["Always", "When Unlocked", "Never"], "Available in iOS 14 and later.") }
                    { this.renderSelect("Notification Grouping", "groupingType", ["Automatic", "By App", "Off"]) }


                    { this.renderButtons(saveButtonClasses) }

                </form>
            </div>
        </React.Fragment>
    }

}

AppNotificationSetting.proptypes = {
    appNotificationSetting: PropTypes.shape({
        bundleIdentifier:         PropTypes.string,
        name:                     PropTypes.string,
        alertType:                PropTypes.number.isRequired,
        badgesEnabled:            PropTypes.bool.isRequired,
        criticalAlertEnabled:     PropTypes.bool.isRequired,
        groupingType:             PropTypes.number.isRequired,
        notificationsEnabled:     PropTypes.bool.isRequired,
        previewType:              PropTypes.number.isRequired,
        showInCarPlay:            PropTypes.bool.isRequired,
        showInLockScreen:         PropTypes.bool.isRequired,
        showInNotificationCenter: PropTypes.bool.isRequired,
        soundsEnabled:            PropTypes.bool.isRequired,

    }),
    editProperty:           PropTypes.func.isRequired
}