export const app_notification_settings = {
    new() {
        this.show();
    },

    show() {
        const allowNotificationsInput = $('#app_notification_setting_notifications_enabled');
        const otherInputs             = $('.notification-settings').find('input, select').not(
            function(index, item) {
                item = $(item);
                return item.attr('name') === allowNotificationsInput.attr('name') ||
                    item.attr('name') === 'app_notification_setting[critical_alert_enabled]'
            }
        );

        allowNotificationsInput.change(function() {
            if ($(this).prop('checked')) {
                otherInputs.prop('disabled', false);
            } else {
                otherInputs.prop('disabled', true);
            }
        });

        allowNotificationsInput.trigger('change');

        const showBannersInput = $('#app_notification_setting_show_banners');
        const alertTypeForm    = $('.form-group.app_notification_setting_alert_type')
        const alertTypeInput   = $('#app_notification_setting_alert_type');

        showBannersInput.change(function() {
            if($(this).prop('checked')) {
                alertTypeForm.show();
                alertTypeInput.prop('disabled', false);
            } else {
                alertTypeForm.hide();
                alertTypeInput.prop('disabled', true);
            }

        });
        showBannersInput.trigger('change');
    }
};