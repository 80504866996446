import React from 'react';
import { apiKeys as ajaxClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { truncate } from "../../shared/functions";

const getAjaxClient = () => ajaxClient;

class APIKeysPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.pagedTableProps            = this.pagedTableProps.bind(this);
        this.renderAddCertificateButton = this.renderAddCertificateButton.bind(this);
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "API Key", key: "apiKey" },
                { displayName: "Last Used", key: "lastUsed" },
            ],
            renderRow: (row) => {
                return Object.assign({}, row, {
                    name: <a href={ AdminRoutes.adminAPIKeyPath(row.id, {format: null}) }>{ truncate(row.name, 50) }</a>
                });
            },
            showCheck: false
        };
    }

    renderAddCertificateButton() {
        if (this.props.policy.create) {
            return <a href={ AdminRoutes.newAdminAPIKeyPath({format: null}) } className="pull-right btn btn-primary">
                Add API Key
            </a>;
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No API keys have been created yet.
            </div>
        );

        const message = <p>API keys provide access to your SimpleMDM account and should be protected.
            Each key can be scoped to have different permissions.</p>;

        const actions = (
            <React.Fragment>
                { this.renderAddCertificateButton() }
            </React.Fragment>
        );
        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }

}

export default pagedTableLayout(APIKeysPagedTable, getAjaxClient, undefined, AdminRoutes.adminAPIKeysPath({format: null}));