

export const munki = {
    show() {
        const editor = ace.edit("editor");

        editor.setTheme("ace/theme/tomorrow");
        editor.session.setUseWorker(false);
        editor.session.setMode("ace/mode/json");

        const installDialogText = document.querySelector('#account_install_dialog_script');
        editor.getSession().setValue(installDialogText.value);
        editor.getSession().on('change', function(){
            installDialogText.value = editor.getSession().getValue();
        });
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },

};