export const service_configuration_files = {
    show() {
        this.fn.updateServiceTypeField()
        const serviceType = document.getElementById('service_configuration_file_service_type')
        serviceType.addEventListener('change', this.fn.updateServiceTypeField)
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },
    fn: {
        updateServiceTypeField() {
            const serviceType = document.getElementById('service_configuration_file_service_type')
            const customServiceTypeRow = document.querySelector('.service_configuration_file_custom_service_type')

            if (serviceType.value === 'other') {
                customServiceTypeRow.classList.remove('hidden')
            } else {
                customServiceTypeRow.classList.add('hidden')
            }
        }
    }
};