const updateVisible = () => {
    const asam = $('.asam')
    const sam  = $('.sam')

    if ($('#single_app_lock_autonomous_mode_true:checked').val() === "true") {
        asam.show()
        sam.hide()
    } else {
        asam.hide()
        sam.show()
    }
}

const wireAndUpdate = () => {
    $('#single_app_lock_autonomous_mode_true, #single_app_lock_autonomous_mode_false').on('change', updateVisible)
    updateVisible()
}


export const single_app_locks = {
    new() {
        wireAndUpdate()
    },
    show() {
        wireAndUpdate()
    },
    create() {
        wireAndUpdate()
    },
    update() {
        wireAndUpdate()
    },

}
