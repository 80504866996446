import React from "react"
import Joyride from '../shared/joyride'

const JOYRIDE_STEPS = [
  {
    content: <p>That's it! To get started, click <a href={AdminRoutes.adminEnrollmentCodesPath({ format: null })}>Enroll Devices</a> to enroll your first device.</p>,
  }
]

export default function OutroJoyride() {
  return <Joyride steps={JOYRIDE_STEPS}/>
}
