import React from 'react'
import {default as MuiPaper} from '@mui/material/Paper'

export const borderRadius = '.75rem'
export const padding = '32px'

const Paper = (props) => {
  const {sx: sxOverrides, ...otherProps} = props
  const sx = {
    borderRadius: borderRadius,
    marginTop: '80px',
    overflow:'hidden',
    padding: padding,
    ...sxOverrides,
  }
  return (
    <MuiPaper
      elevation={3}
      sx={sx}
      {...otherProps}
    />
  )
}

export default Paper
