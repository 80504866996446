import React from 'react';
import { appGroups as ajaxClient } from '../../ajax_clients';
import SearchBar from "../search/search_bar";
import { bindMethods } from "../../shared/functions";
import { renderCompatibilityLabel } from '../catalog/apps_paged_table'

class AddSharedApp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            search: null,
            data:  props.data || []
        }
        bindMethods(this)
    }

    componentDidMount() {
        if (this.state.data.length === 0) {
            this.readOfficialApps()
        }
    }

    readOfficialApps() {
        ajaxClient.readOfficial().then(({ data }) => {
            this.setState({ data })
        })
    }

    setSearch(search) {
        this.setState({ search })
    }

    filteredAppGroups() {
        const search = this.state.search
        if (search) {
            return this.state.data.filter(appGroup => search.test(appGroup.name))
        } else {
            return this.state.data
        }
    }

    addAppGroup(appGroup) {
        appGroup = { ...appGroup, inAccount: true }
        this.replaceAppGroup(appGroup)
        ajaxClient.addOfficial(appGroup.id)
            .then(({ data }) => { this.replaceAppGroup(data) })
            .catch(this.readOfficialApps)
    }

    removeAppGroup(appGroup) {
        appGroup = { ...appGroup, inAccount: false }
        this.replaceAppGroup(appGroup)
        ajaxClient.removeOfficial(appGroup.id)
            .then(({ data }) => { this.replaceAppGroup(data) })
            .catch(this.readOfficialApps)
    }

    replaceAppGroup(updatedAppGroup) {
        const data = this.state.data.map(appGroup => appGroup.id === updatedAppGroup.id ? updatedAppGroup : appGroup)
        this.setState({ data })
    }

    renderButton(appGroup) {
        if (appGroup.inAccount) {
            const remove = (e) => {
                e.preventDefault()
                this.removeAppGroup(appGroup)
            };
            return <a href="#remove" onClick={ remove } className="btn btn-default btn-sm">Remove</a>
        } else {
            const add = (e) => {
                e.preventDefault()
                this.addAppGroup(appGroup)
            };
            return <a href="#add" onClick={ add } className="btn btn-default btn-sm">Add</a>
        }
    }

    renderResults() {
        return this.filteredAppGroups().map(appGroup => {
            const latestVersion = appGroup.apps.find(
                app => app.id === appGroup.latestVersionId
            )
            return (
                <tr key={ appGroup.id }>
                    <td className="icon">
                        <img src={ latestVersion.iconUrl }/>
                    </td>
                    <td className="name">
                        <strong>{ latestVersion.name }</strong>
                    </td>
                    <td className="version">{ latestVersion.version }</td>
                    <td className="compatibility">{ renderCompatibilityLabel(latestVersion) } </td>
                    <td className="description">{ appGroup.description }</td>
                    <td className="actions">
                        { this.renderButton(appGroup) }
                    </td>
                </tr>
            )
        })
    }

    render() {
        return <React.Fragment>
            <h1>
                <span className="glyphicon glyphicon-text-background apps-icon"/>
                Add <small>Shared App</small>
            </h1>
            <div className="top-buttons">
                <a href={ AdminRoutes.adminCatalogPath({format: null}) } className="btn btn-default" id="back-button"><i className="glyphicon glyphicon-chevron-left"></i> Back</a>
            </div>

            <div className="search">
                <SearchBar onSearchChange={ this.setSearch } placeholderText={ "Filter by name." }/>
            </div>

            <div className="well">
                <table className="search-results table table-condensed table-striped">
                    <thead>
                    <tr>
                        <th/>
                        <th>Name</th>
                        <th>Latest Version</th>
                        <th>Compatibility</th>
                        <th>Description</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderResults() }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    }
}

export default AddSharedApp