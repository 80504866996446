import allSettled from "promise.allsettled";

export const vppTokens = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminVPPTokenPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminVPPTokensPath(idOrOptions));
        }
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id =>  $.ajax({
            method: 'DELETE',
            dataType: 'json',
            url: AdminRoutes.adminVPPTokenPath(id)
        })));
    }

};