import React from 'react';
import { mediaInstalledMedia as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds } from '../../shared/functions';

const getAjaxClient = ({ mediaId }) => {
    return {
        read:            (query) => ajaxClient.read(mediaId, query),
        destroyMultiple: (installedMedia) => callWithIds(installedMedia, ajaxClient.destroyMultiple)
    }
};

class InstalledMediaPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this)
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Delete",
                policy: 'destroy'
            }
        ];
        return <div className="button-list-container">
            <ActionDropDown
                text="Actions"
                policy={ this.props.policy }
                items={ items }
                disabled={ !this.props.hasCheckedItems() }/>
        </div>
    }

    removeItems(e) {
        this.props.removeItems(e, 'This will uninstall the media from the device. Are you sure?')
    }

    pagedTableProps() {
        return {
            headers: [
                { displayName: 'Device', key: 'device' },
                { displayName: 'Title', key: 'title' },
                { displayName: 'Author', key: 'author' },
                { displayName: 'Kind', key: 'kind' },
                { displayName: 'Installed', key: 'installed' },
                { displayName: 'Date Installed', key: 'discovered' },
            ],
            renderRow: (row) => {
                let device;
                if (row.device) {
                    device = <a href={ AdminRoutes.adminDevicePath(row.device.id, {format: null}) }>{ row.device.name }</a>
                }
                return { ...row, device }
            }
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No installed media found
            </div>
        );

        const message = <p>
            This tab shows installed instances of the media according to the most recent device inventory data.
            Please note that inventory data may sometimes be outdated.
            Read more in our <a
            href='https://simplemdm.zendesk.com/hc/en-us/articles/9355267128987'
            target="_blank">
            documentation
            </a>.
        </p>;

        const actions = this.renderActionButton();
        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions, { leftSize: 11, rightSize: 1}) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(InstalledMediaPagedTable, getAjaxClient, undefined, null);