export const deviceUsers = {
    read(deviceId, options) {
        return $.getJSON(AdminRoutes.adminDeviceDeviceUsersPath(deviceId, options));
    },

    destroyMultiple(deviceId, ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.destroyMultipleAdminDeviceDeviceUsersPath(deviceId),
            data:     JSON.stringify({ ids })
        })
    },

    unlockMultiple(deviceId, ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.unlockUserAccountsAdminDeviceDeviceUsersPath(deviceId),
            data:     JSON.stringify({ ids })
        })
    }


}
