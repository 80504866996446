import React from 'react';
import { scripts as ajaxClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import ActionDropDown from "../shared/action_drop_down";
import { truncate, bindMethods } from "../../shared/functions";

const getAjaxClient = () => ajaxClient;

class ScriptsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" }
            ],
            renderRow: (row) => {
                const name = (
                    <a href={ AdminRoutes.adminScriptPath(row.id, {format: null}) }>{ truncate(row.name, 50) }</a>
                )
                return Object.assign({}, row, { name });
            }
        };
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroyMultiple'
            }
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    renderCreateButton() {
        if (this.props.policy.create) {
            return (
                <a href={ AdminRoutes.newAdminScriptPath({format: null}) } className="btn btn-primary pull-right">Create Script</a>
            )
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No scripts have been created yet.
            </div>
        );

        const actions = (
            <React.Fragment>
                { this.renderActionButton() }
                { this.renderCreateButton() }
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <div className="special-container">
                    <h1><span className="glyphicon glyphicon-open-file scripts-icon"/>Scripts</h1>
                    <div className="top-buttons">
                        { actions }
                    </div>
                </div>
                <div className="well">
                    <div id="scripts-table">
                        <div className="clearfix">
                            <p className="pull-left">
                                Scripts can only be run on macOS devices.
                            </p>
                        </div>
                        { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default pagedTableLayout(ScriptsPagedTable, getAjaxClient);