import allSettled from "promise.allsettled";

export const scriptJobs = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminScriptJobPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminScriptJobsPath(idOrOptions));
        }
    },

    create(data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ scriptJob: data }),
            url:         AdminRoutes.adminScriptJobsPath()
            });
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      AdminRoutes.adminScriptJobPath(id)
        })));
    }
};