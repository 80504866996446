const createGroupedCheckboxes = (allowAllCheckbox, groupedInputs) => {
    const setAllowConfigCheckbox = () => {
        const checkedSettings = groupedInputs.filter(setting => setting.checked);
        if (checkedSettings.length === groupedInputs.length) {
            allowAllCheckbox.checked = true
            allowAllCheckbox.indeterminate = false
        } else if (checkedSettings.length === 0) {
            allowAllCheckbox.checked = false
            allowAllCheckbox.indeterminate = false
        } else {
            allowAllCheckbox.checked = false
            allowAllCheckbox.indeterminate = true
        }
    };
    groupedInputs.forEach(setting => setting.addEventListener('change', setAllowConfigCheckbox))
    setAllowConfigCheckbox();

    allowAllCheckbox.addEventListener('change', () => {
        groupedInputs.forEach(setting => setting.checked = allowAllCheckbox.checked);
    });
}

const enableNoAccess = () => {
    const noAccessCheckbox = document.querySelector('[type=checkbox][name=user_role\\[no_access\\]]');
    const disableOtherInputs = () => {
        document.querySelectorAll('input[type=checkbox]:not([name=user_role\\[no_access\\]])').forEach(input => {
            input.disabled = noAccessCheckbox.checked;
        })
    }

    noAccessCheckbox.addEventListener('change', disableOtherInputs);
    disableOtherInputs();
}

export const user_roles = {
    show() {
        enableNoAccess();
        createGroupedCheckboxes(
            document.querySelector('[type=checkbox][name=user_role\\[allow_all_configuration_settings\\]]'),
            Array.from(document.querySelectorAll('#configuration-settings input[type=checkbox]'))
        )

        createGroupedCheckboxes(
            document.querySelector('[type=checkbox][name=user_role\\[allow_all_secret_info_settings\\]]'),
            Array.from(document.querySelectorAll('#secret-information-visibility input[type=checkbox]'))
        )

    },

    new() {
        this.show();
    }
};