import React from 'react';
import AssignmentGroupAssignedAssets from "./assignment_group/assigned_assets";
import AssignmentGroupAssignedDevices from "./assignment_group/assigned_devices";
import AssignmentGroupAvailableAssets from "./assignment_group/available_assets";
import AssignmentGroupAvailableDevices from "./assignment_group/available_devices";
import ActionDropDown from "../shared/action_drop_down";
import * as ajaxClients from '../../ajax_clients';
import { bindMethods, dig } from "../../shared/functions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const handleJSONError = response => {
    const errorMessage = dig(['responseJSON', 'error', 'message'], response)
    if (errorMessage) {
        addFlashAlert('error', errorMessage)
    }
};

export default class AssignmentGroup extends React.Component {

    static renderLabels(assignmentGroup) {
        const labels                 = []
        const getDeploymentAttribute = (text, tooltipText) => {
            return <OverlayTrigger key={ text } placement="top" overlay={ <Tooltip id={ "text" }>{ tooltipText }</Tooltip> }>
                <span className="label-outline label-outline-success">{ text }</span>
            </OverlayTrigger>
        }
        if (typeof assignmentGroup.priority !== undefined && assignmentGroup.type === 'standard' && assignmentGroup.profileIds.length > 0) {
            labels.push(getDeploymentAttribute(`profile priority: ${assignmentGroup.priority}`, "Assignment Group Priority determines which profiles are installed in the event of a conflict. Lower priorities are installed first."))
        }

        if (assignmentGroup.deploymentAttributes.includes('munki')) {
            labels.push(getDeploymentAttribute("munki", "App installations are managed by Munki"))
        }

        if (assignmentGroup.deploymentAttributes.includes('selfServe')) {
            labels.push(getDeploymentAttribute(
                "self serve",
                "Apps assigned to this assignment group can be selected by a user to download to their device.")
            )
        }
        if (assignmentGroup.deploymentAttributes.includes('defaultInstalls')) {
            labels.push(getDeploymentAttribute("default installs", "Apps assigned to this assignment group are installed by default"))
        }

        if (assignmentGroup.deploymentAttributes.includes('managed')) {
            labels.push(getDeploymentAttribute("managed", "Apps assigned to this assignment group are installed automatically."))
        }

        if (assignmentGroup.deploymentAttributes.includes('managedUpdates')) {
            labels.push(getDeploymentAttribute("managed updates", "Apps assigned to this assignment group are updated automatically."))
        }

        if (assignmentGroup.permissions.munki && assignmentGroup.deploymentAttributes.includes('standard')) {
            labels.push(getDeploymentAttribute("standard", "App installations are managed by MDM"))
        }
        if (assignmentGroup.deploymentAttributes.includes('autoDeploy')) {
            labels.push(getDeploymentAttribute(
                "auto deploy",
                "Automatically install these apps and media when devices enroll or join device groups.")
            )
        }
        if (assignmentGroup.deploymentAttributes.includes('installDialog')) {
            labels.push(getDeploymentAttribute("install dialog", "Display a dialog on the device when installing apps during DEP enrollment."))
        }
        return labels
    }

    static quickDelete(assignmentGroup, collectionName, idToDelete) {
        return Object.assign({}, assignmentGroup,
            {
                [collectionName]: assignmentGroup[collectionName].filter((id) => id !== idToDelete)
            }
        );
    }

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    syncProfiles(e) {
        e.preventDefault()
        ajaxClients.assignmentGroups.syncProfiles(this.props.assignmentGroup.id)
    }

    push(e) {
        e.preventDefault();
        ajaxClients.assignmentGroups.push(this.props.assignmentGroup.id);
    }

    pushUpdates(e) {
        e.preventDefault();
        ajaxClients.assignmentGroups.pushUpdates(this.props.assignmentGroup.id)
    }

    updateCatalog(e) {
        e.preventDefault();
        ajaxClients.assignmentGroups.updateCatalog(this.props.assignmentGroup.id)
    }

    destroy(e) {
        e.preventDefault();
        const userConfirmed = confirm(
            `Are you sure you want to delete the assignment group "${ this.props.assignmentGroup.name }"?`
        );
        if (userConfirmed) {
            ajaxClients.assignmentGroups.destroy(this.props.assignmentGroup.id)
                .then(() => this.props.destroyAssignmentGroup(this.props.assignmentGroup))
                .catch(handleJSONError);
        }
    }

    collapse(e) {
        e.preventDefault();
        this.props.onCollapse(this.props.assignmentGroup);
    }

    renderActionDropDown() {
        const items = [
            {
                action:  this.push,
                text:    "Install Apps and Media",
                tooltip: "Install Apps and Media to assigned groups and devices",
                policy:  'push'
            },
            {
                action:  this.pushUpdates,
                text:    "Update Apps",
                tooltip: "Update installed apps on devices",
                policy:  'pushUpdates'
            },
            {
                action:  this.updateCatalog,
                text:    "Update Catalog",
                tooltip: "Updates the apps listed in the self serve catalog for devices",
                policy:  'updateCatalog'
            },
            {
                action: this.syncProfiles,
                text: "Sync Profiles",
                tooltip: "Syncs the profiles assigned to this assignment group to the devices assigned devices",
                policy: 'syncProfiles'
            },
            {
                href:   AdminRoutes.editAdminAssignmentGroupPath(this.props.assignmentGroup.id, {format: null}),
                text:   "Edit",
                policy: 'edit'
            },
            {
                action: this.destroy,
                text:   'Delete',
                policy: 'destroy'
            }

        ];

        return <ActionDropDown text="Actions" items={ items } policy={ this.props.assignmentGroup.permissions }/>
    }

    renderAppsMediaHeader() {
        const span = <span className="deploy-to">deploy to<i className="glyphicon glyphicon-arrow-right"/></span>
        if (this.props.assignmentGroup.type === 'munki') {
            return <h3>Apps { span }</h3>
        } else {
            return <h3>Apps, Media &amp; Profiles { span }</h3>
        }
    }

    render() {
        const assignmentGroup = this.props.assignmentGroup;
        return (
            <div className="asset-group expanded">
                <table className="header">
                    <tbody>
                    <tr>
                        <td className="name">
                            <span onClick={ this.collapse }>{ assignmentGroup.name }</span>
                            { AssignmentGroup.renderLabels(assignmentGroup) }
                        </td>
                        <td>
                            { this.renderActionDropDown() }
                            <a
                                href="#"
                                onClick={ this.collapse }
                                rel="tooltip"
                                title="Collapse assignment group display."
                                className='btn btn-default collapse-button'>
                                <span className="glyphicon glyphicon-chevron-up"/>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className="row asset-group-contents">
                    <div className="col-xs-6">
                        { this.renderAppsMediaHeader() }
                        <AssignmentGroupAvailableAssets
                            assignmentGroup={ this.props.assignmentGroup }
                            catalog={ this.props.catalog }
                            updateAssignmentGroup={ this.props.updateAssignmentGroup }
                        />
                        <AssignmentGroupAssignedAssets
                            assignmentGroup={ this.props.assignmentGroup }
                            catalog={ this.props.catalog }
                            updateAssignmentGroup={ this.props.updateAssignmentGroup }
                            allDataLoaded={ this.props.allDataLoaded }
                        />
                    </div>

                    <div className="col-xs-6 devices-listing">
                        <h3>Devices</h3>
                        <AssignmentGroupAvailableDevices assignmentGroup={ assignmentGroup }
                                                         devices={ this.props.devices }
                                                         groups={ this.props.groups }
                                                         updateAssignmentGroup={ this.props.updateAssignmentGroup }
                        />
                        <AssignmentGroupAssignedDevices assignmentGroup={ assignmentGroup }
                                                        devices={ this.props.devices }
                                                        groups={ this.props.groups }
                                                        updateAssignmentGroup={ this.props.updateAssignmentGroup }
                                                        allDataLoaded={ this.props.allDataLoaded }
                        />
                    </div>
                </div>
            </div>
        )
    }

}