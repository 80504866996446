import React from 'react';
import PropTypes from 'prop-types';
import { bindMethods } from "../../shared/functions";

export default class PagedTable extends React.Component {
    constructor(props) {
        super(props);
        bindMethods(this);
    }

    checkAll(e) {
        const checkbox = e.target;
        this.props.onCheckAll(checkbox.checked);
    }

    checkOne(index, object, e) {
        const checkbox = e.target;
        this.props.onCheck(index, object, checkbox.checked);
    }

    renderCheckAll() {
        if (this.props.showCheck && this.props.onCheckAll) {
            const canBeChecked = this.props.data.filter(item => !item.checkDisabled);
            return (
                <th className="checkbox-column">
                    <input
                        type="checkbox"
                        onChange={ this.checkAll }
                        checked={ canBeChecked.length > 0 && canBeChecked.every(item => item.checked) }
                    />
                </th>
            )
        }
        return null;
    }

    hasErrors() {
        return this.props.data.some(({ error }) => typeof error !== 'undefined' && error);
    }

    renderHeaders() {
        return this.props.headers.map(
            (header, index) => {
                let content;
                if (header.orderable) {
                    let sortIcon = null;
                    if (this.props.query.order === header.key) {
                        sortIcon = this.renderSortIcon();
                    }
                    const orderBy = (e) => {
                        e.preventDefault();
                        this.props.orderBy(header.key)
                    };
                    content       = <a href={ `#${ header.key }` } onClick={ orderBy }>
                        { header.displayName } { sortIcon }
                    </a>
                } else {
                    content = header.displayName;
                }
                let classNames = header.classNames || [];
                classNames = [...classNames, header.key ];

                if (this.props.togglePagedTableEditor && index === (this.props.headers.length - 1)) {
                    content = <React.Fragment>
                        { content }
                        <a id="edit-paged-table" href="#editor" onClick={ this.props.togglePagedTableEditor }>
                            <span className="glyphicon glyphicon-cog"/>
                        </a>
                    </React.Fragment>
                }
                return <th className={ classNames.join(' ') } key={ `header-${ index }` }>{ content }</th>
            }
        );
    }

    renderSortIcon() {
        switch (this.props.query.order_d) {
            case 'desc':
                return <span className="glyphicon glyphicon-menu-down"/>;
            case 'asc' :
                return <span className="glyphicon glyphicon-menu-up"/>;
        }
    }

    renderData() {
        return this.props.data.map((row, rowNum) => {
                const className = row.error ? "errors" : "";
                return (
                    <tr className={ className } key={ `row-${ rowNum }` }>
                        { this.renderRow(row, rowNum) }
                    </tr>
                );
            }
        );
    }

    renderRow(row, rowNum) {
        if (this.props.renderRow) {
            row = this.props.renderRow(row, rowNum);
        }
        let renderedRow = this.props.headers.map((header, cellNum) => {
            let value = row[header.key]
            return (
                <td className={ header.key } key={ `cell-${ rowNum }-${ cellNum }` }>
                    { value  }
                </td>
            )
        });

        if (this.props.showCheck && this.props.onCheck) {
            const checkColumn = (
                <td className="checkbox-column" key={ `cell-${ rowNum }-checkbox` }>
                    <input
                        onChange={ (e) => this.checkOne(rowNum, row, e) }
                        disabled={ row.checkDisabled }
                        checked={ !!row.checked }
                        type="checkbox"
                    />
                </td>
            );
            renderedRow       = [checkColumn].concat(renderedRow);
        }

        return renderedRow;
    }

    render() {
        return (
            <table className={ this.props.tableClasses.join(" ") }>
                <thead>
                <tr>
                    { this.renderCheckAll() }
                    { this.renderHeaders() }
                </tr>
                </thead>
                <tbody>
                { this.renderData() }
                </tbody>
            </table>
        )
    }
}

PagedTable.propTypes = {
    headers:    PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        key:         PropTypes.string.isRequired,
        orderable:   PropTypes.bool,
        classNames: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    data:       PropTypes.arrayOf(PropTypes.object).isRequired,
    onCheck:    PropTypes.func,
    onCheckAll: PropTypes.func,
    renderRow:  PropTypes.func,
    showCheck:  PropTypes.bool,
    editPagedTable: PropTypes.func,
    tableClasses: PropTypes.arrayOf(PropTypes.string)
};

PagedTable.defaultProps = {
    showCheck: true,
    tableClasses: ["table", "hover"]
};
