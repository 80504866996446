import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class KernelExtensionIdentifiers extends React.Component {

    constructor(props) {
        super(props);
        this.state        = {
            data: props.data,
        };
        this.headers      = [
            { displayName: 'Team Identifier', key: 'teamIdentifier' },
            { displayName: 'Bundle Identifier', key: 'bundleIdentifiers' },
            { displayName: 'Notes', key: 'notes' },
        ];
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem    = this.onAddItem.bind(this);
    }

    onChangeItem(updatedItem, updatedIndex) {
        this.setState({
            data: this.state.data.map((row, index) => index === updatedIndex ? updatedItem : row)
        })
    }

    onRemoveItem(removedItem, removedIndex) {
        this.setState({
            data: this.state.data.filter((item, index) => index !== removedIndex)
        });
    }

    onAddItem() {
        const newItem = {
            teamIdentifier:    "",
            bundleIdentifiers: ""
        };

        this.setState({
            data: [...this.state.data, newItem]
        })
    }

    render() {
        return <DynamicList
            fieldName={ 'kernel_extension_policy[kernel_extension_identifiers_attributes]' }
            headers={ this.headers }
            rows={ this.state.data }
            policy={ this.props.policy }
            onChangeItem={ this.onChangeItem }
            onRemoveItem={ this.onRemoveItem }
            onAddItem={ this.onAddItem }
        />
    }
}