import passwordRevealInput from '../../shared/password_reveal_input'

export const subscribed_calendar_accounts = {
    new() {
        passwordRevealInput()
    },

    show() {
        passwordRevealInput()
    },

    update() {
        passwordRevealInput()
    },

    create() {
        passwordRevealInput()
    }
}