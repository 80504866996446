import { hide, show } from '../../shared/functions'
import passwordRevealInput from '../../shared/password_reveal_input'

const updateForm = () => {
    const select = name => document.querySelector(`.form-group.vpn_account_${ name }`);

    const provider = document.querySelector('#vpn_account_vpn_provider_id option:checked');

    // Connection Type
    const connectionTypeFields = ['realm', 'role', 'group_or_domain', 'local_identifier'];
    const connectionTypeShow   = {
        'l2tp':                     [],
        'ipsec':                    [],
        'ike_v2':                    ['local_identifier'],
        'juniper_ssl':              ['realm', 'role'],
        'sonicwall_mobile_connect': ['group_or_domain']
    };
    const connectionType       = provider.dataset.connectionType

    connectionTypeFields.forEach((field, index) => {
        const shouldShow = connectionTypeShow[connectionType] && connectionTypeShow[connectionType].indexOf(field) > -1;
        field            = select(field)
        if (shouldShow) {
            show(field);
        } else {
            hide(field);
        }
    })



    // Authentication Type

    const authenticationType = provider.dataset.authenticationType
    const passwordGroup      = select('password');

    if ((authenticationType === 'password' && ['l2tp', 'pptp'].indexOf(connectionType) > -1)
        || (['l2tp', 'pptp', 'ipsec'].indexOf(connectionType) === -1)) {
        show(passwordGroup)
    } else {
        hide(passwordGroup);
    }

    // Proxy Type

    const proxyType          = provider.dataset.proxyType;
    const proxyUsernameGroup = select('proxy_username');
    const proxyPasswordGroup = select('proxy_password');

    if (proxyType === 'manual') {
        show(proxyUsernameGroup)
        show(proxyPasswordGroup)
    } else {
        hide(proxyUsernameGroup)
        hide(proxyPasswordGroup)
    }

    const usingCertificate = provider.dataset.usingCertificate
    const certificateFields = document.getElementById('certificate_fields')
    usingCertificate === "true" ? show(certificateFields) : hide(certificateFields)

}

const wireAndUpdateForm   = () => {
    document.getElementById('vpn_account_vpn_provider_id').addEventListener('change', updateForm)
    updateForm();
    passwordRevealInput()
}

export const vpn_accounts = {
    new() {
        wireAndUpdateForm();
    },
    show() {
        wireAndUpdateForm();
    },
    create() {
        wireAndUpdateForm();
    },
    update() {
        wireAndUpdateForm();
    },

};

export const group_vpn_accounts = vpn_accounts;