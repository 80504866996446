import React from 'react';
import { deviceInstalledApplications as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, boolToString, callWithIds, truncate } from '../../shared/functions';
import { SearchBar } from '../search'

const getAjaxClient = ({ deviceId }) => {
    const callWithDeviceIdAndIds = (func) => {
        return (installedApplications) => callWithIds(installedApplications, (ids) => func(deviceId, ids));
    };

    return {
        read:            (query) => ajaxClient.read(deviceId, query),
        destroyMultiple: callWithDeviceIdAndIds(ajaxClient.destroyMultiple),
        manageMultiple:  callWithDeviceIdAndIds(ajaxClient.manageMultiple),
        updateMultiple:  callWithDeviceIdAndIds(ajaxClient.updateMultiple)
    }
};

class InstalledApplicationsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state              = {
            errors: []
        };
        bindMethods(this);
    }

    onSearchChange(searchRegexp, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({
                search,
                page: 1
            })
        }
    }

    renderSearchBar() {
        return <SearchBar
            searchOnEnter={ true }
            timeout={ 600 }
            searching={ this.props.searching }
            initialValue={ this.props.query.search }
            placeholderText='Search by name'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;

    }

    renderActionButton() {
        const items = [
            {
                href:   "#update",
                action: this.updateItems,
                text:   "Install Update",
                policy: 'edit'
            },
            {
                href:   "#manage",
                action: this.manageItems,
                text:   "Request Management",
                policy: 'manage'
            },
            {
                href:         "#delete",
                action:       this.removeItems,
                text:         "Delete",
                policy:       'destroy',
                afterDivider: true,
            }
        ];
        return <div className="button-list-container">
            <ActionDropDown text="Actions"
                            items={ items }
                            policy={ this.props.policy }
                            disabled={ !this.props.hasCheckedItems() }/>
        </div>
    }

    removeItems(e) {
        this.props.removeItems(e, "This will remove the selected apps from the device. Are you sure?");
    }

    updateItems(e) {
        e.preventDefault();
        this.props.callWithItems('updateMultiple');
    }

    manageItems(e) {
        e.preventDefault();
        this.props.callWithItems('manageMultiple');
    }

    pagedTableProps() {
        const props = {
            headers:   [
                { displayName: 'Name', key: 'name' },
                { displayName: 'Version (build)', key: 'version' },
                { displayName: 'Short Version', key: 'shortVersion' },
                { displayName: 'Bundle Identifier', key: 'identifier' },
                { displayName: 'App Size', key: 'appSize' },
                { displayName: 'App Data', key: 'appData' },
                { displayName: 'Managed?', key: 'managed' },
                { displayName: 'Discovered', key: 'discovered' },
            ],
            renderRow: (row) => {
                const name    = truncate(row.name, 50);
                const managed = boolToString(row.managed)
                return Object.assign({}, row, { name, managed });
            },
        };

        if (!this.props.policy.edit) {
            props.showCheck = false;
        }

        return props
    }

    render() {

        let notFound;
        if (this.props.query?.search == null) {
            if (this.props.policy.show) {
                notFound = (
                    <div className="centered alert alert-info" role="alert">
                        Either the device has not yet completed enrollment or only native applications are installed.
                    </div>
                )
            } else {
                notFound = (
                    <div className="centered alert alert-info" role="alert">
                        App inventory is not available for User Enrolled macOS devices.
                    </div>
                )
            }
        } else {
            notFound = (
                <div className="centered alert alert-warning" role="alert">
                    no apps found
                </div>
            );
        }

        let message = <p>
            Please note that inventory data may sometimes be outdated.
            Read more in our <a
            href='https://simplemdm.zendesk.com/hc/en-us/articles/9355267128987'
            target="_blank">
            documentation
        </a>.
        </p>;

        let actions = this.renderActionButton();

        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions) }
                <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                    { this.renderSearchBar() }
                </div>
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(InstalledApplicationsPagedTable, getAjaxClient, undefined, null);