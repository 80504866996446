import React from "react"
import {default as SharedJoyride} from '../shared/joyride'

const JOYRIDE_STEPS = [
  {
    content: <><p>The App/Media catalog is where you add apps and media that you want to install to devices. You can add App Store apps, enterprise apps, and binaries to your catalog. You can also upload media such as PDFs and iBooks. VPP apps and media will appear automatically.</p><p>You can click on an app to see its details, what VPP licenses are in use, and to add redemption codes.</p><p>Click <strong>Done</strong> or press the return key to see how apps and media are deployed.</p></>,
  }
]

export default function Joyride() {
  return (
    <SharedJoyride
      nextPath={AdminRoutes.adminAssignmentGroupsPath({ format: null, intro: true })}
      steps={JOYRIDE_STEPS}
    />
  )
}
