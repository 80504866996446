import passwordRevealInput from '../../shared/password_reveal_input'

export const custom_attributes = {
    show() {
        passwordRevealInput()
    },

    edit() {
        passwordRevealInput()
    },

    update() {
        passwordRevealInput()
    }
}