import React from 'react';
import { deviceAccounts as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { truncate } from '../../shared/functions';

const getAjaxClient = ({ deviceId }) => ({
    read:            query => ajaxClient.read(deviceId, query),
    destroyMultiple: (deviceAccounts) => {
        return ajaxClient.destroyMultiple(deviceAccounts).then((responses) => {
            const failedResponses = responses.filter(({ status }) => status === 'rejected');
            const failureCount    = failedResponses.length;
            if (failureCount > 0) {
                const deviceAccount = failureCount > 1 ? 'device accounts' : 'device account';
                window.addxFlashAlert('error', `Failed to remove ${ failureCount } ${ deviceAccount }.`)
            } else {
                window.addFlashAlert('notice', 'Selected device accounts have been removed')
            }

            return responses;
        })
    }
});

class DeviceAccountsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state              = {
            errors: []
        };
        this.renderActionButton = this.renderActionButton.bind(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroy',
            }
        ];
        return <ActionDropDown policy={ this.props.policy }
                               text="Actions" items={ items }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Account Type", key: "displayName" },
                { displayName: "Username", key: "username" },
            ],
            renderRow: (row) => {
                const name = <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>;

                let error = this.state.errors.find(error => {
                    const { id, accountType } = error.metadata;
                    return row.id === id && row.accountType === accountType;
                });

                if (error) {
                    error = Object.values(error.modelErrors)[0];
                    if (Array.isArray(error)) {
                        error = error[0];
                    }
                }
                return Object.assign({}, row, { name, error });
            },
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No accounts have been configured.
            </div>
        );

        return (
            <div id="device-accounts-table">
                { this.renderActionButton() }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(DeviceAccountsPagedTable, getAjaxClient, undefined, null);