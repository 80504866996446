import React from "react"
import Joyride from '../shared/joyride'

const JOYRIDE_STEPS = [
  {
    content: <><p><strong>Welcome to your account!</strong></p><p>I'll briefly introduce you to SimpleMDM. You can use the return key or click <strong>Next</strong> to go to the next step.</p></>
  },
  {
    content: <><p>Enrolled devices will appear here, on the Devices page.</p><p>Once you've enrolled a device, you can click on its name to view inventory data, configure, and control the device.</p></>
  },
  {
    content: <p>Every device belongs to a device group. Device groups apply shared configurations to devices. You can edit device group configurations, restrictions, and more by visiting the <strong>Groups</strong> section.</p>,
    placement: 'right',
    target: '.groups-link',
  },
  {
    content: <><p>You can create configurations for your devices and groups here. These objects can be shared between multiple device groups.</p><p>Click <strong>Done</strong> or press the return key to continue.</p></>,
    placement: 'right',
    target: '.configs-link',
  },

]

export default function IntroJoyride() {
  return (
    <Joyride
      nextPath={AdminRoutes.adminCatalogPath({ format: null, intro: true })}
      showProgress
      steps={JOYRIDE_STEPS}
    />
  )
}
