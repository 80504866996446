import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class StatusLabel extends React.Component {
    render() {
        const { shortText, description, severity } = this.props.statusReason
        let labelContent
        if (shortText) {
            labelContent = <span className={ `label-outline label-outline-${severity}` }>{ shortText }</span>
        } else {
            labelContent = <span className={ `label label-${severity}` }>
                <span className="glyphicon glyphicon-alert"/>
            </span>
        }

        if (description) {
            const tooltipContent = <Tooltip id="tooltip">{ description }</Tooltip>
            return <OverlayTrigger placement="left" overlay={ tooltipContent }>
                { labelContent }
            </OverlayTrigger>
        } else {
            return labelContent
        }
    }
}
