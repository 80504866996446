import React from 'react';
import { adminNotifications as notificationsClient, users as usersClient } from '../../ajax_clients';
import { bindMethods } from '../../shared/functions';
import PropTypes from "prop-types";

const sortByEmail = (a, b) => {
    const emailA = a.email.toUpperCase(); // ignore upper and lowercase
    const emailB = b.email.toUpperCase(); // ignore upper and lowercase
    if (emailA < emailB) {
        return -1;
    }
    if (emailA > emailB) {
        return 1;
    }
    // names must be equal
    return 0;
}

export default class AdminNotificationUserSelector extends React.Component {
    constructor(props) {
        super(props);
        bindMethods(this);
    }

    changeHandler(notification, user) {
        return (e) => {
            if (e.target.checked) {
                notification = { ...notification, users: [...notification.users, user].sort(sortByEmail) }
                this.props.onUpdate(notification)
                notificationsClient.addUser(notification, user)
                    .then(({ data }) => this.props.onUpdate(data))
                    .catch(this.props.onError)
            } else {
                notification = {
                    ...notification,
                    users: notification.users.filter(other_user => user.id !== other_user.id)
                }
                this.props.onUpdate(notification)
                notificationsClient.removeUser(notification, user)
                    .then(({ data }) => this.props.onUpdate(data))
                    .catch(this.props.onError)
            }
        }
    }

    render() {
        let userList       = [<li key="loading">Loading...</li>];
        const notification = this.props.currentNotification;
        if (this.props.users.length > 0) {
            const selectedUsers = notification.users.map(user => user.id)
            userList            = this.props.users.map(user => {
                const checked = selectedUsers.includes(user.id)
                const disabled = !((this.props.policy && this.props.policy.updateOtherUsers) || user.id === this.props.currentUser.id)
                return <li key={ user.id }>
                    <label>
                        <input
                            type="checkbox"
                            checked={ checked }
                            disabled={ disabled }
                            onChange={ this.changeHandler(notification, user) }
                        />
                        { user.email } { user.sso ? '(sso)' : null }
                    </label>
                </li>
            })
        }
        return (
            <React.Fragment>
                <div id="admin-notification-user-editor" className="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Select Users</h4>
                            </div>
                            <div className="modal-body">
                                <ul> { userList }</ul>

                            </div>
                            <div className="modal-footer">
                                <a
                                    onClick={ (e) => {
                                        e.preventDefault();
                                        this.props.onClose()
                                    } }
                                    href="#close"
                                    className="btn btn-default done">
                                    Close
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade in" />
            </React.Fragment>
    )
    }
}

AdminNotificationUserSelector.propTypes = {
    currentNotification: PropTypes.object.isRequired,
    users:               PropTypes.arrayOf(PropTypes.object).isRequired,
    notifications:       PropTypes.object.isRequired,
    onClose:             PropTypes.func.isRequired,
    onError:             PropTypes.func.isRequired,
    onUpdate:  PropTypes.func.isRequired
};
