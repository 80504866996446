import allSettled from "promise.allsettled";

export const scriptCommands = {
    read(scriptJobId, options) {
        return $.getJSON(AdminRoutes.adminScriptJobCommandsPath(scriptJobId, options));
    },

    destroyMultiple(scriptJobId, ids) {
        return allSettled(ids.map(id => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      AdminRoutes.adminScriptJobCommandPath(scriptJobId, id)
        })));
    }
};