import allSettled from "promise.allsettled";

export const appGroups = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminAppGroupPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminAppGroupsPath(idOrOptions));
        }
    },

    destroy(id) {
        return $.ajax({
            method:      'DELETE',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.adminAppGroupPath(id),
        })
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id => destroy(id)));
    },

    readOfficial(options) {
        return $.getJSON(AdminRoutes.officialAdminAppGroupsPath(options));
    },

    addOfficial(id) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.addOfficialAdminAppGroupsPath(id),

        })
    },

    removeOfficial(id) {
        return $.ajax({
            method:      'DELETE',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.removeOfficialAdminAppGroupsPath(id),

        })
    },

}
