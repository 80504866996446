import React from 'react';
import { bindMethods } from "../../shared/functions";

export default class AppIcon extends React.Component {
    static renderIcons(icons, clickHandler = null) {
        if (icons) {
            return icons.map(icon => {
                let key = icon.key || icon.id;
                key = `${icon.iconType}${key}`
                return <AppIcon key={ key  } icon={ icon } clickHandler={ clickHandler }/>
            });
        }
    }

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    clickHandler(e) {
        if (this.props.clickHandler) {
            this.props.clickHandler(this.props.icon, e);
        }
    }

    render() {
        const className = this.props.icon.iconType === 'folder' ? "app-icon folder-icon" : "app-icon";
        let name;
        if (this.props.icon.iconType === 'folder' && /^\s*$/.test(this.props.icon.name)) {
            name = "Unnamed Folder";
        } else {
            name = this.props.icon.name;
        }
        return (
            <div className={ className } onClick={ this.clickHandler }>
                <img src={ this.props.icon.iconPath } alt={ name } title={ name }/>
                <span className="name">{ name }</span>
            </div>
        )
    }
}