import allSettled from "promise.allsettled";

export const customAttributes = {
    read(idOrOptions) {
        if (typeof idOrOptions === "object") {
            return $.getJSON(AdminRoutes.adminCustomAttributesPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminCustomAttributePath(idOrOptions));
        }
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id => $.ajax({
            method: 'DELETE',
            dataType: 'json',
            url: AdminRoutes.adminCustomAttributePath(id)
        })));
    }

};