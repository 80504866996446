import { show, hide } from '../../shared/functions'
import passwordRevealInput from '../../shared/password_reveal_input'

export const recovery_lock_passwords = {
    show() {
        const macosArc                 = document.getElementById('recovery_lock_password_allowed_macos_architecture')
        macosArc.disabled              = true
        const generatePasswordCheckbox = document.getElementById('recovery_lock_password_generate_password')
        const togglePasswordField      = () => {
            const passwordField = document.querySelector('.form-group.recovery_lock_password_password')
            if (generatePasswordCheckbox.checked) {
                hide(passwordField)
            } else {
                show(passwordField)
            }
        }
        generatePasswordCheckbox.addEventListener('change', togglePasswordField)
        togglePasswordField();
        passwordRevealInput()
    },

    new() {
        this.show();
        passwordRevealInput()
    },

    update() {
        this.show();
        passwordRevealInput()
    },

    create() {
        this.show();
        passwordRevealInput()
    }
};