import passwordRevealInput from '../../shared/password_reveal_input'

const setupForm = (isPersisted = false) => {
    const munkiEnabled        = document.querySelector('input[type=hidden][name="munki_enabled"]').value == "true"
    const typeInputs          = document.querySelectorAll('input[type=radio][name="assignment_group\\[deployment_type\\]"]')
    const standardOptions     = document.querySelector('#standard-options')
    const munkiOptions        = document.querySelector('#munki-options')
    const munkiRadioButton    = document.querySelector('input[type=radio][name="assignment_group\\[deployment_type\\]"][value="munki"]')
    const standardRadioButton = document.querySelector('input[type=radio][name="assignment_group\\[deployment_type\\]"][value="standard"]')
    const showOptions         = (type) => {
        if (type === 'standard') {
            standardOptions.classList.remove('hidden')
            munkiOptions.classList.add('hidden')
        } else {
            standardOptions.classList.add('hidden')
            munkiOptions.classList.remove('hidden')
        }
    }

    typeInputs.forEach(input => {
        input.addEventListener('change', (e) => showOptions(e.target.value));
    })

    if (isPersisted) {
        typeInputs.forEach(input => {
            if (!input.checked) {
                return input.disabled = true
            }
        })
    }

    const selectedInput = document.querySelector('input[type=radio][name="assignment_group\\[deployment_type\\]"]:checked')
    if (selectedInput) {
        showOptions(selectedInput.value)
    }

    if (!munkiEnabled) {
        munkiRadioButton.disabled   = true
        standardRadioButton.checked = true
    }
    passwordRevealInput()
}

export const assignment_groups = {
    index() {
    },
    show() {
        setupForm(true)
    },
    update() {
        setupForm(true)
    },
    edit() {
        setupForm(true)
    },
    new() {
        setupForm()
    }
};