import React, { useCallback } from 'react'
import { get as _get, isEmpty as _isEmpty } from "lodash"
import { visitURL } from "../../shared/query";


const ErrorMessages = ({messages}) => (
  <>
    { messages.map((m, i) => (
      <span key={i} style={{ display: 'block' }}>{m}</span>
    ))}
  </>
)

export const useRailsErrorHandler = (setError) => useCallback((event) => {
  // Turbolinks hijacks Rails redirects and responds with JS that it expects the FE to run.
  // So, we visit the response location header which should be the same as the redirect URL.
  if (event.status === 200) return visitURL(event.getResponseHeader('location'))

  const modelErrors = _get(event, 'responseJSON.error.modelErrors', {})
  const errorMessage = _get(event, 'responseJSON.error.message', "")

  if (!_isEmpty(modelErrors)) {
    return Object.keys(modelErrors).forEach((name) => {
      setError(name, {type: 'custom', message: <ErrorMessages messages={modelErrors[name]}/>})
    })
  } 
  
  if (!_isEmpty(errorMessage)) {
    return window.addFlashAlert('error', errorMessage)
  }
}, [setError])
