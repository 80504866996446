

export const custom_configuration_profiles = {
    show() {
        const editor = ace.edit("editor");
        const isEditable = document.getElementById('custom_configuration_profile_editable').value
        const useCustomAttributesCheckbox = document.getElementById("custom_configuration_profile_variable_support")

        this.toggleEscapeCustomAttributesState(useCustomAttributesCheckbox.checked)

        useCustomAttributesCheckbox.addEventListener("change", (e) => {
            this.toggleEscapeCustomAttributesState(e.target.checked)
        })

        if (isEditable === "false") {
            editor.setReadOnly(true)
            document.querySelectorAll('form, .buttons, input').forEach(item => item.disabled = true)
        }

        editor.setTheme("ace/theme/tomorrow");
        editor.session.setUseWorker(false);
        editor.session.setMode("ace/mode/xml");

        const mobileConfigText = document.querySelector('input[name="mobileconfig_text"]');
        editor.getSession().setValue(mobileConfigText.value);
        editor.getSession().on('change', function(){
            mobileConfigText.value = editor.getSession().getValue();
        });
        editor.setReadOnly(isEditable === "false" || document.querySelector('input[name="custom_configuration_profile[name]"').disabled)

    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },

    toggleEscapeCustomAttributesState(enabled) {
        const escapeCustomAttributesCheckbox = document.getElementById("custom_configuration_profile_escape_variables")

        escapeCustomAttributesCheckbox.disabled = !enabled
    }
};