export const groups = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminGroupPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminGroupsPath(idOrOptions));
        }
    },

    readProfileAssignments(id) {
        return $.getJSON(AdminRoutes.adminGroupProfileAssignmentsPath(id));
    },

    assignProfile(id, profileId) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.adminGroupProfileAssignmentsPath(id),
            data:     JSON.stringify({ profileId })
        });
    },

    unassignProfile(id, profileId) {
        return $.ajax({
            method:   'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.adminGroupProfileAssignmentPath(id, profileId),
        });
    }
};