import { apps as ajaxClient } from '../../ajax_clients'
import DirectUploadUppy from "../shared/direct_upload_uppy";
import React from 'react';

const DirectUploader = ({ data, appId }) => {
    let allowedFileTypes = ['.ipa', '.pkg'] // TODO remove jpg test extension
    let maxNumberOfFiles = 2
    if (appId) {
        allowedFileTypes = ['.ipa']
        maxNumberOfFiles = 1
    } else if (data?.permissions?.uploadDmg) {
        allowedFileTypes.push('.dmg')
    }

    const successHandler = (file) => {
        let name = file.data.name
        let appType;
        if (/\.ipa$/.test(name)) {
            appType = 'enterprise'
        } else {
            appType = 'package'
        }

        return ajaxClient.directUploadAppCallback(
            appType,
            name,
            file.simpleMDMData.uuid,
            appId
        ).then((response) => {
            if (response.redirect_to) {
                Turbolinks.clearCache()
                Turbolinks.visit(response.redirect_to, {
                    "action": "replace"
                })
            }
        })
    }

    return <>
        <DirectUploadUppy
            id="app-uploader"
            allowedFileTypes={allowedFileTypes}
            successHandler={successHandler}
            humanNamePlural='a custom app'
            maxNumberOfFiles={maxNumberOfFiles}
        />
    </>
}

export default DirectUploader