import React from 'react';
import { depDevices as ajaxClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { bindMethods } from "../../shared/functions";
import { SearchBar } from "../search";

const getAjaxClient = ({ depAccountId }) => {
    return {
        read: (query) => ajaxClient.read(depAccountId, query)
    }
}

class DEPDevicesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    onSearchChange(searchRegex, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({
                search,
                page: 1
            })
        }
    }

    renderSearchBar() {
        return <SearchBar
            searchOnEnter={ true }
            timeout={ 600 }
            searching={ this.props.searching }
            initialValue={ this.props.query.search }
            placeholderText='Search by serial number'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Serial Number", key: "appleSerialNumber" },
                { displayName: "Model", key: "appleModel" },
                { displayName: "Color", key: "deviceColor" },
                { displayName: "Last DEP Check-In", key: "depCheckinAt" },
                { displayName: "Linked Device", key: "linkedDevice" },
                { displayName: "Status", key: "status" },
            ],
            showCheck: false,
            renderRow: (row) => {
                let linkedDevice = null;
                let status = "awaiting enrollment"
                if (row.linkedDevice) {
                    linkedDevice = <a href={ AdminRoutes.adminDevicePath(row.linkedDevice.id, {format: null}) }>
                        { row.linkedDevice.name || row.linkedDevice.serialNumber }
                    </a>
                    status = row.linkedDevice.enrollmentStatus
                }
                return { ...row, linkedDevice, status }
            }
        };
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No DEP devices found.
            </div>
        );

        return (
            <div id="dep-devices-codes-table">
                <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                    { this.renderSearchBar() }
                </div>
                { this.props.renderTopSection(null, null) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }

}

export default pagedTableLayout(DEPDevicesPagedTable, getAjaxClient);