import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import { bindMethods } from "../../shared/functions";

export default class Flash extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        bindMethods(this);
    }

    componentDidMount() {
        this.addAlertsFromObject(this.props);
        $(document).ajaxComplete(this.detectAlert);
        window.addFlashAlert = this.addAlert;
    }

    componentWillUnmount() {
        $(document).unbind("ajaxComplete", this.detectAlert);
        clearTimeout(this.timeout);
        delete window.addFlashAlert;
    }

    addAlertsFromObject(object) {
        ['error', 'alert', 'notice'].forEach(alertType => {
            if (object[alertType]) {
                this.addAlert(alertType, object[alertType])
            }
        });
    }

    addAlert(alertType, message) {
        const alertTime = alertType === 'notice' ? 4000 : 10000;
        this.setState({
            [alertType]: message
        }, () => {
            this.timeout = setTimeout(() => {
                this.removeAlert(alertType);
            }, alertTime)
        });
    }

    detectAlert(_event, xhr) {
        if (xhr.responseJSON) {
            let flash;
            if (xhr.responseJSON.meta && xhr.responseJSON.meta.flash) {
                flash = xhr.responseJSON.meta.flash
            } else {
                flash = xhr.responseJSON.flash
            }

            if (flash) {
                this.addAlertsFromObject(flash);
            }
        }
    }

    renderSpecialMessage(message) {
        switch (message) {
            case "account_unconfirmed":
                return <React.Fragment>
                    Your account has not yet been confirmed. Would you like us to <a
                    href="/admin/auth/confirmation/new">resend your enrollment email</a>?
                </React.Fragment>
            default:
                return message
        }
    }

    removeAlert(alertType) {
        this.setState({ [alertType]: null });
    }

    render() {
        let alert;

        if (this.state.error) {
            alert = <div className="alert alert-danger">{ this.renderSpecialMessage(this.state.error) }</div>
        } else if (this.state.alert) {
            alert = <div className="alert alert-warning">{ this.renderSpecialMessage(this.state.alert) }</div>
        } else if (this.state.notice) {
            alert = <div className="alert alert-success">{ this.renderSpecialMessage(this.state.notice) }</div>
        }

        if (alert) {
            alert = (
                <CSSTransition
                    key={ alert }
                    timeout={ 10000 }
                    onEntered={ this.onEntered }
                    classNames="fadeout"
                >
                    { alert }
                </CSSTransition>
            )
        }

        return (
            <div className="flash">
                <TransitionGroup>
                    { [alert] }
                </TransitionGroup>
            </div>
        )
    }
}

Flash.propTypes = {
    notice: PropTypes.string,
    alert:  PropTypes.string,
    error:  PropTypes.string
};
