import React from 'react'
import PropTypes from "prop-types";

const RadioButtonGroup = (props) => {

    const hint         = props.hint ? <p className="help-block">{ props.hint }</p> : null
    const renderOption = (option) => {
        const isChecked = props.value === option.value
        const onChange = () => { props.onChange(option) }
        const optionHint = option.hint ? <p className="help-block">{ option.hint }</p> : null
        return (
            <span key={ `option-${ option.value }` } className="radio">
                <label>
                    <input
                        className="radio_buttons optional"
                        type="radio"
                        value={ option.value }
                        checked={ isChecked }
                        onChange={ onChange }
                        name={ props.name }/>
                    { option.text }
                    { optionHint }
                </label>
            </span>
        )
    }
    return (
        <div className="form-group radio_buttons optional">
            <label className="col-sm-2 control-label radio_buttons optional">{ props.title }</label>
            <div className="col-sm-10">
                { props.options.map(renderOption) }
                { hint }
            </div>
        </div>
    )
}

RadioButtonGroup.propTypes = {
    title:    PropTypes.string.isRequired,
    value:    PropTypes.any.isRequired,
    name:     PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hint:     PropTypes.string,
    options:  PropTypes.arrayOf(PropTypes.shape({
        text:  PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        hint: PropTypes.string
    }))
}

export default RadioButtonGroup