export const logs = {
    read(options) {
        return $.getJSON(AdminRoutes.adminLogsPath(options));
    },

    error: function(message, path, userAgent, stack, lineNumber, columnNumber) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.adminErrorLogsPath(),
            data:     JSON.stringify({
                message,
                path,
                userAgent,
                stack,
                lineNumber,
                columnNumber,
            })
        })
    }
};
