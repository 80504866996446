import React, { useState } from 'react'
import PropTypes from 'prop-types'

const PasswordReveal = ({ url, permitted, dataFetcher }) => {
  const [passwords, setPasswords] = useState()

  const isRevealed = () => {
    return !!passwords
  }

  const content = () => {
    if (permitted && isRevealed()) {
      if (passwords.length === 0 || typeof(passwords) != 'object') {
        return '-'
      } else {
        return(
          <ul className="list-unstyled">
            { passwords.map((password, index) => {
              return (<li key={ index }><code>{ password }</code></li>)
            }) }
          </ul>
        )
      }
    } else {
      return '••••••••••••'
    }
  }

  const handleClick = (e) => {
    e.preventDefault()

    if (!isRevealed()) {
      if (dataFetcher) {
        dataFetcher().then((data) => {
          setPasswords(data)
        })
      } else {
        $.getJSON({ url: url }).then((data) => setPasswords(data))
      }
    } else {
      setPasswords(null)
    }
  }

  const linkText = () => {
    return isRevealed() ? 'hide' : 'reveal'
  }

  const revealLink = () => {
    if (permitted) {
      return (<a onClick={ handleClick } href="">{ linkText() }</a>)
    }
  }

  return (
    <React.Fragment>
      <span className="password-reveal-content">{ content() }</span>
      { revealLink() }
    </React.Fragment>
  )
}

PasswordReveal.propTypes = {
  url: PropTypes.string, // for use with the default data fetcher
  permitted: PropTypes.bool,
  dataFetcher: PropTypes.func
}

export default PasswordReveal