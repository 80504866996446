import React from 'react';
import { webhookEndpoints as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds, truncate } from "../../shared/functions";

const getAjaxClient = () => {
    return {
        ...ajaxClient,
        destroyMultiple: (webhookEndpoints) => {
            return callWithIds(webhookEndpoints, ajaxClient.destroyMultiple)
                .then((responses) => {
                    const failedResponses = responses.filter(({ status }) => status === 'rejected');
                    const failureCount    = failedResponses.length;
                    if (failureCount > 0) {
                        const item   = failureCount > 1 ? 'webhook endpoints' : 'webhook endpoint';
                        let messages = failedResponses.map(
                            ({ reason: { responseJSON: { error: { message: messageString } } } }) => messageString
                        ).filter((message) => message !== undefined);

                        let errorMessage = `Failed to remove ${ failureCount } ${ item }.`;
                        if (messages.length > 0) {
                            errorMessage = `${ errorMessage }\n\n${ messages.join("\n\n") }`;
                        }
                        window.addFlashAlert('error', errorMessage)
                    } else {
                        window.addFlashAlert('notice', 'Selected webhook endpoints were deleted.')
                    }

                    return responses;
                })

        }
    }
};

class WebhookEndpointsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    sendTestEvent(e) {
        e.preventDefault();
        ajaxClient.sendTestEvent();
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroy'
            }
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "URL", key: "url" },
                { displayName: "Authentication", key: "authentication" },
                { displayName: "Status", key: "enabled" },
            ],
            renderRow: (row) => {
                const rowTitle = truncate(row.url, 50)
                const updateAllowed = this.props.policy.webhooksUpdate

                return Object.assign({}, row, {
                    url:   updateAllowed ? <a href={ AdminRoutes.adminWebhookEndpointPath(row.id, {format: null}) }>{ rowTitle }</a> : rowTitle,
                    enabled: row.enabled ? 'enabled' : 'disabled'
                });
            }
        };
    }


    renderSendTestEventButton() {
        if (this.props.data.some(({ enabled }) => enabled)) {
            return <a className='pull-right btn btn-default' onClick={ this.sendTestEvent } href="#test-event">
                Send Test Event
            </a>
        }
    }

    renderAddButton() {
        let addCertificate = null;
        if (this.props.policy.create) {
            addCertificate =
                <a href={ AdminRoutes.newAdminWebhookEndpointPath({format: null}) } className="pull-right btn btn-default">
                    Add Endpoint
                </a>;
        }
        return addCertificate;
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                You have not created any webhook endpoints yet.
            </div>
        );

        const message = <p>Receive notifications via HTTP callbacks when events occur in your account.</p>;
        const actions = (
            <React.Fragment>
                { this.renderActionButton() }
                { this.renderAddButton() }
                { this.renderSendTestEventButton() }
            </React.Fragment>
        );
        return (
            <div id="certificates-table">
                { this.props.renderTopSection(message, actions, { leftSize: 6, rightSize: 6 }) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(WebhookEndpointsPagedTable, getAjaxClient, undefined, null);