const swapFields = (fromField, toField, formAttributeName) => {
    fromField.style.display    = 'none'
    fromField.style.visibility = 'hidden'
    fromField.disabled         = true
    toField.style.display      = 'inherit'
    toField.style.visibility   = 'inherit'
    toField.disabled           = false
    toField.name               = formAttributeName
    toField.value              = fromField.value
}

const passwordRevealInput = () => {
    document.querySelectorAll('.password-reveal-wrapper').forEach((wrapper) => {
        const valuePasswordField = wrapper.querySelector('input[type="password"]')
        const formAttributeName  = valuePasswordField.name
        const valueVisibleField  = wrapper.querySelector('input[type="text"], textarea')
        const valueToggleLink    = wrapper.querySelector('a.password-reveal-link')

        valueToggleLink.addEventListener('click', (e) => {
            e.preventDefault()
            if ( valueVisibleField.style.display === 'none' ) {
                swapFields(valuePasswordField, valueVisibleField, formAttributeName)
                valueToggleLink.text = 'hide'
            } else {
                swapFields(valueVisibleField, valuePasswordField, formAttributeName)
                valueToggleLink.text = 'reveal'
            }
        })
    })
}

export default passwordRevealInput