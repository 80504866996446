export const devices = {
    read(options) {
        return $.getJSON(AdminRoutes.adminDevicesPath(options));
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.destroyMultipleAdminDevicesPath(),
            data:        JSON.stringify({ ids })
        });
    },

    restart(ids, notifyUser = false, rebuildKernelCache = false) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.restartAdminDevicesPath(),
            data:        JSON.stringify({ ids, notifyUser, rebuildKernelCache })
        });
    },
    shutdown(ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.shutdownAdminDevicesPath(),
            data:        JSON.stringify({ ids })
        });
    },
    // pushMessagesNewMultipleAdminDevicesPath
    sendMessage(ids, message) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.pushMessagesMultipleAdminDevicesPath(),
            data:        JSON.stringify({ admin_push_message: { ids, message } })
        });
    },

    assignToGroup(ids, groupId) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.multipleAssignGroupAdminDevicesPath(),
            data:        JSON.stringify({ ids, group_id: groupId })
        });
    },

    pushOSUpdate(ids, options) {
        options = options || {}
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.osPushUpdateAdminDevicesPath(),
            data:        JSON.stringify({ ...options, ids  })
        });
    },

    readProfileAssignments(id) {
        return $.getJSON(AdminRoutes.adminDeviceProfileAssignmentsPath(id));
    },

    assignProfile(id, profileId) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.adminDeviceProfileAssignmentsPath(id),
            data:        JSON.stringify({ profileId })
        });
    },

    unassignProfile(id, profileId) {
        return $.ajax({
            method:      'DELETE',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.adminDeviceProfileAssignmentPath(id, profileId),
        });
    },

    enableRemoteDesktop(id) {
        return $.post({
            method: 'POST',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceRemoteDesktopPath(id)
        })
    },

    disableRemoteDesktop(id) {
        return $.post({
            method: 'DELETE',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceRemoteDesktopPath(id)
        })
    },

    updateTimeZones(deviceIds, timeZone, options = {}) {
        return $.post({
            method: 'PUT',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.timeZonesAdminDevicesPath(),
            data: { ...options, device_ids: deviceIds, time_zone: timeZone }
        })
    }
};
