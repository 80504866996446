import React from 'react';
import { ReactSortable } from "react-sortablejs";
import AppIcon from "./app_icon";
import { bindMethods } from "../../shared/functions";

import { FORCE_FALLBACK } from "./index";
const MAX_ICONS_PER_PAGE = 9;
export default class OpenFolder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPageNumber: 0
        };

        bindMethods(this);
    }

    updateName(e) {
        const name = e.target.value;
        this.props.onFolderNameChange(this.props.folder, name);
    }

    closeFolder(e) {
        e.preventDefault();
        this.props.onCloseFolder(this.props.folder)
    }

    setCurrentPage(currentPage) {
        this.props.setFolderPages(
            this.props.folder,
            { pages: Object.assign([], this.props.folder.pages, { [this.state.currentPageNumber]: currentPage }) }
        );
    }

    currentPage() {
        return this.props.folder.pages[this.state.currentPageNumber] || [];
    }

    currentPageFilled() {
        return this.currentPage().length >= MAX_ICONS_PER_PAGE;
    }

    previousPage(e) {
        e.preventDefault();
        let { currentPageNumber } = this.state;
        currentPageNumber         = currentPageNumber <= 0 ? currentPageNumber : currentPageNumber - 1;
        this.setState({ currentPageNumber })
    }

    nextPage(e) {
        e.preventDefault();
        const currentPageNumber = this.state.currentPageNumber + 1;
        if (currentPageNumber >= this.props.folder.pages.length) {
            this.props.setFolderPages(this.props.folder, { pages: [...this.props.folder.pages, []] });
        }
        this.setState({ currentPageNumber });
    }

    render() {
        const folder      = this.props.folder;
        const currentPage = this.currentPage();
        const put         = !this.currentPageFilled();
        return (
            <div className="folder">
                <input
                    disabled={ this.props.disabled }
                    placeholder="Folder name"
                    value={ folder.name }
                    onChange={ this.updateName }/>
                <ReactSortable
                    disabled={ this.props.disabled }
                    forceFallback={ FORCE_FALLBACK }
                    key={ `folder-enabled-${ put }` /* fixes bug where group object props not updated */ }
                    group={ { name: 'folder', put } }
                    className="contents"
                    list={ currentPage }
                    onStart={ this.props.onStart }
                    onEnd={ this.props.onEnd }
                    setList={ this.setCurrentPage }>
                    { AppIcon.renderIcons(currentPage) }
                </ReactSortable>
                <div className="folder-control">
                        <span
                            onClick={ this.previousPage }
                            className="btn btn-default previous-screen btn-sm pull-left">
                        <span className="glyphicon glyphicon-chevron-left"/>
                        </span>

                    <div className="current-screen pull-left">
                        page { this.state.currentPageNumber + 1 }
                    </div>

                    <span
                        onClick={ this.nextPage }
                        className="btn btn-default next-screen btn-sm pull-left">
                        <span className="glyphicon glyphicon-chevron-right"/>
                        </span>

                    <a href="#close" className="btn btn-default pull-right" onClick={ this.closeFolder }>Close</a>
                </div>
            </div>
        )
    }

}
