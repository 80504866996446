import React from 'react';
import PropTypes from 'prop-types';
import { bindMethods } from "../../shared/functions";

class Pagination extends React.Component {

    constructor() {
        super();
        bindMethods(this);
    }

    previousPage(e) {
        e.preventDefault();
        e.currentTarget.blur();
        if (this.hasPreviousPage()) {
            const page = this.props.pagination.prevPage;
            const per  = this.props.pagination.per;
            this.props.onPageChange(page, per);
        }
    }

    nextPage(e) {
        e.preventDefault();
        e.currentTarget.blur();
        if (this.hasNextPage()) {
            const page = this.props.pagination.nextPage;
            const per  = this.props.pagination.per;
            this.props.onPageChange(page, per);
        }
    }

    setPer(per, e) {
        if (e) {
            e.preventDefault();
        }
        const page = 1;
        this.props.onPageChange(page, per);
    }

    hasNextPage() {
        return !(this.props.pagination.nextPage === null);
    }

    hasPreviousPage() {
        return !(this.props.pagination.prevPage === null);
    }

    renderPer() {
        return this.props.perOptions.map((perOption) => {
            if (perOption === this.props.pagination.per) {
                return <span key={ perOption } className="per-option">{ perOption }&nbsp;</span>;
            } else {
                return (
                    <span key={ perOption } className="per-option">
                        <a href="#" onClick={ (e) => this.setPer(perOption, e) }>{ perOption }&nbsp;</a>
                    </span>
                )
            }
        })
    }

    renderInfo() {
        if (this.props.pagination.totalCount) {
            return (
                <span className="info">
                    <strong>{ this.props.pagination.minItemIndex }</strong>
                    &nbsp;-&nbsp;
                    <strong>{ this.props.pagination.maxItemIndex }</strong>
                    &nbsp;of&nbsp;
                    <strong>{ this.props.pagination.totalCount }</strong>
                </span>
            )
        }
    }

    render() {
        return (
            <div className="paged-table">
                <div className="footer-controls">
                    <div className="per">
                        <span className="pretext">show</span>
                        { this.renderPer() }
                    </div>

                    <div className="pagination">
                        <a className="btn btn-sm btn-default"
                           href="#"
                           disabled={ !this.hasPreviousPage() }
                           onClick={ this.previousPage }>
                            <span className="glyphicon glyphicon-menu-left"/>
                        </a>
                        &nbsp;
                        <a className="btn btn-sm btn-default "
                           href="#"
                           disabled={ !this.hasNextPage() }
                           onClick={ this.nextPage }>
                            <span className="glyphicon glyphicon-menu-right"/>
                        </a>
                        &nbsp;
                       { this.renderInfo() }
                    </div>
                </div>
            </div>
        )
    }
}

Pagination.propTypes = {
    perOptions:   PropTypes.array,
    pagination:   PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
    perOptions: [20, 40, 100]
};

export default Pagination;