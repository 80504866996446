export const users = {
    read(pageSize) {
      return  $.getJSON(AdminRoutes.adminUsersPath({ per: pageSize }));
    },

    current() {
        return $.getJSON(AdminRoutes.adminUserPath());
    },

    create(user, password, reCaptcha, consent = '1') {
        const data = {
            recaptchaResponse: reCaptcha,
            user: {
                consent: consent,
                email: user.email,
                password: password,
                accountsAttributes: user.accounts.map((account) => ({
                    expectedDeviceCount: account.expectedDeviceCount,
                    name: account.name,
                    planId: account.planId,
                })),
            }
        }
        return $.post({
            contextType: "application/json",
            data: data,
            dataType: 'json',
            method: 'POST',
            url: AdminRoutes.adminUserPath()
        })
    },

    enableDeviceColumn(column) {
        return $.ajax({
            method:      'PATCH',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.deviceColumnAdminUserPreferencesPath(),
            data:        JSON.stringify(column)
        });
    },

    disableDeviceColumn(column) {
        return $.ajax({
            method:      'DELETE',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.deviceColumnAdminUserPreferencesPath(),
            data:        JSON.stringify(column)
        });
    },

    enableSidebarSection(section) {
        return $.ajax({
            method:      'PATCH',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.sidebarSectionAdminUserPreferencesPath(),
            data:        JSON.stringify(section)
        });
    },

    disableSidebarSection(section) {
        return $.ajax({
            method:      'DELETE',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.sidebarSectionAdminUserPreferencesPath(),
            data:        JSON.stringify(section)
        });
    }
}