import React from 'react';
import PropTypes from 'prop-types';

export default class Select extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    renderOption({ text, value }) {
        return <option key={ `${ text }-${ value }` } value={ value }>
            { text }
        </option>
    }

    onChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <div className="select">
                <select value={ this.props.selectedValue }
                        disabled={ this.props.disabled }
                        onChange={ this.onChange }
                        className="form-control select">
                    { this.props.options.map(this.renderOption) }
                </select>
            </div>
        )
    }
}

Select.propTypes = {
    options:       PropTypes.arrayOf(PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.string
    })),
    selectedValue: PropTypes.string.isRequired,
    onChange:      PropTypes.func,
    disabled:      PropTypes.bool
};

Select.defaultProps = {
    disabled: false
};