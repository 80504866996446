export const consultationCalls = {
  create(data) {
    const urlParams = new URLSearchParams(window.location.search)

    return $.post({
      contextType: "application/json",
      data: {
        admin_consultation_call: data,
        skip_eligible_check: urlParams.get('skip_eligible_check') // TODO: MDM-1466 remove after testing
      },
      dataType: 'json',
      method: 'POST',
      url: AdminRoutes.adminConsultationCallPath()
  })
  },
}
