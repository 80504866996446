import passwordRevealInput from '../../shared/password_reveal_input'

export const ldap_accounts = {
    new() {
        passwordRevealInput()
    },

    show() {
        passwordRevealInput()
    },

    update() {
        passwordRevealInput()
    },

    create() {
        passwordRevealInput()
    }
}