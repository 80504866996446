import React from 'react';
import { media as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { truncate, callWithIds, isEmpty } from '../../shared/functions';
import { processAvailableLicenses } from './apps_paged_table';

const getAjaxClient = () => Object.assign({}, ajaxClient,
    {
        destroyMultiple: (collection) => callWithIds(collection, ajaxClient.destroyMultiple)
    }
);

class MediaPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.renderActionButton = this.renderActionButton.bind(this);
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroy'
            }
        ];

        return <ActionDropDown text="Actions"
                               items={ items }
                               policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Media Name", key: "name" },
                { displayName: "", key: "version" },
            ],
            renderRow: (row) => {
                const availableLicenses = isEmpty(row.availableLicenses) ? null : processAvailableLicenses(row);
                const name              = (
                    <a href={ AdminRoutes.adminMediaPath(row.id, {format: null}) }>
                        <img src={ row.iconUrl }/>{ truncate(row.name, 50) }
                    </a>
                );
                return Object.assign({}, row, { name, version: availableLicenses });
            }
        };
    }

    renderAddMediaButton() {
        if (this.props.policy.new) {
            return <a className="btn btn-default pull-right" href={ AdminRoutes.newAdminMediaPath({format: null}) }>Add Media</a>;
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                You have not added any media to your catalog.
            </div>
        );

        const message = <p>Add the media to your catalog that you wish to distribute to your devices.
            These media are made available under the "Assignment" section for distribution.
            Media in associated VPP accounts will be automatically added.</p>;
        let actions   = <React.Fragment>
            { this.renderActionButton() }
            { this.renderAddMediaButton() }
        </React.Fragment>;

        return (
            <div id="media-table">
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }

}

export default pagedTableLayout(MediaPagedTable, getAjaxClient, undefined, null);