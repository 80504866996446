import React from 'react';
import { users as userClient } from '../../ajax_clients'
import {
    parseQuery,
    queryObjectToParams,
    setQueryHistory,
} from "../../shared/query";
import PagedTable from "./index";
import Pagination from "./pagination";
import { bindMethods } from "../../shared/functions";

const defaultQuery  = { search: null, order_d: 'asc', page: 1, per: 20 };
const defaultPolicy = {
    show:    true,
    create:  true,
    edit:    true,
    update:  true,
    destroy: true
};

export const PagedTableContext = React.createContext();
export default function(WrappedComponent, getAjaxClient, initialQuery, queryParamsPath, propsProcessor, beforePageChange) {

    return class extends React.Component {
        static migrateParsedQuery() {
            const parsedQuery    = parseQuery();
            const oldQueryFormat = /q\[([^\]]+)\]/
            Object.keys(parsedQuery).forEach(key => {
                if (oldQueryFormat.test(key)) {
                    const newKey = key.replace(oldQueryFormat, '$1')
                    const value  = parsedQuery[key]
                    delete parsedQuery[key]
                    parsedQuery[newKey] = value
                }
            })
            return parsedQuery;
        }

        static setQueryHistory(queryObject, initialState = false) {
            const params = queryObjectToParams(queryObject);
            setQueryHistory(params, initialState);
        };

        constructor(props) {
            super(props);
            bindMethods(this);
            let query         = initialQuery || defaultQuery;
            const parsedQuery = this.constructor.migrateParsedQuery();

            if (typeof propsProcessor == 'function') {
                props = propsProcessor(props)
            }

            let data        = props.data || [];
            this.state      = {
                currentUser:    {},
                data,
                pagination:     props.pagination || (props.meta ? props.meta.pagination : null),
                query:          { ...query, ...parsedQuery },
                searching:      false,
                loaded:         false,
                editPagedTable: false,
                policy:         props.policy || (props.meta ? props.meta.policy : null) || defaultPolicy
            };
            this.ajaxClient = getAjaxClient(props);
        }

        togglePagedTableEditor(e) {
            e.preventDefault()
            this.setState({ editPagedTable: !this.state.editPagedTable })
        }

        componentDidMount() {
            this.readUser().then(currentUser => {
                const storedQuery = currentUser?.preferences?.queryParams?.[queryParamsPath || window.location.pathname] || {}
                this.setQuery({ ...parseQuery(), ...storedQuery }, true);
                document.addEventListener('turbolinks:load', this.readDataFromPopState);
            })
        }

        componentWillUnmount() {
            document.removeEventListener('turbolinks:load', this.readDataFromPopState);
        }

        readDataFromPopState(event) {
            this.setQuery(event.state);
        }

        orderBy(order) {
            let orderDirection = 'desc';
            if (order === this.state.query.order && this.state.query.order_d === 'desc') {
                orderDirection = 'asc';
            }
            this.setQuery({ order, order_d: orderDirection });
        }

        readData(additionalParams = {}) {
            // if we loaded the data from the initial react component, lets not try to load the data again
            if (this.props.data && !this.state.loaded) {
                this.setState({ loaded: true, searching: false });
            } else {
                const params = { ...this.state.query, ...additionalParams, save_query: true }
                this.ajaxClient.read(params).then(({ data, pagination, policy, meta }) => {
                    data.forEach(item => item.checked = false);
                    if (meta && meta.policy) {
                        policy = meta.policy
                    }
                    if (meta && meta.pagination) {
                        pagination = meta.pagination
                    }
                    this.setState({
                        searching: false,
                        loaded:    true,
                        data,
                        pagination,
                        policy:    policy || this.state.policy
                    })
                })
            }
        }

        setQuery(newQueryParams = {}, initialState) {
            const matchingQueryParams = {};
            Object.keys(this.state.query).forEach((key) => {
                if (typeof newQueryParams[key] !== 'undefined') {
                    matchingQueryParams[key] = newQueryParams[key];
                }
            });
            const query = Object.assign({}, this.state.query, matchingQueryParams);
            this.setState({ query, searching: true }, () => {
                this.readData();
                this.constructor.setQueryHistory(this.state.query, initialState);
            });
        }

        onCheck(index, targetItem, checked) {
            const data        = this.state.data;
            const updatedItem = { ...data[index], checked };
            const newData     = Object.assign([], data, { [index]: updatedItem });

            this.setState({ data: newData });
        }

        onCheckAll(checked) {
            this.setState({
                data: this.state.data.map(item => ({
                    ...item,
                    checked: checked && !item.checkDisabled
                }))
            });
        }

        hasCheckedItems() {
            return this.state.data.some(item => item.checked);
        }

        checkedItems() {
            return this.state.data.filter(item => item.checked);
        }

        onPageChange(page, per) {
            let newQuery = {}
            if (typeof beforePageChange === 'function') {
                newQuery = beforePageChange(page, per, this.state.pagination)
            } else {
                newQuery = { page, per }
            }
            this.setQuery(newQuery);
        }

        removeItems(e, confirmMessage = "This will delete all selected items. Are you sure?") {
            e.preventDefault();
            if (confirm(confirmMessage)) {
                return this.callWithItems('destroyMultiple');
            }
        }

        callWithItems(ajaxMethodName) {
            return this.ajaxClient[ajaxMethodName](this.checkedItems())
                .then(() => this.readData())
                .catch(errorData => {
                    if (errorData.responseJSON) {
                        const { errors } = errorData.responseJSON;
                        this.setState({ errors }, this.readData)
                    } else if (errorData instanceof Error) {
                        throw errorData
                    }
                })
        }

        updateItem(index, newData) {
            const data  = [...this.state.data];
            data[index] = Object.assign({}, data[index], newData);
            this.updateData(data);
        }

        updateData(data) {
            this.setState({ data })
        }

        renderTopSection(message, actions, options) {
            const { leftSize, rightSize } = { leftSize: 9, rightSize: 3, ...options }
            let topPart;
            if (this.state.policy.edit) {
                topPart = (
                    <React.Fragment>
                        <div className={ `col-xs-${ leftSize }` }>
                            { message }
                        </div>
                        <div className={ `col-xs-${ rightSize }` }>
                            { actions }
                        </div>
                    </React.Fragment>
                )
            } else {
                topPart = (
                    <div className="col-xs-12">
                        { message }
                    </div>
                )
            }
            return (
                <div className="row">
                    { topPart }
                </div>
            )
        }

        renderPagedTable(props) {
            let pagination = null;
            if (this.state.pagination) {
                pagination = <Pagination
                    pagination={ this.state.pagination }
                    onPageChange={ this.onPageChange }
                    perOptions={ props.perOptions }
                />;
            }
            return (
                <div className="paged-table">
                    { this.state.editPagedTable ? props.pagedTableEditor : null }
                    <PagedTable
                        query={ this.state.query }
                        orderBy={ this.orderBy }
                        data={ this.state.data }
                        errors={ this.state.errors }
                        onCheck={ this.onCheck }
                        onCheckAll={ this.onCheckAll }
                        { ...props }
                    />
                    { pagination }
                </div>
            );
        }

        readUser() {
            return userClient.current().then(({ data: currentUser }) => {
                this.setState({ currentUser })
                return currentUser
            })
        }

        enableDeviceColumn(columnName) {
            return this.handleUpdateUserPreference(userClient.enableDeviceColumn(columnName))
        }

        disableDeviceColumn(columnName) {
            return this.handleUpdateUserPreference(userClient.disableDeviceColumn(columnName))
        }

        handleUpdateUserPreference(userPreferencePromise) {
            return userPreferencePromise.then(({ data: currentUser }) => {
                this.setState({ currentUser })
            }).catch(response => {
                const message = response?.responseJSON?.error?.message || "There was an error processing your request."
                window.addFlashAlert('error', message)
            })
        }

        getContext() {
            return {
                currentUser: this.state.currentUser,
                readUser: this.readUser,
                enableDeviceColumn: this.enableDeviceColumn,
                disableDeviceColumn: this.disableDeviceColumn
            }
        }

        render() {
            return (
                <div className="paged-table-layout">
                    <PagedTableContext.Provider value={  this.getContext() }>
                        <WrappedComponent
                            dataNotFound={ this.state.loaded && !this.state.searching && this.state.data.length === 0 }
                            query={ this.state.query }
                            ajaxClient={ this.ajaxClient }
                            setQuery={ this.setQuery }
                            readData={ this.readData }
                            removeItems={ this.removeItems }
                            hasCheckedItems={ this.hasCheckedItems }
                            checkedItems={ this.checkedItems }
                            callWithItems={ this.callWithItems }
                            setLayoutState={ this.setState }
                            updateData={ this.updateData }
                            updateItem={ this.updateItem }
                            data={ this.state.data }
                            searching={ this.state.searching }
                            loaded={ this.state.loaded }
                            renderPagedTable={ this.renderPagedTable }
                            renderTopSection={ this.renderTopSection }
                            policy={ this.state.policy }
                            { ...this.props }
                        />
                    </PagedTableContext.Provider>
                </div>
            )
        }
    }

}
;
