import React from 'react'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'

const SimpleMDMTheme = (typography = {}) => createTheme({
  simplemdm: {
    colors: {
      grey: "#F8FAFD",
    },
  },
  palette: {
    primary: { main: "#0066F1" },
  },
  typography: {
    h1: {
      fontSize: "1.25rem",
      lineHeight: 1.6,
      fontWeight: 500,
      letterSpacing: "0.0075em",
    },
    h2: { 
      fontSize: "1.15rem",
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: "0.0075em",
    },
    h3: {
      fontSize: "1rem",
      lineHeight: 1.4,
      fontWeight: 300,
      letterSpacing: "0.0075em",
    },
    h4: {
      fontSize: "0.875rem",
      lineHeight: 1.3,
      fontWeight: 300,
      letterSpacing: "0.0075em",
    },
    h5: {
      fontSize: "0.875rem",
      lineHeight: 1.2,
      fontWeight: 200,
      letterSpacing: "0.0075em",
    },
    h6: {
      fontSize: "0.875rem",
      lineHeight: 1.0,
      fontWeight: 100,
      letterSpacing: "0",
    },
    button: {
      textTransform: "none",
    },
    body2: {
      color: "#666666",
    },
    ...typography,
  },
})

export const ThemeProvider = ({children, typography, ...otherProps}) => (
  <ScopedCssBaseline sx={ { height: '100%' }}>
    <MuiThemeProvider theme={ SimpleMDMTheme(typography) } {...otherProps}>
      {children}
    </MuiThemeProvider>
  </ScopedCssBaseline>
)

export default SimpleMDMTheme()
