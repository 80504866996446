import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'

export default function MenuItem(props) {
  const currentLocation = window.location.pathname

  const { className, icon, preferenceName, title, items, expanded, onExpandSection, onCollapseSection } = props;

  const handleSectionClick = () => {
    setOpen(!open);
    open ? onCollapseSection(preferenceName) : onExpandSection(preferenceName)
  }

  const visitUrl = (url) => {
    Turbolinks.visit(url)
  }

  const subItems = () => {
    return items.map((item) => {
      const active = isActiveLink(item)
      return { ...item, active  }
    })
  }

  const isActiveLink = ({ path, activePaths }) => {
    const paths = [ path, ...(activePaths ? activePaths : []) ]
    return paths.find((sectionPath) => new RegExp(`^${sectionPath}(/.*)?$`).test(currentLocation) ) !== undefined
  }

  const [open, setOpen] = React.useState(() => {
    return expanded || subItems().some(({ active }) => active === true)
  } );

  return (
    <React.Fragment>
      <ListItemButton onClick={ handleSectionClick } className={className}>
        <ListItemIcon>{ icon }</ListItemIcon>
        <ListItemText disableTypography primary={ title } sx={ { fontWeight: 500 } } />
      </ListItemButton>
      <Collapse in={ open } timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            subItems().map(({ title, path, active, className: subClassName }) => {
              return (<Link key={ title }
                    underline="none"
                    color="inherit"
                    href={ path }
                    component={ ListItemButton }
                    sx={ { pl: 4, pt: '4px', pb: '4px', height: '36px' } }
                    selected={ active }
                    disableRipple={ true }
                    className={subClassName}
                    onClick={ (e) => {
                      e.preventDefault()
                      visitUrl(path)
                    } }>
                <ListItemText inset disableTypography primary={ title } sx={ { fontSize: '14px' } } />
              </Link>)
            })
          }
        </List>
      </Collapse>
    </React.Fragment>
  )
}