import React from 'react';
import { SearchBar, SearchBarResult } from '../../search'
import * as ajaxClients from '../../../ajax_clients';
import { bindMethods, dig } from "../../../shared/functions";
import { renderCompatibilityLabel } from '../../catalog/apps_paged_table'
import AssignmentGroups from "../assignment_groups";

const handleJSONError = response => {
    const errorMessage = dig(['responseJSON', 'error', 'message'], response)
    if (errorMessage) {
        addFlashAlert('error', errorMessage)
    }
};

export default class AssignmentGroupAvailableAssets extends React.Component {

    static assetIcon(asset) {
        return <span className="img-holder"><img alt="icon" className="asset" src={ asset.iconUrl }/></span>
    }

    static assetType(asset) {
        switch(asset.type) {
            case 'app':
                return 'app'
            case 'uploaded_media':
                return 'media'
            case 'vpp_media':
                return 'media'
            default:
                return 'profile'
        }
    }

    static displayName(asset) {
        switch (AssignmentGroupAvailableAssets.assetType(asset.type)) {
            case 'app':
                return 'app'
            case 'media':
                return 'media'
            default:
                return asset.displayName
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            search: null
        };
        bindMethods(this);
    }


    filterAssigned(catalogEntries, assignedIds) {
        return catalogEntries.filter(asset => !assignedIds.includes(asset.id));
    }

    availableCatalog() {
        const { catalog, assignmentGroup } = this.props;
        const availableApps                = this.filterAssigned(
            catalog.appGroups,
            assignmentGroup.appGroups.map(appGroup => appGroup.id)
        ).map(appGroup => appGroup.latestVersion).filter(app => {
            const isProcessed                = app.processingStatus === 'processed';
            let supportedInstallationChannel = false;
            if (assignmentGroup.deploymentAttributes.includes('standard') && app.installationChannels.includes('standard')) {
                supportedInstallationChannel = true
            } else if (assignmentGroup.deploymentAttributes.includes('munki') && app.installationChannels.includes('munki')) {
                supportedInstallationChannel = true;
            }
            const notInternal = app.name !== 'SimpleMDMAgent'
            return isProcessed && supportedInstallationChannel && notInternal
        });

        let availableMedia;
        if (assignmentGroup.deploymentAttributes.includes('standard')) {
            availableMedia = this.filterAssigned(catalog.media, assignmentGroup.mediaIds);
        } else {
            availableMedia = []
        }

        let availableProfiles;
        if (assignmentGroup.deploymentAttributes.includes('standard')) {
            availableProfiles = this.filterAssigned(catalog.profiles, assignmentGroup.profileIds);
        } else {
            availableProfiles = []
        }
        return availableApps.concat(availableMedia).concat(availableProfiles);
    }

    searchResults() {
        if (this.state.search === null) {
            return this.availableCatalog().slice(0, 5);
        }
        return this.availableCatalog()
            .filter(asset => this.state.search.test(asset.name))
    }

    onSearchChange(search) {
        this.setState({ search })
    }

    assignApp(app) {
        ajaxClients.assignmentGroups.assignApp(this.props.assignmentGroup.id, app.id)
            .then(this.props.updateAssignmentGroup)
            .catch(handleJSONError);
    }

    assignMedia(media) {
        ajaxClients.assignmentGroups.assignMedia(this.props.assignmentGroup.id, media.id)
            .then(this.props.updateAssignmentGroup)
            .catch(handleJSONError);
    }

    assignProfile(profile) {
        ajaxClients.assignmentGroups.assignProfile(this.props.assignmentGroup.id, profile.id)
            .then(this.props.updateAssignmentGroup)
            .catch(handleJSONError);
    }

    assignAsset(asset) {
        switch(asset.type) {
            case 'app':
                return this.assignApp(asset);
            case 'uploaded_media':
                return this.assignMedia(asset);
            case 'vpp_media':
                return this.assignMedia(asset);
            default:
                return this.assignProfile(asset);
        }
    }

    render() {
        return (
            <div className="available-assets">
                <SearchBar
                    disabled={ !this.props.assignmentGroup.editable }
                    placeholderText='Search for an app, media or profile to add'
                    inputClass="form-control string"
                    onSearchChange={ this.onSearchChange }
                >
                    {
                        this.searchResults().map((asset) => {
                            let displayName
                            displayName = AssignmentGroupAvailableAssets.displayName(asset);

                            let version = asset.version;
                            const startsWithNumber = /^\d/;
                            if (typeof version === 'string' && startsWithNumber.test(version)) {
                                version = `v${ version }`
                            }

                            if (version === 'latest') {
                                version = ''
                            }

                            version = <span className="version">{ version }</span>

                            let assetIcon = AssignmentGroupAvailableAssets.assetIcon(asset)

                            return <SearchBarResult
                                key={ `${ asset.id }-${ asset.type }` }
                                onChooseItem={ () => this.assignAsset(asset) }
                            >
                                { assetIcon }
                                { asset.name }
                                <span className="type">{ asset.platformSupport } { displayName }</span>
                                { renderCompatibilityLabel(asset, 'pull-right') }
                                { version }
                            </SearchBarResult>
                            }
                        )
                    }
                </SearchBar>
            </div>
        )
    }

}