import passwordRevealInput from '../../shared/password_reveal_input'

export const global_http_proxies = {
	new() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	show() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	create() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	update() {
		this.wireAndUpdateForm();
		passwordRevealInput()
	},
	wireAndUpdateForm() {
		$('#global_http_proxy_proxy_type').change(this.updateForm);
		this.updateForm();
	},
	updateForm() {
		const select = function(name) {
			return $('div.form-group.global_http_proxy_' + name);
		};

		// Proxy Type

		const proxyType = $('#global_http_proxy_proxy_type').val();

		switch(proxyType) {
			case 'Manual':
				select('server').show();
				select('server_port').show();
				select('username').show();
				select('password').show();
				select('pacurl').hide();
				select('pac_fallback_allowed').hide();
				select('captive_login_allowed').hide();
				break;
			case 'Auto':
				select('server').hide();
				select('server_port').hide();
				select('username').hide();
				select('password').hide();
				select('pacurl').show();
				select('pac_fallback_allowed').show();
				select('captive_login_allowed').show();
				break;
		}
	}
}