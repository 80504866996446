import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class UserPrinters extends React.Component {

    constructor(props) {
        super(props);
        this.state        = {
            data: props.data,
        };
        this.headers      = [
            { displayName: 'Device URI', key: 'deviceUri' },
            { displayName: 'Display Name', key: 'displayName' },
            { displayName: 'Location', key: 'location' },
            { displayName: 'Model', key: 'model' },
            { displayName: 'PPD Url', key: 'ppdUrl' },
            { displayName: 'Locked', key: 'locked', boolean: true },
            { displayName: 'Default Printer', key: 'defaultPrinter',boolean: true },
        ];
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem    = this.onAddItem.bind(this);
    }

    onChangeItem(updatedItem, updatedIndex, key) {
        const setDefaultPrinter = key === "defaultPrinter" && updatedItem[key] === true;
        this.setState({
            data: this.state.data.map((row, index) => {
                if (index === updatedIndex) {
                    return updatedItem
                } else if (setDefaultPrinter) {
                    return { ...row, defaultPrinter: false }
                } else {
                    return row
                }
            })
        })
    }

    onRemoveItem(removedItem, removedIndex) {
        this.setState({
            data: this.state.data.filter((item, index) => index !== removedIndex)
        });
    }

    onAddItem() {
        const newItem = {
            deviceUri:  "",
            displayName:    "",
            location:   "",
            model:  "",
            ppdUrl: "",
            locked: false,
            defaultPrinter: this.state.data.length == 0
        };
        this.setState({
            data: [...this.state.data, newItem]
        })
    }


    render() {
        return <DynamicList
            fieldName={ 'printing[printers_attributes]' }
            headers={ this.headers }
            rows={ this.state.data }
            policy={ this.props.policy }
            onChangeItem={ this.onChangeItem }
            onRemoveItem={ this.onRemoveItem }
            onAddItem={ this.onAddItem }
        />
    }
}
