export const managed_software_updates = {
    show() {
        this.fn.handleSpecificVersionChange()
        document.querySelectorAll('input[name="managed_software_update[target_os_version_type]"]').forEach((radio) => {
            radio.addEventListener('change', () => this.fn.handleSpecificVersionChange())
        })

        this.fn.handleEnforceByChange()
        document.querySelectorAll('input[name="managed_software_update[enforce_by]"]').forEach((radio) => {
            radio.addEventListener('change', () => this.fn.handleEnforceByChange())
        })

        this.fn.handleAdditionalEnforcementChange()
        document.querySelectorAll('input[name="managed_software_update[additional_enforcement_days_enabled]"]').forEach((radio) => {
            radio.addEventListener('change', () => this.fn.handleAdditionalEnforcementChange())
        })
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },
    fn: {
        handleSpecificVersionChange() {
            this.handleHiddenFieldChanges('version_type_toggle_fields', [
                'managed_software_update_target_os_version_type_latest_minus',
                'managed_software_update_target_os_version_type_specific',
            ])
        },
        handleEnforceByChange() {
            this.handleHiddenFieldChanges('enforce_by_toggle_fields', [
                'managed_software_update_enforce_by_relative',
                'managed_software_update_enforce_by_specific',
            ])
        },
        handleAdditionalEnforcementChange() {
            this.handleHiddenFieldChanges('additional_enforcement_toggle_field', [
                'managed_software_update_additional_enforcement_days_enabled'
            ])
        },
        handleHiddenFieldChanges(hiddenFieldsWrapperId, radioIds){
            this.hideAndDisableInputs(`#${hiddenFieldsWrapperId} .form-group`)

            radioIds.forEach((radioId) => {
                const element = document.getElementById(radioId)
                if (element.checked) {
                    const idToShow = isNaN(parseInt(element.value, 10)) ? element.value : `value_${element.value}`
                    this.showAndEnableInputs(`#${hiddenFieldsWrapperId} #${idToShow} .form-group`)
                }
            })
        },
        hideAndDisableInputs(selector){
            document.querySelectorAll(selector).forEach(element => {
                element.classList.add('hidden')
                element.querySelectorAll('.form-control').forEach(e => e.setAttribute('disabled', true))
            });
        },
        showAndEnableInputs(selector){
            document.querySelectorAll(selector).forEach(element => {
                element.classList.remove('hidden')
                element.querySelectorAll('.form-control').forEach(e => e.removeAttribute('disabled'))
            });
        }
    }
};