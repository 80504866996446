export const mediaInstalledMedia = {
    read(mediaId, idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminMediaInstalledMediaPath(mediaId, idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminMediaInstalledMediaIndexPath(mediaId, idOrOptions));
        }
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.destroyMultipleAdminInstalledMediaIndexPath(),
            data:     JSON.stringify({ ids })
        });

    }

};
