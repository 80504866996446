import React from 'react';
import { appVolumePurchaseCodes as ajaxClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { bindMethods } from '../../shared/functions';

const getAjaxClient = ({ appId }) => {
    return {
        read: (query) => ajaxClient.read(appId, query),
    }
};

class AppsVolumePurchaseCodesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    pagedTableProps() {
        const props = {
            headers:   [
                { displayName: 'Code', key: 'code' },
                { displayName: 'Used By', key: 'usedBy' },
                { displayName: '', key: 'appliedAt' },
            ],
            showCheck: false
        };

        return props
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No redemption codes found
            </div>
        );

        let message = <p>App licenses can be distributed to Apple IDs using redemption codes, where a code can be redeemed once by an Apple ID for a particular app license.</p>

        let actions =<div className="button-list-container">
            <a
                className="btn btn-default"
                href={ AdminRoutes.editMultipleAdminAppVolumePurchaseCodesPath(this.props.appId, {format: null}) }>
                Edit Codes
            </a>
        </div>

        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions, { leftSize: 10, rightSize: 2 }) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(AppsVolumePurchaseCodesPagedTable, getAjaxClient, undefined, null);