export default {
    initialize(id) {
        this.update(id);
        // update action button when selection changes
        $(document).on('click', "input[type='checkbox']", () => {
            setTimeout(() => {
                this.update(id)
            }, 100);
        });

        // wiring for 'all' checkbox
        $(document).on('click', '.all-checkbox', function() {
            $('tbody input[data-sab-id="' + id + '"][type=checkbox]:visible:not(:disabled)').prop('checked', this.checked);
        });

        // how to submit data upon button click
        $('ul[data-sab-id="' + id + '"] a.group-action, a.selection-actions-button:not([data-toggle])[data-sab-id="' + id + '"]').click(function(e) {
            let submitForm = true;

            if ($(this).is('[data-confirm]')) {
                const confirmMessage = $(this).attr('data-confirm');
                if (!confirm(confirmMessage)) {
                    submitForm = false;
                }
            }

            if (submitForm) {
                const ids = $('input[data-sab-id="' + id + '"]:checked:not(.all-checkbox)').map(function() {
                    return $(this).val();
                }).get().join();

                const url = $(this).attr('href');
                $.form(url, { ids: ids }).submit();
            }

            e.preventDefault();
            return false;
        });
    },

    update(id) {
        const selectionActionsButton = $('.selection-actions-button[data-sab-id="' + id + '"]');
        if ($('input[type="checkbox"][data-sab-id="' + id + '"]:not(.all-checkbox):checked').length > 0) {
            selectionActionsButton.removeProp('disabled').removeAttr('disabled');
        } else {
            selectionActionsButton.prop('disabled', true).attr('disabled', 'disabled');
        }
    }
};

