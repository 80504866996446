import React from 'react';
import { accountProviders as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { truncate } from '../../shared/functions';

const getAjaxClient = () => ({
    ...ajaxClient,
    destroyMultiple: (profiles) => {
        return ajaxClient.destroyMultiple(profiles).then((responses) => {
            const failedResponses = responses.filter(({ status }) => status === 'rejected');
            const failureCount    = failedResponses.length;
            if (failureCount > 0) {
                const provider = failureCount > 1 ? 'providers' : 'provider';
                window.addFlashAlert('error', `Failed to remove ${ failureCount } ${ provider }.`)
            } else {
                window.addFlashAlert('notice', 'Selected account providers have been removed')
            }

            return responses;
        })
    }
});

class AccountProvidersPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.links = [
            { text: "CalDAV", href: AdminRoutes.newAdminCaldavProviderPath({format: null}) },
            { text: "CardDAV", href: AdminRoutes.newAdminCarddavProviderPath({format: null}) },
            { text: "Email", href: AdminRoutes.newAdminEmailProviderPath({format: null}) },
            { text: "LDAP", href: AdminRoutes.newAdminLdapProviderPath({format: null}) },
            { text: "Subscribed Calendar", href: AdminRoutes.newAdminSubscribedCalendarProviderPath({format: null}) },
            { text: "VPN", href: AdminRoutes.newAdminVpnProviderPath({format: null}) },
        ];

        this.renderActionButton = this.renderActionButton.bind(this);
        this.renderAddButton    = this.renderAddButton.bind(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
            }
        ];
        return <ActionDropDown text="Actions" items={ items } disabled={ !this.props.hasCheckedItems() }/>
    }

    renderAddButton() {
        if (this.props.policy.new) {
            return <ActionDropDown text="Add Provider" items={ this.links }/>
        }
    }


    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Type", key: "accountProviderTypeDisplayName" },
            ],
            renderRow: (row) => {
                const name = <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>;
                return Object.assign({}, row, { name });
            },
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No account providers have been created yet.
            </div>
        );

        const message = <p>Account providers are objects that define services, like email servers.
            These objects are reusable when defining accounts, like email accounts.</p>;

        const actions = (
            <React.Fragment>
                { this.renderActionButton() }
                { this.renderAddButton() }
            </React.Fragment>
        );
        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(AccountProvidersPagedTable, getAjaxClient);