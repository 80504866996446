import passwordRevealInput from '../../shared/password_reveal_input'

export const enrollment_codes = {
    index() {
        // MDM.fn.selectionActionButton.initialize('enrollments');
    },
    show() {
        this.fn.wireForms()
        this.fn.wireGeneralSettingsForm()
        $('[data-toggle="tooltip"]').tooltip()
        MDM.fn.toggleElement($('#enrollment_code_account_driven'), $(".enrollment_code_managed_apple_id_domain"));
        MDM.fn.toggleElement($('#enrollment_code_account_driven'), $("#ade-enrollment-warning"));
    },
    new() {
        MDM.fn.toggleElement($('input[type=radio][name=enrollment_code\\[user_enrollment\\]]'), $("#user-enrollment-warning"));
        MDM.fn.toggleElement($('#enrollment_code_account_driven'), $(".enrollment_code_managed_apple_id_domain"));
        MDM.fn.toggleElement($('#enrollment_code_account_driven'), $("#ade-enrollment-warning"));
    },
    create() {
        this.new()
    },
    update() {
        this.show()
    },
    update_dep() {
        this.fn.wireForms()
    },
    fn: {
        wireForms() {
            passwordRevealInput()
            MDM.fn.toggleElement($('input[type=radio][name=enrollment_code\\[user_enrollment\\]]'), $("#user-enrollment-warning"));
            const form = document.querySelector('form.edit_dep_profile')
            if (form && !form.classList.contains('disabled')) {
                const skipSetupPaneCheckboxes = document.querySelectorAll('.selection-buttons input[type=checkbox]')

                document.getElementById('select-all-setup-panes').addEventListener('click', (e) => {
                    e.preventDefault()
                    skipSetupPaneCheckboxes.forEach((element) => element.checked = true)
                    e.target.blur()
                })

                document.getElementById('deselect-all-setup-panes').addEventListener('click', (e) => {
                    e.preventDefault()
                    skipSetupPaneCheckboxes.forEach((element) => element.checked = false)
                    e.target.blur()
                })

                // macosAccountWiring

                const depProfilePrimaryAccountAllowUserModification = document.getElementById('dep_profile_primary_account_allow_user_modification');

                // Leaving these as jQuery objects for now as updateCheckboxShadowElement & toggleElement expect them
                const depProfileMacosAccountCreate = $('#dep_profile_macos_account_create');
                const depProfileMacosAccountType = $('#dep_profile_macos_account_type');
                const depProfilePrimaryAccountPromptUser = $('#dep_profile_primary_account_prompt_user');
                const depProfilePrimaryAccountShortNameEnable = $('#dep_profile_primary_account_short_name_enable');
                const depProfilePrimaryAccountFullNameEnable = $('#dep_profile_primary_account_full_name_enable');

                MDM.fn.updateCheckboxShadowElement(depProfileMacosAccountCreate);
                MDM.fn.updateCheckboxShadowElement(depProfilePrimaryAccountShortNameEnable);
                MDM.fn.updateCheckboxShadowElement(depProfilePrimaryAccountFullNameEnable);

                MDM.fn.toggleElement(depProfilePrimaryAccountPromptUser, $('#create-primary-account-details'));
                MDM.fn.toggleElement(depProfilePrimaryAccountShortNameEnable, $('#primary-account-short-name-section'));
                MDM.fn.toggleElement(depProfilePrimaryAccountFullNameEnable, $('#primary-account-full-name-section'));
                MDM.fn.toggleElement(depProfileMacosAccountCreate, $('#create-admin-account-details'));

                depProfilePrimaryAccountPromptUser.change(this.testIfAdminAccountRequired);
                depProfileMacosAccountType.change(this.testIfAdminAccountRequired);
                this.testIfAdminAccountRequired();


                depProfilePrimaryAccountAllowUserModification.addEventListener('change', (e) => {
                    if (e.target.checked) {
                        depProfilePrimaryAccountShortNameEnable.attr('disabled', false);
                        depProfilePrimaryAccountFullNameEnable.attr('disabled', false);
                    } else {
                        depProfilePrimaryAccountShortNameEnable
                            .prop('checked', true)
                            .attr('disabled', true)
                            .trigger('change');
                        depProfilePrimaryAccountFullNameEnable.prop('checked', true)
                            .attr('disabled', true)
                            .trigger('change');
                    }
                })
                depProfilePrimaryAccountAllowUserModification.dispatchEvent(new Event("change"))
                this.updateMdmRemovalCheckbox()

                document.getElementById('dep_profile_is_supervised').addEventListener('change', this.updateMdmRemovalCheckbox)

                MDM.fn.updateCheckboxShadowElement($("#dep_profile_macos_account_store_password"))

                document.getElementById('dep_profile_macos_account_generate_password').addEventListener('change', this.updateStoreMdmPasswordCheckbox)

                this.updateStoreMdmPasswordCheckbox()

                let saveButton = document.querySelector('.save-button');
                if (saveButton != null) {
                    saveButton.addEventListener('click', (e) => {
                        const saveButton = e.target
                        saveButton.value = 'Applying, please wait...'
                        saveButton.disabled = true
                        saveButton.closest('form').submit();
                    });
                }

                MDM.fn.toggleDOMElement(document.getElementById('dep_profile_enable_macos_min_os_version'), document.getElementById('macos-min-os-version-section'));
                MDM.fn.toggleDOMElement(document.getElementById('dep_profile_enable_ios_min_os_version'), document.getElementById('ios-min-os-version-section'));
                this.wireCustomOsVersionControls()
            }
        },
        updateMdmRemovalCheckbox() {
            const supervisedCheckbox = document.getElementById('dep_profile_is_supervised')
            const removableCheckbox  = document.getElementById('dep_profile_is_mdm_removable')
            const defaultValue       = document.querySelector('input[type=hidden][name="dep_profile[is_mdm_removable]"]')

            if (supervisedCheckbox.checked) {
                removableCheckbox.disabled = false
                defaultValue.value = "0"
            } else {
                removableCheckbox.checked = true
                defaultValue.value = "1"
                removableCheckbox.disabled = true
            }
        },
        testIfAdminAccountRequired() {
            const primaryAccountPromptUser = document.getElementById('dep_profile_primary_account_prompt_user')
            const macosAccountCreate       = document.getElementById('dep_profile_macos_account_create')
            const macosAccountType         = document.getElementById('dep_profile_macos_account_type')
            const e = new Event("change");

            if (!primaryAccountPromptUser.checked || macosAccountType.value !== 'admin_account') {
                macosAccountCreate.disabled = true
                macosAccountCreate.checked = true
            } else {
                macosAccountCreate.disabled = false
            }
            macosAccountCreate.dispatchEvent(e)
        },
        updateStoreMdmPasswordCheckbox() {
            const storePasswordCheckbox = document.getElementById('dep_profile_macos_account_store_password')
            const randomPasswordCheckbox = document.getElementById('dep_profile_macos_account_generate_password')
            // this can be null if the password reveal functionality is disabled
            const passwordField = document.getElementById('dep_profile_macos_account_password')
            const e = new Event("change");

            if (randomPasswordCheckbox.checked) {
                storePasswordCheckbox.disabled = true
                storePasswordCheckbox.checked  = true
                if (passwordField) {
                    passwordField.disabled         = true
                }
            } else {
                storePasswordCheckbox.disabled = false
                if (passwordField) {
                    passwordField.disabled         = false
                }
            }
            storePasswordCheckbox.dispatchEvent(e)
        },
        wireGeneralSettingsForm() {
            const userEnrollmentRadios = document.querySelectorAll('.enrollment_code_user_enrollment input[type="radio"]')

            if ( userEnrollmentRadios.length === 0 ) { return }

            this.setRemoteDesktopState()
            userEnrollmentRadios.forEach((element) =>  {
                element.addEventListener('change', this.setRemoteDesktopState)
            })
        },

        setRemoteDesktopState() {
            const enableRemoteDesktopCheck = document.getElementById('enrollment_code_enable_remote_desktop')
            const isUserEnrollment = document.getElementById('enrollment_code_user_enrollment_true').checked

            enableRemoteDesktopCheck.disabled = isUserEnrollment
            if (isUserEnrollment) {
                enableRemoteDesktopCheck.checked = false
            }
        },
        wireCustomOsVersionControls() {
            // Custom OS version controls
            const macOsMinOsVersion = document.getElementById('dep_profile_macos_min_os_version');
            const macOMinCustomOsSection = document.getElementById('macos-min-os-custom-section');
            const iosMinOsVersion = document.getElementById('dep_profile_ios_min_os_version');
            const iosMinCustomOsSection = document.getElementById('ios-min-os-custom-section');

            if (!macOsMinOsVersion || !macOMinCustomOsSection || !iosMinOsVersion || !iosMinCustomOsSection) {
                return
            }

            const toggleCustomOsVersion = (element, value) => {
                if (value === "Custom") {
                    MDM.fn.show(element)
                } else {
                    MDM.fn.hide(element)
                }
            }

            macOsMinOsVersion.addEventListener('change', (e) => {
                toggleCustomOsVersion(macOMinCustomOsSection, e.target.value)
            })
            macOsMinOsVersion.dispatchEvent(new Event('change'))

            iosMinOsVersion.addEventListener('change', (e) => {
                toggleCustomOsVersion(iosMinCustomOsSection, e.target.value)
            })
            iosMinOsVersion.dispatchEvent(new Event('change'))
        },

    }
};