import React from 'react';
import { userRoles as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { callWithIds, truncate } from "../../shared/functions";

const getAjaxClient = () => {
    return {
        ...ajaxClient,
        read:            (options) => {
            return ajaxClient.read(options).then(response => {
                return {
                    ...response,
                    data: response.data.map(role => ({ ...role, checkDisabled: role.name === 'Administrator' }))
                }
            })
        },
        destroyMultiple: (userRoles) => {
            return callWithIds(userRoles, ajaxClient.destroyMultiple)
                .then((responses) => {
                    const failedResponses = responses.filter(({ status }) => status === 'rejected');
                    const failureCount    = failedResponses.length;
                    if (failureCount > 0) {
                        const userRole = failureCount > 1 ? 'user roles' : 'user role';
                        let messages   = failedResponses.map(
                            ({ reason: { responseJSON: { error: { message: messageString } } } }) => messageString
                        ).filter((message) => message !== undefined);

                        let errorMessage = `Failed to remove ${ failureCount } ${ userRole }.`;
                        if (messages.length > 0) {
                            errorMessage = `${ errorMessage }\n\n${ messages.join("\n\n") }`;
                        }
                        window.addFlashAlert('error', errorMessage)
                    } else {
                        window.addFlashAlert('notice', 'Selected Roles were deleted.')
                    }

                    return responses;
                })

        }
    }
};

class UserRolesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.renderActionButton = this.renderActionButton.bind(this);
        this.pagedTableProps    = this.pagedTableProps.bind(this);
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
            }
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
            ],
            renderRow: (row) => {
                return Object.assign({}, row, {
                    name: <a href={ AdminRoutes.adminUserRolePath(row.id, {format: null}) }>{ truncate(row.name, 50) }</a>
                });
            }
        };
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                Unable to load user roles.
            </div>
        );

        const message = <p>User roles allow you to create groups of permissions that can be assigned
            to users in your account.</p>;
        const actions = (
            <React.Fragment>
                { this.renderActionButton() }
                <a href={ AdminRoutes.newAdminUserRolePath({format: null}) } className="pull-right btn btn-default">Add Role</a>
            </React.Fragment>
        );
        return (
            <div id="apps-table">
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(UserRolesPagedTable, getAjaxClient, undefined, null);