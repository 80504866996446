export const login_windows = {
    show() {
        this.fn.wireForms();
        let showFullName = document.getElementById("login_window_show_full_name");
        showFullName.addEventListener("change", this.fn.wireForms);
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },
    fn: {
        wireForms() {
            let userElements = document.querySelectorAll("#list-users-options input");
            const showFullName = document.getElementById("login_window_show_full_name");

            if (showFullName.checked) {
                disableInput(...userElements);
            } else {
                enableInput(...userElements);
            }
        }
    }
};

const disableInput = (...elements) => {
    elements.forEach(el => {
        el.disabled = true;
        el.checked = false;
        el.closest(".form-group").classList.add("text-muted");
    })
}

const enableInput = (...elements) => {
    elements.forEach(el => {
        el.disabled = false;
        el.closest(".form-group").classList.remove("text-muted");
    });
};