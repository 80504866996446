import React, { useCallback, useMemo } from 'react'
import DynamicList from '../shared/dynamic_list'

function ManagedExtensions(props) {
  const { data, policy, meta: { options } } = props
  const [state, setState] = React.useState({items: data.managedExtensions})

  const onAddItem = useCallback(() => {
    const newItem = {
      bundle_identifier: "",
      allowed_domains: "",
      denied_domains: "",
      private_browsing: "",
      state: ""
    }
    setState({ items: [...state.items, newItem]})
  }, [state.items])

  const onRemoveItem = useCallback((_removedItem, removedIndex) => {
    const newItems = state.items.filter((_item, index) => index !== removedIndex)
    setState({ items: newItems })
  }, [state.items])

  const onChangeItem = useCallback((updatedItem, updatedIndex) => {
    setState({
      items: state.items.map((item, index) => {
        if (index === updatedIndex) {
          return updatedItem
        }
        return item
      })
    })
  }, [state.items])

  const headers = useMemo(() => {
    return [
      {
        displayName: "Bundle Identifier",
        key: "bundleIdentifier",
        tooltip: 'The composed identifier of the managed extension, or "*" for all extensions. In order for the extension to be managed, its host app must be present on the device.'
      },
      {
        displayName: "State",
        key: "state",
        dropdown: true,
        options: options,
        tooltip: "Allowed - The user is allowed to turn the extension on or off. AlwaysOn - The extension will always be on. AlwaysOff - The extension will always be off."
      },
      {
        displayName: "Private Browsing",
        key: "privateBrowsing",
        dropdown: true,
        options: options,
        tooltip: "Allowed - The user is allowed to turn the extension on or off. AlwaysOn - The extension will always be on. AlwaysOff - The extension will always be off."
      },
      {
        displayName: "Allowed Domains",
        key: "allowedDomains",
        multiline: true,
      },
      {
        displayName: "Denied Domains",
        key: "deniedDomains",
        multiline: true
      },
    ]
  }, [options])


  return (
    <DynamicList
      rows={state.items}
      onRemoveItem={onRemoveItem}
      onAddItem={onAddItem}
      onChangeItem={onChangeItem}
      headers={headers}
      policy={policy}
      fieldName="safari_extension_setting[managed_extensions_attributes]"
    />
  );
}

export default ManagedExtensions;