export const enrollmentCodes = {
    read(options) {
        return $.getJSON(AdminRoutes.adminEnrollmentCodesPath(options));
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.destroyMultipleAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    },

    enableRemoteDesktop(ids) {
        return $.ajax({
            method:   'PATCH',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.enableRemoteDesktopAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    },
    disableRemoteDesktop(ids) {
        return $.ajax({
            method:   'PATCH',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.disableRemoteDesktopAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    }
};