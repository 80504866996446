import React from 'react';
import FormGroup from "../forms/form_group";
import { SearchBar, SearchBarResult } from "../search"
import { appGroups as appGroupsClient } from "../../ajax_clients";
import { bindMethods, filterUnique } from "../../shared/functions";

export default class AppsCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value:         props.value || "",
            initialValue:  props.value || "",
            categories:    [],
            searchResults: [],
        }
        bindMethods(this);
    }

    componentDidMount() {
        appGroupsClient.read().then(response => {
            const categories = response.data.map(appGroup => appGroup.category)
                .filter(category => category !== null)
                .filter(filterUnique)
                .sort()
            this.setState({ categories })
        })
    }

    onSearch(regex, value) {
        let categories = this.state.categories;
        value          = (value || this.state.value).trim()

        if (regex !== null) {
            categories = categories.filter(category => regex.test(category))
        }
        let searchResults = [];
        if (categories.length > 0) {
            searchResults = categories.map(category => {
                return <SearchBarResult key={ category } onChooseItem={ () => {
                    this.onChooseItem(category)
                } }>
                    { category }
                </SearchBarResult>
            })
        } else if (value !== null && value !== "") {
            searchResults = [
                <SearchBarResult key="add-new" onChooseItem={ () => {
                    this.onAddCategory(value)
                    this.onChooseItem(value)
                } }>
                    Add "{ value }"
                </SearchBarResult>
            ]
        }

        this.setState({ searchResults, value })
    }

    onAddCategory(newCategory) {
        const categories = [...this.state.categories, newCategory].filter(filterUnique).sort()
        this.setState({ categories })
    }

    onChooseItem(value) {
        if (value && !/^\s*$/.test(value)) {
            const setValue = () => {
                this.setState({ value, initialValue: value })
            }

            if (value === this.state.initialValue) {
                // if a user chooses the currently set value, we need to clear the search bar key to re-render
                // the initial search value
                this.setState({ initialValue: "" }, setValue)
            } else {
                setValue();
            }
        }
    }

    render() {
        return <React.Fragment>
            <FormGroup title="Category">
                <SearchBar key={ `value-${ this.state.initialValue }` }
                           initialValue={ this.state.initialValue }
                           disabled={ this.props.disabled }
                           onSearchChange={ this.onSearch }
                           renderClearButton={ false }
                           renderSearchGlyph={ false }
                >
                    { this.state.searchResults }
                </SearchBar>
            </FormGroup>
            <input type="hidden" name="app[category]" value={ this.state.value }/>
        </React.Fragment>
    }
}