import passwordRevealInput from '../../shared/password_reveal_input'

export const apns = {
    new() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },

    show() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },

    update() {
        passwordRevealInput()
    },

    create() {
        passwordRevealInput()
    },

    fn: {
        wireAndUpdateForm() {
            const apnType = document.getElementById('apn_apn_type')

            this.toggleApnFields(apnType.value)
            apnType.addEventListener('change', (e) => this.toggleApnFields(e.target.value))
        },
        toggleApnFields(apnType) {
            const defaultApn = document.getElementById('default-apn')
            const dataApn    = document.getElementById('data-apn')

            if (apnType == 'default') {
              defaultApn.hidden = false
              dataApn.hidden = true
            } else if (apnType == 'data') {
              defaultApn.hidden = true
              dataApn.hidden = false
            } else if (apnType == 'both') {
              defaultApn.hidden = false
              dataApn.hidden = false
            }
        }
    }
}