import React, { useCallback, useState } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui'
import { useForm } from "react-hook-form"
import { visitURL } from "../../shared/query";
import { ThemeProvider }  from '../shared/simplemdm_theme'
import { consultationCalls as ajaxClient } from '../../ajax_clients/consultation_calls'
import Button from './button'
import Container from './container'
import Header from './header'
import { useRailsErrorHandler } from "./hooks"
import Paper from './paper'

function ConsultationCall(props) {
  const { consultationCall } = props
  const [isLoading, setIsLoading] = useState(false)
  const formContext = useForm({ defaultValues: consultationCall })
  const { handleSubmit, setError } = formContext
  const handleRailsError = useRailsErrorHandler(setError)
  const goToPushCertificate = useCallback(() => visitURL(AdminRoutes.newAdminPushCertificatePath({format: null})), [])

  const onSuccess = useCallback(async (data) => {
    setIsLoading(true)

    try {
      await ajaxClient.create(data)
    } catch(e) {
      handleRailsError(e)
    }

    setIsLoading(false)
  }, [handleRailsError, setIsLoading, consultationCall])

  return (
      <Container>
        <Grid item>
          <Paper sx={{maxWidth: 600}}>
            <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSuccess)} >
              <Grid container direction='column' justifyContent="center">
                <Header>Jump-start your deployment</Header>
                <p>We provide a free technical consultation to discuss your requirements and ensure a successful deployment. Share your contact information below and we'll give you a call.</p>
                <p style={{paddingBottom: 10}}><b>Note:</b> Your phone number will not be used for any other purpose.</p>
                <TextFieldElement
                  id="name"
                  fullWidth
                  label="Your name"
                  name="name"
                  required
                  sx={{ paddingBottom: 2 }}
                  variant="outlined"
                />
                <TextFieldElement
                  id="phoneNumber"
                  fullWidth
                  label="Phone number"
                  name="phoneNumber"
                  required
                  variant="outlined"
                />
                <FormHelperText sx={{ paddingBottom: 2 }}>Please include a “+” and country code if note a US phone number</FormHelperText>
                <Button
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  variant="contained">
                  Request call
                </Button>
                <Button
                  disabled={isLoading}
                  fullWidth
                  onClick={goToPushCertificate}
                  variant="text">
                  No thanks
                </Button>
              </Grid>
            </FormContainer>
          </Paper>
        </Grid>
      </Container>
  )
}

export default (props) => <ThemeProvider typography={{fontFamily:'"Inter", "Helvetica", "Arial", sans-serif'}}><ConsultationCall {...props}/></ThemeProvider>
