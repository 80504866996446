import { visitURL } from "../../shared/query";
import React from "react";

const curryClient = (client, id) => {
    const newClient = {}
    Object.entries(client).forEach(([key, func]) => {
        newClient[key] = (...args) => func(id, ...args)
    })
    return newClient;
}

export const getAjaxClient = (assignableObject, ajaxClients) => {
    if (assignableObject) {
        const { type, id } = assignableObject
        return type === 'device' ? curryClient(ajaxClients.device, id) : curryClient(ajaxClients.group, id)
    }
    return ajaxClients.default
}

export const errorHandler = (component) => {
    return data => {
        if (data?.responseJSON?.error?.message) {
            window.addFlashAlert('error', data.responseJSON.error.message);
        } else {
            window.addFlashAlert('error', 'There was a problem processing your request');
        }
        if (data?.responseJSON?.error?.modelErrors) {
            component.setState({ errors: data.responseJSON.error.modelErrors, saving: false })
        }
    }
}

export const successHandler = (component) => {
    return ({ redirectTo }) => {
        component.setState({ saving: false, errors: {} })
        const path = redirectTo ? redirectTo : backPath(component.props.assignableObject);
        component.setState({ errors: {} }, () => {
            visitURL(path)
        });
    }
};

export const backPath = (assignableObject) => {
    let backPath
    if (assignableObject?.type === 'device') {
        backPath = AdminRoutes.adminDevicePath(assignableObject.id, { anchor: 'profiles', format: null })
    } else if (assignableObject?.type === 'group') {
        backPath = AdminRoutes.adminGroupPath(assignableObject.id, { anchor: 'profiles', format: null })
    } else {
        backPath = AdminRoutes.adminProfilesPath({format: null})
    }
    return backPath
}

export const requirementColumns = (profileType) => {
    const { requiresSupervision } = profileType
    const requiresOS              = Object.keys(profileType.requiresOS)
    const supervisionLabel        = <span className="label label-info platform-support-label supervision" rel="tooltip"
                                          title="Supervision required">S</span>;
    const dotChar                 = <span className="dot"><>&#8226;</></span>;

    let macos, ios, tvos

    if (requiresOS.length === 0) {
        macos = ios = tvos = dotChar
    } else {
        [macos, ios, tvos] = ['macos', 'ios', 'tvos'].map(
            key => {
                if (requiresOS.includes(key)) {
                    const supervisionForKey = Array.isArray(requiresSupervision) && requiresSupervision.includes(key);
                    if (requiresSupervision === true || supervisionForKey) {
                        return supervisionLabel
                    } else {
                        return dotChar
                    }
                }
            }
        )
    }

    return { macos, ios, tvos }
}
