import React from 'react';
import pluralize from 'pluralize'
import { bindMethods } from "../../shared/functions";
import AssignmentGroup from './assignment_group'
export default class AssignmentGroupCollapsed extends React.Component {

    static calculateDeviceCount(assignmentGroup) {
        return assignmentGroup.associatedDeviceIds.length
    }

    constructor(props) {
        super(props);
        this.expand            = this.expand.bind(this);
        this.state             = {
            deviceCount: AssignmentGroupCollapsed.calculateDeviceCount(props.assignmentGroup)
        };
        bindMethods(this)
    }

    expand(e) {
        e.preventDefault();
        this.props.onExpand(this.props.assignmentGroup);
    }

    static getDerivedStateFromProps(nextProps, state) {
        const previousAssignmentGroup = state.previousAssignmentGroup;
        if (previousAssignmentGroup && nextProps.assignmentGroup !== previousAssignmentGroup) {
            return {
                deviceCount:             AssignmentGroupCollapsed.calculateDeviceCount(nextProps.assignmentGroup),
                previousAssignmentGroup: nextProps.assignmentGroup
            };
        }
        return null;
    }

    renderAssetsInfo() {
        const assets              = this.props.assignmentGroup.apps.concat(this.props.assignmentGroup.media);
        const numberOfIconsToShow = 5;
        const icons               = assets.slice(0, numberOfIconsToShow)
            .map(asset => <img key={ asset.id + asset.type } alt={ asset.name } src={ asset.iconUrl }/>)
        const additionalAssets    = assets.length - numberOfIconsToShow;
        let additionalAssetText   = null;
        if (additionalAssets > 0) {
            additionalAssetText = `+ ${ additionalAssets } more.`
        }
        return (
            <td className="assets-info">
                { icons } { additionalAssetText }
            </td>
        )
    }

    renderDevicesInfo() {
        let deviceInfoText;
        if (this.state.deviceCount === 0) {
            if (this.props.allDataLoaded) {
                deviceInfoText = "no devices";
            } else {
                deviceInfoText = "loading devices...";
            }
        } else {
            deviceInfoText   = `${ this.state.deviceCount } ${ pluralize('device', this.state.deviceCount) }`
        }
        return (
            <td className="devices-info">
                { deviceInfoText }
            </td>
        )
    }

    render() {
        return (
            <div className="asset-group collapsed">
                <div className="asset-group-click-container" onClick={ this.expand }>
                    <table className="header">
                        <tbody>
                        <tr>
                            <td className="name">
                                { this.props.assignmentGroup.name }
                                { AssignmentGroup.renderLabels(this.props.assignmentGroup) }
                            </td>
                            { this.renderAssetsInfo() }
                            { this.renderDevicesInfo() }
                            <td>
                                    <span
                                        onClick={ this.expand }
                                        rel="tooltip"
                                        title="Expand assignment group display."
                                        className="glyphicon glyphicon-chevron-down btn"
                                    />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}