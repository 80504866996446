export const loginAndBackgroundItemManagements = {
    create(data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ loginAndBackgroundItemManagement: data }),
        		url:         AdminRoutes.adminLoginAndBackgroundItemManagementsPath()
				});
    },

    update(id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  loginAndBackgroundItemManagement: data }),
            url:         AdminRoutes.adminLoginAndBackgroundItemManagementPath(id)
        });
    },

    delete(id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminLoginAndBackgroundItemManagementPath(id)
        });

    },

    duplicate(id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminLoginAndBackgroundItemManagementPath(id)
        });
    }
};

export const deviceLoginAndBackgroundItemManagements = {
    create(deviceId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ loginAndBackgroundItemManagement: data }),
        		url:         AdminRoutes.adminDeviceLoginAndBackgroundItemManagementsPath(deviceId)
   		  });
    },

    update(deviceId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  loginAndBackgroundItemManagement: data }),
            url:         AdminRoutes.adminDeviceLoginAndBackgroundItemManagementPath(deviceId, id)
        });
    },

    delete(deviceId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminDeviceLoginAndBackgroundItemManagementPath(deviceId, id)
        });

    },

    duplicate(deviceId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminDeviceLoginAndBackgroundItemManagementPath(deviceId, id)
        });
    }
};

export const groupLoginAndBackgroundItemManagements = {
    create(groupId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ loginAndBackgroundItemManagement: data }),
            url:         AdminRoutes.adminGroupLoginAndBackgroundItemManagementsPath(groupId)
    	  });
    },

    update(groupId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  loginAndBackgroundItemManagement: data }),
            url:         AdminRoutes.adminGroupLoginAndBackgroundItemManagementPath(groupId, id)
        });
    },

    delete(groupId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminGroupLoginAndBackgroundItemManagementPath(groupId, id)
        });
    },

    duplicate(groupId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminGroupLoginAndBackgroundItemManagementPath(groupId, id)
        });
    }
};

export const allLoginAndBackgroundItemManagements = {
  default: loginAndBackgroundItemManagements,
  device: deviceLoginAndBackgroundItemManagements,
  group: groupLoginAndBackgroundItemManagements
}
