import React, { useCallback, useMemo } from "react"
import {default as ReactJoyride} from 'react-joyride'
import { visitURL } from "../../shared/query";

const STEP_DEFAULTS = {
  placement: 'center',
  locale: { close: 'Done', last: 'Done' },
  styles: { tooltipContainer: { textAlign: 'left' } },
  target: 'body'
}

const STYLE_OPTIONS = {
  primaryColor: '#0066F1',
}

export default function Joyride(props) {
  const { nextPath, steps: stepsProp, ...otherProps } = props

  const callback = useCallback(({action}) => {
    if (nextPath != null && ['close', 'reset'].includes(action)) visitURL(nextPath)
  }, [nextPath])

  const steps = useMemo(() => stepsProp.map((s) => ({
    ...STEP_DEFAULTS,
    ...s,
    locale: { ...STEP_DEFAULTS.locale, ...(s.locale ?? {}), },
    styles: { ...STEP_DEFAULTS.styles, ...(s.styles ?? {}), },
  })), [stepsProp])

  return (
    <ReactJoyride
      callback={callback}
      continuous
      showSkipButton
      steps={steps}
      styles={{options: STYLE_OPTIONS}}
      {...otherProps}
    />
  )
}
