export const lock_screen_messages = {
    show() {
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    }
};