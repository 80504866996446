import * as React from 'react'
import { ThemeProvider } from '../shared/simplemdm_theme'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'

import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined'
import AppsIcon from '@mui/icons-material/Apps'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import LogoutIcon from '@mui/icons-material/Logout'

import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { v2Route } from '../../shared/functions';

import MenuItem from './menu_item'

import { users as userClient } from '../../ajax_clients'

const sidebarWidth = 244
const sections = [
  { title:  'Devices', preferenceName: 'devices', icon: <PhonelinkOutlinedIcon />, permission: 'payload', items: [
      {
        title: 'Devices',
        path: AdminRoutes.adminDevicesPath({ format: null }),
        activePaths: [
          '/admin/deleted_devices',
          '/admin/enrollments',
          '/admin/locks'
        ]
      },
      { title: 'Groups', path: AdminRoutes.adminGroupsPath({ format: null }), className: "groups-link" },
      { title: 'Groups V2', path: v2Route("groups"), permission: 'v2Private', className: "groups-link" },
      {
        title: 'Enrollments',
        path: AdminRoutes.adminEnrollmentCodesPath({ format: null }),
        activePaths: [
          '/admin/dep_accounts',
          '/admin/dep_server_tokens'
        ]
      },
      { title: 'Locations', path: AdminRoutes.adminLocationsPath({ format: null }) }
    ]
  },
  { title:  'Apps & Media', preferenceName: 'apps_media', icon: <AppsIcon />, items: [
      {
        title: "Catalog",
        path: AdminRoutes.adminCatalogPath({ format: null }),
        activePaths: [
          '/admin/apps',
          '/admin/app_groups',
          '/admin/catalog',
          '/admin/media',
          '/admin/volume_purchase_codes'
        ]
      },
      { title: "Assignment", path: AdminRoutes.adminAssignmentGroupsPath({ format: null }) },
      { title: "VPP", path: AdminRoutes.adminVPPTokensPath({ format: null }) },
      { title: "Munki", path: AdminRoutes.adminMunkiPath({ format: null }) }
    ]
  },
  { title:  'Configs', preferenceName: 'configs', icon: <SettingsOutlinedIcon />, className: "configs-link", items: [
      {
        title: "Profiles",
        path: AdminRoutes.adminProfilesPath({ format: null }),
        permission: 'payload',
        activePaths: [
          '/admin/airprint_printers',
          '/admin/apns',
          '/admin/app_restrictions',
          '/admin/caldav_accounts',
          '/admin/carddav_accounts',
          '/admin/certificates',
          '/admin/custom_configuration_profiles',
          '/admin/disk_management_settings',
          '/admin/email_accounts',
          '/admin/extensible_single_sign_ons',
          '/admin/filevaults',
          '/admin/firewalls',
          '/admin/firmware_passwords',
          '/admin/global_http_proxies',
          '/admin/google_accounts',
          '/admin/home_screen_layouts',
          '/admin/kernel_extension_policies',
          '/admin/ldap_accounts',
          '/admin/notification_settings',
          '/admin/passcode_policies',
          '/admin/privacy_preferences',
          '/admin/recovery_lock_passwords',
          '/admin/restrictions',
          '/admin/single_app_locks',
          '/admin/single_sign_on_accounts',
          '/admin/software_update_policy_ios',
          '/admin/software_update_policy_macos',
          '/admin/managed_software_updates',
          '/admin/safari_extension_settings',
          '/admin/subscribed_calendar_accounts',
          '/admin/system_extension_policies',
          '/admin/vpn_accounts',
          '/admin/wallpapers',
          '/admin/web_clips',
          '/admin/web_content_filters',
          '/admin/wifi_networks',
          '/admin/software_update_settings',
          '/admin/background_tasks',
          '/admin/disk_management_settings',
          '/admin/lock_screen_messages',
          '/admin/service_configuration_files',
          '/admin/gatekeeper_policies',
          '/admin/printings',
          '/admin/login_and_background_item_managements',
          '/admin/airplay_devices',
        ]
      },
      {
        title: "Providers",
        path: AdminRoutes.adminAccountProvidersPath({ format: null }),
        permission: 'payload',
        activePaths: [
          '/admin/caldav_providers',
          '/admin/carddav_providers',
          '/admin/email_providers',
          '/admin/ldap_providers',
          '/admin/ldap_search_settings',
          '/admin/subscribed_calendar_providers',
          '/admin/vpn_providers'
        ]
      },
      { title: "Attributes", path: AdminRoutes.adminCustomAttributesPath({ format: null }), permission: 'customAttribute' }
    ]
  },
  { title:  'Scripts', preferenceName: 'scripts', icon: <MonitorHeartOutlinedIcon />, items: [
      { title: "Scripts", path: AdminRoutes.adminScriptsPath({ format: null }), permission: 'script' },
      { title: "Jobs", path: AdminRoutes.adminScriptJobsPath({ format: null }), permission: 'scriptJob' }
    ]
  },
  { title:  'Account', preferenceName: 'account', icon: <ManageAccountsIcon />, items: [
      { title: "Settings", path: AdminRoutes.adminAccountPath({ format: null }), permission: 'account' },
      {
        title: "Users",
        path: AdminRoutes.adminUserManagementPath({ format: null }),
        permission: 'userManagement',
        activePaths: [
          '/admin/user_roles'
        ]
      },
      { title: "Notifications", path: AdminRoutes.adminAdminNotificationsPath({ format: null }) },
      { title: "Push Certificate", path: AdminRoutes.adminPushCertificatePath({ format: null }) },
      { title: "Logs", path: AdminRoutes.adminLogsPath({ format: null }), permission: 'auditLog' },
      {
        title: "API",
        path: AdminRoutes.adminAPIPath({ format: null }),
        permission: 'api',
        activePaths: [
          '/admin/api_keys',
          '/admin/webhook_endpoints',
        ]
      },
      {
        title: "Billing",
        path: AdminRoutes.adminBillingPath({ format: null }),
        permission: 'billing',
        activePaths: [
          '/admin/billing',
          '/admin/credit_card',
          '/admin/receipts'
        ]
      }
    ]
  }
]

function Sidebar({data: { permissions, preferences } }) {
  const permitted = ({ permission }) => {
    return permission === undefined || permissions[permission] == true
  }

  const onExpandSection = (preferenceName) => {
    userClient.enableSidebarSection({ section: preferenceName })
  }

  const onCollapseSection = (preferenceName) => {
    userClient.disableSidebarSection({ section: preferenceName })
  }

  const menuItems = () => {
    return sections.map((section) => {
      if (!permitted(section)) { return }

      const items = section.items.filter((item) => permitted(item))

      if (items.length == 0) { return }

      const expanded = preferences?.expandedSections?.includes(section.preferenceName)

      const props = { ...section, items, expanded, onExpandSection, onCollapseSection }
      return (<MenuItem key={ section.preferenceName } { ...props } />)
    })
  }

  return (
    <>
      <Box
        sx={ {
          width: sidebarWidth,
          display: 'flex',
          height: '100%'
        } }>
        <Paper variant="outlined" square sx={ {
          height: '100%',
          width: '100%',
          borderTop: 'none',
          paddingTop: '10px',
          paddingBottom: '40px'
        } }>
          <List component="nav" sx={ { width: '100%', maxWidth: sidebarWidth } }>

            { menuItems() }

            <Link
              underline="none"
              color="inherit"
              href="https://simplemdm.pdq.com/hc/en-us"
              target="_blank"
              component={ ListItemButton }>
              <ListItemIcon>
                <LiveHelpOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={ "Support" } disableTypography  sx={ { fontWeight: 500 } }  />
            </Link>
          </List>

        </Paper>
      </Box>
      <Paper variant="outlined" square sx={ { position: 'fixed', width: '100%', maxWidth: sidebarWidth, bottom: 0 } }>
        <Link
          underline="none"
          color="inherit"
          href={ AdminRoutes.destroyUserSessionPath({ format: null }) }
          component={ ListItemButton }
          data-method="delete">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={ "Sign Out" } />
        </Link>
      </Paper>
    </>
  )
}

export default (props) => <ThemeProvider><Sidebar {...props}/></ThemeProvider>
