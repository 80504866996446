import React from 'react';
import SearchBarResult from "../search/search_bar_result";
import SearchBar from "../search/search_bar";
import FormGroup from "../forms/form_group";
import { appIdentifiers as ajaxClient } from '../../ajax_clients';
import { bindMethods, truncate } from '../../shared/functions'
import DynamicList from "./dynamic_list";

export default class AppSearch extends React.Component {
    static renderIcon(searchResult) {
        if (searchResult.iconUrl) {
            return (
                <span className="img-holder">
                    <img src={ searchResult.iconUrl }/>
                </span>
            )
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            increment: 0,  // re-render searchbar
            search:        null,
            searchResults: [],
            // normally copying props to state would be bad
            // but if selectedApp exists, this component effectively becomes read-only
            selectedSearchResult: props.selectedApp || null,
            searching:            false,
        };

        this.timeout      = null;

        bindMethods(this)
    }

    searchForApps() {
        clearTimeout(this.timeout);
        const runSearch = () => {
            if (this.state.search != null) {
                $('.turbolinks-progress-bar').hide();
                ajaxClient.search(this.state.search).then((data) => {
                    let error = null;
                    if (data.searchResults.length === 0) {
                        error = `No results found for ${ this.state.search }`;
                    }
                    this.setState({
                        searchResults: data.searchResults,
                        searching:     false,
                        error:         error
                    });
                    $('.turbolinks-progress-bar').show();
                }).catch((data) => {
                    this.setState({
                        searchResults: [],
                        searching:     false,
                        error:         data.responseJSON.error.message
                    });
                    $('.turbolinks-progress-bar').show();
                });
            } else {
                this.setState({ searching: false });
            }

        };
        this.timeout    = setTimeout(() => {
            this.setState({ searching: true, searchResults: [], error: null }, runSearch)
        }, 500);
    }

    onSearchChange(searchRegexp, search) {
        if (search == null) {
            this.setState({ search, searchResults: [], error: null });
        } else {
            search = search.trim();
            if (search !== this.state.search) {
                this.setState({ search }, this.searchForApps);
            }
        }
    }

    selectApp(searchResult) {
        if (this.props.storeSelectedSearchResult){
            this.setState({
                selectedSearchResult: searchResult
            });
        } else {
            this.setState({
                increment: this.state.increment + 1
            });
        }
        this.props.selectApp(searchResult)
    }

    removeSelectedSearchResult(e) {
        e.preventDefault();
        this.setState({ selectedSearchResult: null });
        this.props.selectApp({ name: null, bundleIdentifier: null })
    }

    renderSearchResults() {
        if (this.state.searchResults.length > 0) {
            return this.state.searchResults.map((searchResult) => {
                    return <SearchBarResult
                        key={ searchResult.bundleIdentifier }
                        onChooseItem={ () => this.selectApp(searchResult) }>
                        { AppSearch.renderIcon(searchResult) }
                        <span className="truncate">
                        { truncate(searchResult.name) }
                    </span>
                        <span className="type">{ searchResult.bundleIdentifier }</span>
                    </SearchBarResult>
                }
            )
        }
    }

    renderSearch() {
        return (
            <div id="app-search-bar">
                <SearchBar
                    key={ this.state.increment }
                    searching={ this.state.searching }
                    placeholderText={ 'Search for an app to add' }
                    inputClass="form-control string"
                    onSearchChange={ this.onSearchChange }
                >
                    { this.renderSearchResults() }
                </SearchBar>
            </div>
        )
    }

    renderSelectedSearchResult() {
        const searchResult = this.state.selectedSearchResult;
        let removeLink;
        if (this.props.newRecord) {
            removeLink = (
                <a className="remove-link" href="app/webpack/javascript/components/shared/app_search#" onClick={ (e) => this.removeSelectedSearchResult(e) }>
                    <span className="glyphicon glyphicon-remove"/>
                </a>
            )
        }
        return (
            <span id="selected-search-result">
                { AppSearch.renderIcon(searchResult) }
                { truncate(searchResult.name) }
                { removeLink }
            </span>
        )
    }

    render() {
        return (
            <FormGroup inputClasses={ [] } error={ this.props.error || this.state.error }
                       title="App"
                       required={ this.props.newRecord }>
                { this.state.selectedSearchResult === null || this.state.selectedSearchResult.name === null ?
                    this.renderSearch() : this.renderSelectedSearchResult() }
            </FormGroup>
        )
    }
}

AppSearch.defaultProps = {
    storeSelectedSearchResult: true
};