import React from 'react';
import { customAttributes as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds, truncate } from "../../shared/functions";
import PasswordReveal from "../shared/password_reveal";
const getAjaxClient = () => {
    return {
        ...ajaxClient,
        destroyMultiple: (certificates) => {
            return callWithIds(certificates, ajaxClient.destroyMultiple)
                .then((responses) => {
                    const failedResponses = responses.filter(({ status }) => status === 'rejected');
                    const failureCount    = failedResponses.length;
                    if (failureCount > 0) {
                        const item   = failureCount > 1 ? 'custom attributes' : 'custom attribute';
                        let messages = failedResponses.map(
                            ({ reason: { responseJSON: { error: { message: messageString } } } }) => messageString
                        ).filter((message) => message !== undefined);

                        let errorMessage = `Failed to remove ${ failureCount } ${ item }.`;
                        if (messages.length > 0) {
                            errorMessage = `${ errorMessage }\n\n${ messages.join("\n\n") }`;
                        }
                        window.addFlashAlert('error', errorMessage)
                    } else {
                        window.addFlashAlert('notice', 'Selected custom attributes were deleted.')
                    }

                    return responses;
                })

        }
    }
};

class CustomAttributesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Delete",
                policy: 'destroy'
            }
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    removeItems(e) {
        this.props.removeItems(e, "This will delete all selected custom attributes, and associated welcome screen inputs. Are you sure?");
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Default Value", key: "defaultValue" },
            ],
            renderRow: (row) => {
                let defaultValue
                if (row.secret) {
                    const dataFetcher = () => ajaxClient.read(row.id).then(({ data }) => [data.defaultValue])
                    defaultValue = <PasswordReveal permitted={ this.props.policy.showSecrets } dataFetcher={ dataFetcher } />
                } else {
                    defaultValue = row.defaultValue
                }

                return Object.assign({}, row, {
                    name: <a href={ AdminRoutes.adminCustomAttributePath(row.id, {format: null}) }>{ truncate(row.name, 50) }</a>,
                    defaultValue
                });
            }
        };
    }

    renderAddButton() {
        if (this.props.policy.new) {
            return <a href={ AdminRoutes.newAdminCustomAttributePath({format: null}) } className="pull-right btn btn-default">
                Add Attribute
            </a>;
        }
    }


    render() {
        const renderActionButton = this.renderActionButton();
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No custom attributes have been created yet.
            </div>
        );

        const message      = <p>Custom attributes allow you to define a set of keys.
            Devices can be assigned personalized values for these keys. These key-value attributes can then be
            utilized throughout SimpleMDM to provide each device with a customized configuration, where supported.</p>;

        const actions      = (
            <React.Fragment>
                { renderActionButton }
                { this.renderAddButton() }
            </React.Fragment>
        );
        return (
            <div id="custom-attributes-table">
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }

}

export default pagedTableLayout(CustomAttributesPagedTable, getAjaxClient);