export const adminNotifications = {
    read(options) {
        return $.getJSON(AdminRoutes.adminAdminNotificationsPath(options));
    },

    addUser(adminNotification, user) {
        return $.ajax({
            method:      'POST',
            contentType: 'application/json',
            dataType:    'json',
            url:         AdminRoutes.addUserAdminAdminNotificationPath(adminNotification.type),
            data:        JSON.stringify({ userId: user.id })
        })
    },

    removeUser(adminNotification, user) {
        return $.ajax({
            method:      'POST',
            contentType: 'application/json',
            dataType:    'json',
            url:         AdminRoutes.removeUserAdminAdminNotificationPath(adminNotification.type),
            data:        JSON.stringify({ userId: user.id })
        })
    },

    update(adminNotification) {
        return $.ajax({
            method:      'PUT',
            contentType: 'application/json',
            dataType:    'json',
            url:         AdminRoutes.adminAdminNotificationPath(adminNotification.type),
            data:        JSON.stringify({ adminNotification })
        })
    }
};