
export const systemExtensionPolicies = {
    create(data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ systemExtensionPolicy: data }),
        		url:         AdminRoutes.adminSystemExtensionPoliciesPath()
				});
    },

    update(id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  systemExtensionPolicy: data }),
            url:         AdminRoutes.adminSystemExtensionPolicyPath(id)
        });
    },

    delete(id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminSystemExtensionPolicyPath(id)
        });

    },

    duplicate(id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminSystemExtensionPolicyPath(id)
        });
    }
};

export const deviceSystemExtensionPolicies = {
    create(deviceId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ systemExtensionPolicy: data }),
        		url:         AdminRoutes.adminDeviceSystemExtensionPoliciesPath(deviceId)
   		  });
    },

    update(deviceId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  systemExtensionPolicy: data }),
            url:         AdminRoutes.adminDeviceSystemExtensionPolicyPath(deviceId, id)
        });
    },

    delete(deviceId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminDeviceSystemExtensionPolicyPath(deviceId, id)
        });

    },

    duplicate(deviceId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminDeviceSystemExtensionPolicyPath(deviceId, id)
        });
    }
};

export const groupSystemExtensionPolicies = {
    create(groupId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ systemExtensionPolicy: data }),
            url:         AdminRoutes.adminGroupSystemExtensionPoliciesPath(groupId)
    	  });
    },

    update(groupId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  systemExtensionPolicy: data }),
            url:         AdminRoutes.adminGroupSystemExtensionPolicyPath(groupId, id)
        });
    },

    delete(groupId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminGroupSystemExtensionPolicyPath(groupId, id)
        });
    },

    duplicate(groupId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminGroupSystemExtensionPolicyPath(groupId, id)
        });
    }
};

export const allSystemExtensionPolicies = {
  default: systemExtensionPolicies,
  device: deviceSystemExtensionPolicies,
  group: groupSystemExtensionPolicies
}
