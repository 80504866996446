import passwordRevealInput from '../../shared/password_reveal_input'

export const email_accounts = {
    new() {
        passwordRevealInput()
        this.wireAndUpdateForm()
    },

    show() {
        passwordRevealInput()
        this.wireAndUpdateForm()
    },

    update() {
        passwordRevealInput()
    },

    create() {
        passwordRevealInput()
        this.wireAndUpdateForm()
    },
    wireAndUpdateForm() {
        const providers = document.getElementById("email_account_email_provider_id")
        providers.onchange = function() {
            let deployAsProfile = document.getElementById("email_account_is_device_profile")
            let accountProvider = providers.options[providers.selectedIndex].dataset.provider;
            if (accountProvider === "Exchange") {
                deployAsProfile.checked = false;
                deployAsProfile.disabled = true;
            } else {
                deployAsProfile.disabled = false;
            }
        }
        providers.onchange();
    }

}