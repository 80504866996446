import React, { useCallback, useEffect, useMemo, useState } from "react"
import { devices as ajaxClient } from '../../ajax_clients/devices'
import { timeZones as timeZonesClient } from '../../ajax_clients'
import FormGroup from "../forms/form_group"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { visitURL } from "../../shared/query";

export default function SetTimeZone(props) {
  const { onError, onFinish } = props
  const devices = props.devices ?? props.data
  const deviceIds = useMemo(() => devices.map(({ id }) => id), [devices])
  const [timeZoneOptions, setTimeZoneOptions] = useState([])
  const [selectedTimeZone, setSelectedTimeZone] = useState(devices[0].diTimeZone)

  useEffect(() => {
    timeZonesClient.read().then((timeZones) => {
      setTimeZoneOptions(timeZones.map((tz) => ({ label: tz.name, value: tz.value })))
    })
  }, []);

  const goBack = useCallback((e) => {
    e.preventDefault()
    history.back()
  }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault()

    const flash = onFinish == null ? 'later' : 'now'
    ajaxClient.updateTimeZones(deviceIds, selectedTimeZone, { flash }).then(() => {
      if (onFinish == null) {
        visitURL(AdminRoutes.adminDevicePath(deviceIds[0], { format: null }))
      } else {
        onFinish()
      }
    }).catch(({ responseJSON }) => {
      const { errors } = responseJSON
      if (onError == null) {
        errors.forEach(({ title }) => window.addFlashAlert('error', title))
      } else {
        onError(error)
      }
    })
  }, [deviceIds, onError, onFinish, selectedTimeZone])

  if (timeZoneOptions.length === 0) return null

  return (
    <div className="set-time-zone multi-select-panel">
      <h1>Set Time Zone</h1>

      <div className="well">
        <p>
          This will update the time zone on the selected devices.
        </p>
        <form>
          <FormGroup title="Time Zone" inputClasses={['form-control', 'p-0']}>
            <Autocomplete
              disableClearable
              isOptionEqualToValue={(option, value) => option.value === value}
              onChange={(_e, value) => setSelectedTimeZone(value.value)}
              options={timeZoneOptions}
              value={selectedTimeZone}
              renderInput={(params) => (
                <TextField {...params} variant="standard" sx={{ ".MuiInput-root ": { "padding-left": "16px" }}} />
              )}
            />
          </FormGroup>

          <div className="buttons">
            <a href="#cancel" className="btn btn-default" onClick={goBack}>Cancel</a>
            <a href="#submit" className="btn btn-primary" onClick={onSubmit}>Submit</a>
          </div>
        </form>
      </div>
    </div>
  )
}
