import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class WelcomeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state        = {
            data: props.data,
        };
        this.headers      = [
            { displayName: 'Label Name', key: 'labelName' },
            {
                displayName: 'Custom Attribute',
                key:         'customAttributeId',
                dropDown:   true,
                options:     props.customAttributes
            },
            { displayName: 'Dropdown', key: 'dropdown', boolean: true },
            { displayName: 'Dropdown Options (comma-separated)', key: 'dropdownOptions' },
            { displayName: 'Required', key: 'required', boolean: true }
        ];
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem    = this.onAddItem.bind(this);
    }

    onChangeItem(updatedItem, updatedIndex) {
        this.setState({
            data: this.state.data.map((row, index) => index === updatedIndex ? updatedItem : row)
        })
    }

    onRemoveItem(removedItem, removedIndex) {
        if (removedItem.id) {
            this.onChangeItem({ ...removedItem, _deleted: true }, removedIndex);
        } else {
            this.setState({ data: this.state.data.filter((item, index) => index !== removedIndex) });
        }
    }

    disableInput(header, row, _rowNum, _colNum) {
        if (header.key === 'dropdownOptions') {
            return !row.dropdown;
        }
        return false;
    }

    onAddItem() {
        const newItem = {
            labelName:    "",
            customAttributeId: "",
            dropdown: false,
            dropdownOptions: "",
            required: false
        };

        this.setState({
            data: [...this.state.data, newItem]
        })
    }

    render() {
        return <DynamicList
            fieldName={ 'enrollment_welcome_screen[welcome_inputs_attributes]' }
            headers={ this.headers }
            rows={ this.state.data }
            policy={ this.props.policy }
            onChangeItem={ this.onChangeItem }
            onRemoveItem={ this.onRemoveItem }
            onAddItem={ this.onAddItem }
            disableInput={ this.disableInput }
        />
    }
}
