import React from 'react';
import { deviceInstalledMedia as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { callWithIds } from '../../shared/functions';

const getAjaxClient = ({ deviceId }) => {
    return {
        read:            (query) => ajaxClient.read(deviceId, query),
        destroyMultiple: (installedMedia) => callWithIds(installedMedia, ajaxClient.destroyMultiple)
    }
};

class InstalledMediaPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.renderActionButton = this.renderActionButton.bind(this);
        this.removeItems        = this.removeItems.bind(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Delete",
                policy: 'destroy'
            }
        ];
        return <div className="button-list-container">
            <ActionDropDown
            text="Actions"
            policy={ this.props.policy }
            items={ items }
            disabled={ !this.props.hasCheckedItems() }/>
        </div>
    }

    removeItems(e) {
        this.props.removeItems(e, 'This will remove the media from the device. Are you sure?')
    }

    pagedTableProps() {
        return {
            headers: [
                { displayName: 'Title', key: 'title' },
                { displayName: 'Author', key: 'author' },
                { displayName: 'Kind', key: 'kind' },
                { displayName: 'Discovered', key: 'discovered' },
            ]
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                Either the device has not yet completed enrollment or no media has been installed.
            </div>
        );

        const message = <p>
            Please note that inventory data may sometimes be outdated.
            Read more in our <a
            href='https://simplemdm.zendesk.com/hc/en-us/articles/9355267128987'
            target="_blank">
            documentation
        </a>.
        </p>;

        const actions = this.renderActionButton();
        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(InstalledMediaPagedTable, getAjaxClient, undefined, null);