import '../stylesheets/admin.scss'

import '../javascript/shared/libraries'
import '../javascript/admin/form';
// import '../admin/error_logger';
import * as Routes from '../javascript/admin/routes.js.erb';
window.AdminRoutes = Routes;
import '../javascript/shared/mdm'
import '../javascript/admin/turbolinks'
import ReactRailsUJS from 'react_ujs';
const componentRequireContext = require.context("javascript/components", true);
ReactRailsUJS.useContext(componentRequireContext);


