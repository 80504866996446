export const restrictions = {
    show() {
    },
    new() {
        this.show();
    },
    update() {
        this.show();
    },
    edit() {
        this.show();
    }
};