import allSettled from "promise.allsettled";

export const webhookEndpoints = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminWebhookEndpointPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminWebhookEndpointsPath(idOrOptions));
        }
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      AdminRoutes.adminWebhookEndpointPath(id)
        })));
    },

    sendTestEvent() {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: AdminRoutes.testEventAdminWebhookEndpointsPath()
        })
    }

};