import React from 'react';
import PropTypes from 'prop-types';

class SearchBarResult extends React.Component {

    constructor(props) {
        super(props);
    }

    buttonClass() {
        const classes = ['btn', 'btn-default', 'search-bar-result'];
        if (this.props.isSelected) {
            classes.push('selected')
        }
        return classes.join(' ');
    }

    render() {
        return (
            <button
                className={ this.buttonClass() }
                onMouseDown={ this.props.onChooseItem }
                type="button"
            >
                { this.props.children }
            </button>
        )
    }
}

SearchBarResult.propTypes = {
    onChooseItem: PropTypes.func.isRequired,
};

export default SearchBarResult;