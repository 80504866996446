export const directMultipartUpload = {
    create(fileName) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminDirectMultipartUploadPath(),
            data: JSON.stringify({ fileName }),
        });
    },
    createPart(id, key, partNumber) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.partAdminDirectMultipartUploadPath(encodeURIComponent(id), partNumber),
            data: JSON.stringify({ key }),
        });
    },
    destroy(id, key) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.destroyAdminDirectMultipartUploadPath(encodeURIComponent(id)),
            data: JSON.stringify({ key }),
        });
    },
    parts(id, key) {
        return $.getJSON(AdminRoutes.partsAdminDirectMultipartUploadPath(encodeURIComponent(id), { key }))
    },
    complete(id, key, parts) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.completeAdminDirectMultipartUploadPath(encodeURIComponent(id)),
            data: JSON.stringify({ key, parts }),
        });
    },
}
