import React from "react";
import {
  errorHandler,
  getAjaxClient,
  successHandler,
} from "../profiles/profile_utilities";
import { allLoginAndBackgroundItemManagements as ajaxClients } from "../../ajax_clients/profile_data/login_and_background_item_managements";
import { bindMethods, dig } from "../../shared/functions";
import FormGroup from "../forms/form_group";
import DynamicList from "../shared/dynamic_list";
import ProfileScope from "../profiles/profile_scope";

class LoginAndBackgroundItemManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id,
      name: props.data.name || "",
      description: props.data.description || "",
      minimumMacosVersion: props.data.minimumMacosVersion,
      maximumMacosVersion: props.data.maximumMacosVersion,
      allowedMacosArchitecture: props.data.allowedMacosArchitecture,
      isDeviceProfile: props.data.isDeviceProfile,
      allowedPlatforms: props.data.allowedPlatforms,
      reinstallAfterOSUpdate: props.data.reinstallAfterOSUpdate,
      rules: props.data.rules || [],
      saving: false,
      errors: {},
    };
    this.headers = [
      {
        displayName: "Rule Type",
        key: "ruleType",
        options: [
          { displayName: "Bundle Identifier", value: "BundleIdentifier" },
          {
            displayName: "Bundle Identifier Prefix",
            value: "BundleIdentifierPrefix",
          },
          { displayName: "Label", value: "Label" },
          { displayName: "Label Prefix", value: "LabelPrefix" },
          { displayName: "Team Identifier", value: "TeamIdentifier" },
        ],
      },
      { displayName: "Rule Value", key: "ruleValue" },
    ];
    this.ajaxClient = getAjaxClient(props.assignableObject, ajaxClients);
    bindMethods(this);
  }

  onChangeItem(updatedItem, updatedIndex) {
    this.setState({
      rules: this.state.rules.map((row, index) =>
        index === updatedIndex ? updatedItem : row
      ),
    });
  }

  onRemoveItem(_, removedIndex) {
    this.setState({
      rules: this.state.rules.filter((_, index) => index !== removedIndex),
    });
  }

  onAddItem(app = null) {
    let newRule;
    if (app) {
      newRule = {
        ruleType: "BundleIdentifier",
        ruleValue: app.bundleIdentifier,
      };
    } else {
      newRule = {
        ruleType: "BundleIdentifier",
        ruleValue: "",
      };
    }

    this.setState({
      rules: [...this.state.rules, newRule],
    });
  }

  setName(e) {
    this.setState({ name: e.target.value });
  }

  setDescription(e) {
    this.setState({ description: e.target.value });
  }

  onChangeAllowUserOverrides(e) {
    this.setState({ allowUserOverrides: e.target.checked });
  }

  onChangeIsDeviceProfile(e) {
    this.setState({ isDeviceProfile: e.target.checked });
  }

  renderData() {
    return this.state.rules.map((rule) => {
      return {
        ...rule,
        ruleType: rule.ruleType,
        ruleValue: rule.ruleValue,
      };
    });
  }

  saveData(e) {
    e.preventDefault();
    this.setState({ saving: true }, () => {
      const {
        id,
        name,
        description,
        isDeviceProfile,
        allowUserOverrides,
        rules,
        allowedMacosArchitecture,
        reinstallAfterOSUpdate,
      } = this.state;

      const allowedPlatforms = this.state.allowedPlatforms.map(
        (os) => os.displayName
      );
      const minimumMacosVersion = this.state.minimumMacosVersion.version;
      const maximumMacosVersion = this.state.maximumMacosVersion.version;

      let saveData = {
        id,
        name,
        description,
        isDeviceProfile,
        allowUserOverrides,
        rules,
        allowedPlatforms,
        minimumMacosVersion,
        maximumMacosVersion,
        allowedMacosArchitecture,
        reinstallAfterOSUpdate,
      };
      if (id) {
        this.ajaxClient
          .update(id, saveData)
          .then(successHandler(this))
          .catch(errorHandler(this));
      } else {
        this.ajaxClient
          .create(saveData)
          .then(successHandler(this))
          .catch(errorHandler(this));
      }
    });
  }

  selectMinimumOSVersion(minimumMacosVersion) {
    this.setState({ minimumMacosVersion });
  }

  selectMaximumOSVersion(maximumMacosVersion) {
    this.setState({ maximumMacosVersion });
  }

  selectAllowedMacosArchitecture(allowedMacosArchitecture) {
    this.setState({ allowedMacosArchitecture });
  }

  onChangeReinstallAfterOSUpdate(e) {
    this.setState({ reinstallAfterOSUpdate: e.target.checked });
  }

  selectAllowedOs(osName) {
    const oses = this.state.allowedPlatforms;

    if (oses.find((os) => os.name === osName)) {
      this.setState({
        allowedPlatforms: oses.filter((os) => os.name !== osName),
      });
    } else {
      oses.push(
        this.props.supportedPlatformOptions.find(
          (platform) => platform.name === osName
        )
      );
      this.setState({ allowedPlatforms: oses });
    }
  }

  render() {
    const disabled = this.props.data.id
      ? !this.props.policy.update
      : !this.props.policy.create;
    let buttons;
    if (disabled) {
      buttons = (
        <div className="buttons">
          <a
            className="btn btn-default"
            href={AdminRoutes.adminProfilesPath({ format: null })}
          >
            Back
          </a>
        </div>
      );
    } else {
      buttons = (
        <div className="buttons">
          <a
            className="btn btn-default"
            href={AdminRoutes.adminProfilesPath({ format: null })}
          >
            Cancel
          </a>
          <input
            type="submit"
            value="Save"
            disabled={this.state.saving}
            className="btn btn-primary"
            onClick={this.saveData}
          />
        </div>
      );
    }
    return (
      <div className="form form-horizontal">
        <h3>General</h3>
        <FormGroup
          disabled={disabled}
          title="Name"
          error={this.state.errors.name}
          required={true}
        >
          <input value={this.state.name} onChange={this.setName} />
        </FormGroup>

        <FormGroup
          disabled={disabled}
          title={"Description"}
          error={dig(["state", "errors", "description"], this)}
          hint="Optional description presented to the end user."
        >
          <input
            name="description"
            value={this.state.description}
            onChange={this.setDescription}
          />
        </FormGroup>

        <FormGroup disabled={disabled} inputClasses={["checkbox"]}>
          <div>
            <label className="boolean optional">
              <input
                disabled={disabled}
                className="boolean optional"
                type="checkbox"
                name="is_device_profile"
                checked={this.state.isDeviceProfile}
                onChange={this.onChangeIsDeviceProfile}
              />
              For macOS devices, deploy as a device profile instead of a user
              profile.
            </label>
          </div>
        </FormGroup>

        <FormGroup disabled={disabled} inputClasses={["checkbox"]}>
          <div>
            <label className="boolean optional">
              <input
                disabled={disabled}
                className="boolean optional"
                type="checkbox"
                name="reinstall_after_os_update"
                checked={this.state.reinstallAfterOSUpdate}
                onChange={this.onChangeReinstallAfterOSUpdate}
              />
              Re-install profile automatically after macOS software updates are
              detected.
            </label>
          </div>
        </FormGroup>

        <h3>Rules</h3>

        <DynamicList
          policy={this.props.policy}
          fieldName={"data[rules]"}
          headers={this.headers}
          rows={this.renderData()}
          onChangeItem={this.onChangeItem}
          onRemoveItem={this.onRemoveItem}
          onAddItem={this.onAddItem}
        />

        <ProfileScope
          policy={this.props.policy}
          selectMinVersion={this.selectMinimumOSVersion}
          selectMaxVersion={this.selectMaximumOSVersion}
          selectAllowedMacosArchitecture={this.selectAllowedMacosArchitecture}
          selectAllowedOs={this.selectAllowedOs}
          minVersion={this.state.minimumMacosVersion}
          maxVersion={this.state.maximumMacosVersion}
          allowedArchitecture={this.state.allowedMacosArchitecture}
          minimumMacosVersions={this.props.minimumMacosVersions}
          maximumMacosVersions={this.props.maximumMacosVersions}
          allowedMacosArchitectures={this.props.allowedMacosArchitectures}
          allowedPlatforms={this.state.allowedPlatforms}
          supportedPlatformOptions={this.props.supportedPlatformOptions}
          supportedPlatforms={this.props.supportedPlatforms}
          errors={this.state.errors}
        />

        {buttons}
      </div>
    );
  }
}

export default LoginAndBackgroundItemManagement;
