import React from 'react';
import { deviceUsers as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, boolToString, callWithIds } from '../../shared/functions';


const getAjaxClient = ({ deviceId }) => {
    const callWithDeviceIdAndIds = (func) => {
        return (deviceUsers) => callWithIds(deviceUsers, (ids) => func(deviceId, ids));
    };

    return {
        read:            (query) => ajaxClient.read(deviceId, query),
        destroyMultiple: callWithDeviceIdAndIds(ajaxClient.destroyMultiple),
        unlockMultiple: callWithDeviceIdAndIds(ajaxClient.unlockMultiple)
    }
}

class DeviceUsersPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeUsers,
                text:   "Delete",
                policy: 'destroy'
            },
            {
                href: "#unlock",
                action: this.unlockUsers,
                text: "Unlock User(s)",
                policy: 'unlockUserAccounts'
            }
        ];
        return <div className="button-list-container">
            <ActionDropDown text="Actions"
                            items={ items }
                            policy={ this.props.policy }
                            disabled={ !this.props.hasCheckedItems() }/>
        </div>
    }

    removeUsers(e) {
        this.props.removeItems(e, "This will delete the user account(s) from the device. Are you sure?");
    }

    unlockUsers(e) {
        e.preventDefault();
        if(confirm("This will unlock the user account when locked out due to too many failed password attempts. Are you sure?")){
            const ids = this.props.checkedItems().map(item => item.id)
            ajaxClient.unlockMultiple(this.props.deviceId, ids)
        }
    }

    pagedTableProps() {
        const props = {
            headers:   [
                           { displayName: 'User Name', key: 'username', policy: 'showUsername' },
                           { displayName: 'Full Name', key: 'fullName', policy: 'showFullName' },
                           { displayName: 'UID', key: 'uid', policy: 'showUid' },
                           { displayName: 'User GUID', key: 'userGuid', policy: 'showUserGuid' },
                           { displayName: 'Secure Token?', key: 'secureToken', policy: 'showSecureToken' },
                           { displayName: 'Mobile Account?', key: 'mobileAccount', policy: 'showMobileAccount' },
                           { displayName: 'Logged In?', key: 'loggedIn', policy: 'showLoggedIn' },
                           { displayName: 'Data Synced?', key: 'dataSynced', policy: 'showDataSynced' },
                           { displayName: 'Data Quota', key: 'dataQuota', policy: 'showDataQuota' },
                           { displayName: 'Data Used', key: 'dataUsed', policy: 'showDataUsed' },
                           { displayName: 'Auto Admin', key: 'depCreated', policy: 'showAutoAdminUser' },
                       ].filter(header => this.props.policy[header.policy]),
            renderRow: (row) => {
                return {
                    ...row,
                    secureToken:   boolToString(row.secureToken),
                    mobileAccount: boolToString(row.mobileAccount),
                    loggedIn:      boolToString(row.loggedIn),
                    dataSynced:    boolToString(row.dataSynced),
                    depCreated:    boolToString(row.depCreated),
                }
            }
        };

        if (!this.props.policy.edit) {
            props.showCheck = false;
        }

        return props
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No users found. The device may not have yet checked in with user information.
            </div>
        );

        let message = <p>Please note that inventory data may sometimes be outdated.
            Read more in our <a
                href="https://simplemdm.zendesk.com/hc/en-us/articles/9355267128987"
                target="_blank">documentation</a>.</p>;
        let actions = this.renderActionButton();

        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(DeviceUsersPagedTable, getAjaxClient, undefined, null);