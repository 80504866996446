export const deviceInstalledApplications = {
    read(deviceId, options) {
        return $.getJSON(AdminRoutes.adminDeviceInstalledApplicationsPath(deviceId, options));
    },

    destroyMultiple(deviceId, ids) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.destroyMultipleAdminDeviceInstalledApplicationsPath(deviceId),
            data: JSON.stringify({ ids })
        })
    },

    updateMultiple(deviceId, ids) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.updateMultipleAdminDeviceInstalledApplicationsPath(deviceId),
            data: JSON.stringify({ ids })
        })
    },

    manageMultiple(deviceId, ids) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.manageMultipleAdminDeviceInstalledApplicationsPath(deviceId),
            data: JSON.stringify({ ids })
        })
    },
};
