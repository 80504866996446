import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class ManagedConfigs extends React.Component {

    constructor(props) {
        super(props);
        this.state        = {
            data: props.data,
        };
        this.headers      = [
            { displayName: 'Key', key: 'key' },
            {
                displayName: 'Value Type',
                key:         'valueType',
                options:     [
                                 'boolean',
                                 'date',
                                 'float',
                                 'float array',
                                 'integer',
                                 'integer array',
                                 'string',
                                 'string array',
                             ].map(type => ({ displayName: type, value: type }))
            },
            { displayName: 'Value', key: 'value' },
        ];
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem    = this.onAddItem.bind(this);
        this.renderInput  = this.renderInput.bind(this);
    }

    onChangeItem(updatedItem, updatedIndex) {
        this.setState({ data: this.state.data.map((row, index) => index === updatedIndex ? updatedItem : row) });
    }

    onRemoveItem(removedItem, removedIndex) {
        if (removedItem.id) {
            this.onChangeItem({ ...removedItem, _deleted: true }, removedIndex);
        } else {
            this.setState({ data: this.state.data.filter((item, index) => index !== removedIndex) });
        }
    }

    renderInput(input, header, row, rowNum, _onChangeItem, columnName) {
        if (header.key === 'value' && row.valueType === 'boolean') {
            const checkBoxOnChangeItem = (e) => {
                const newValue    = e.target.checked;
                const updatedItem = { ...row, [header.key]: newValue };
                this.onChangeItem(updatedItem, rowNum)
            };

            let checked;
            if (typeof row.value === 'string') {
                const noSpaces = row.value.replace(/\s+/g, '');
                checked        = noSpaces !== '' && noSpaces !== "false" && noSpaces !== "0";
            } else {
                checked = !!row.value;
            }

            return (
                <React.Fragment>
                    <input type="hidden" value="0" name={ columnName }/>
                    <input
                        type="checkbox"
                        className="form-control value"
                        checked={ checked }
                        value="1"
                        name={ columnName }
                        onChange={ checkBoxOnChangeItem }
                    />
                </React.Fragment>
            )
        }
        return input;
    }

    onAddItem() {
        const newItem = {
            key:        "",
            valueType: "string",
            value:      ""
        };

        this.setState({ data: [...this.state.data, newItem] });
    }

    render() {
        return <DynamicList
            fieldName={ 'app[managed_configs_attributes]' }
            headers={ this.headers }
            rows={ this.state.data }
            policy={ this.props.policy }
            renderInput={ this.renderInput }
            onChangeItem={ this.onChangeItem }
            onRemoveItem={ this.onRemoveItem }
            onAddItem={ this.onAddItem }
        />
    }
}