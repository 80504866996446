import React from 'react';
import AssignmentGroup from "../assignment_group";
import * as ajaxClients from '../../../ajax_clients';

export default class AssignmentGroupAssignedDevices extends React.Component {

    constructor(props) {
        super(props);
        this.unassignDevice  = this.unassignDevice.bind(this);
        this.unassignGroup   = this.unassignGroup.bind(this);
        this.groupRow        = this.groupRow.bind(this);
        this.deviceRow       = this.deviceRow.bind(this);
        this.assignedDevices = this.assignedDevices.bind(this);
        this.assignedGroups  = this.assignedGroups.bind(this);
    }

    unassignDevice(device, e) {
        e.preventDefault();
        this.props.updateAssignmentGroup(AssignmentGroup.quickDelete(this.props.assignmentGroup, 'deviceIds', device.id));
        ajaxClients.assignmentGroups.unassignDevice(this.props.assignmentGroup.id, device.id)
            .then(this.props.updateAssignmentGroup);
    }

    unassignGroup(group, e) {
        e.preventDefault();
        this.props.updateAssignmentGroup(AssignmentGroup.quickDelete(this.props.assignmentGroup, 'groupIds', group.id));
        ajaxClients.assignmentGroups.unassignGroup(this.props.assignmentGroup.id, group.id)
            .then(this.props.updateAssignmentGroup);
    }

    assignedDevices() {
        return this.props.devices.filter((device) => {
            return this.props.assignmentGroup.deviceIds.includes(device.id);
        })
    }

    assignedGroups() {
        return this.props.devices.filter((group) => {
            return this.props.assignmentGroup.groupIds.includes(group.id);
        })
    }

    deviceRow(device) {
        let removeLink;
        if (this.props.assignmentGroup.permissions.removeDevice) {
            removeLink = (
                <a className="remove-link" href="#" onClick={ (e) => this.unassignDevice(device, e) }>
                    <i className="glyphicon glyphicon-remove"/>
                </a>
            )
        }
        return (
            <tr key={ `device-${ device.id }` }>
                <td>{ removeLink }</td>
                <td className="title">
                    <a href={ AdminRoutes.adminDevicePath(device.id, {format: null}) }>
                        { device.name }
                    </a>
                </td>
                <td className="metadata">device</td>
            </tr>
        )
    }

    groupRow(group) {
        let removeLink;
        if (this.props.assignmentGroup.permissions.removeGroup) {
            removeLink = (
                <a className="remove-link" href="#" onClick={ (e) => this.unassignGroup(group, e) }>
                    <i className="glyphicon glyphicon-remove"/>
                </a>
            )
        }
        return (
            <tr key={ `group-${ group.id }` }>
                <td>{ removeLink }</td>
                <td className="title">
                    <a href={ AdminRoutes.adminGroupPath(group.id, {format: null}) }>
                        { group.name }
                    </a>
                </td>
                <td className="metadata">group</td>
            </tr>
        )
    }

    render() {
        const groups  = this.props.assignmentGroup.groups;
        const devices = this.props.assignmentGroup.devices;
        if (!this.props.allDataLoaded) {
            return <em>loading groups and devices...</em>;
        } else if (groups.length === 0 && devices.length === 0) {
            return <em>no groups or devices</em>;
        }
        return (
            <table className="assets-or-devices">
                <tbody>
                { groups.map(this.groupRow) }
                { devices.map(this.deviceRow) }
                </tbody>
            </table>
        )
    }
}