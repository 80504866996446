import React from 'react'

const FormGroup = (props) => {
    let groupClasses = [...props.groupClasses]
    let labelClasses = [...props.labelClasses]
    let inputClasses = [...props.inputClasses]

    let hints = []
    if (props.hint) {
        hints.push(<span key={ props.hint } className="help-block">{ props.hint }</span>)
    }

    if (props.error) {
        hints.push(<span key={ props.error } className="help-block">{ props.error }</span>)
        groupClasses.push('has-error')
    }

    if (props.disabled) {
        labelClasses.push('disabled')
        inputClasses.push('disabled')
    }

    let required
    if (props.required) {
        required = <abbr title="required">*</abbr>
        labelClasses.push('required')
        groupClasses.push('required')
        inputClasses.push('required')
    }

    if (props.children.props.className) {
        inputClasses.push(props.className)
    }

    const newProps = { className: inputClasses.join(' ') }
    if (props.disabled) {
        newProps.disabled = props.disabled
    }

    if (props.error) {
        newProps['aria-invalid'] = props.error
    }

    let inputWrapperClasses = [...props.inputWrapperClasses]
    const clonedChild  = React.cloneElement(props.children, newProps)
    let title
    if (props.title) {
        title = <label className={ labelClasses.join(' ') }>
            { required } { props.title }
        </label>
    } else {
        inputWrapperClasses.push('col-sm-offset-2')
    }
    return (
        <div className={ groupClasses.join(' ') }>
            { title }
            <div className={ inputWrapperClasses.join(' ') }>
                { clonedChild }
                { hints }
            </div>
        </div>
    )
}

FormGroup.defaultProps = {
    title:        "",
    error:        null,
    required:     false,
    groupClasses: ['form-group', 'row'],
    inputClasses: ['form-control'],
    labelClasses: ['col-sm-2', 'control-label', 'string'],
    inputWrapperClasses: ['col-sm-10']
}

export default FormGroup