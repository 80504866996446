import React from 'react'
import pagedTableLayout from '../paged_table/layout'
import { bindMethods, isEmpty } from '../../shared/functions'
import { billing as ajaxClient } from '../../ajax_clients/billing'

const getAjaxClient = () => ajaxClient;
const initialQuery  = { before: null, after: null, per: 12 };

// The Stripe API returns invoices depending on whether they're before or after the requested ID
const beforePageChange = (page, per, pagination) => {
  const { prevPage, nextPage } = pagination
  // Changing the per page doesn't work well with the Stripe API if they've already moved through the pages. So reset to page 1
  if (per != pagination.per) {
    return { before: null, after: null, per }
  } else if (page == prevPage) {
    return { before: page, after: null, per }
  } else if (page == nextPage) {
    return { after: page, before: null, per }
  } else {
    return { page, per }
  }
}

class InvoicesPagedTable extends React.Component {

  constructor(props) {
    super(props)
    bindMethods(this)
  }

  pagedTableProps() {
    return {
        headers:   [
            { displayName: "Date", key: "date" },
            { displayName: "Amount", key: "amount" }
        ],
        showCheck: false,
        perOptions: [12, 24, 36],
        renderRow: (row) => {
            const date = (
                <a href={ AdminRoutes.adminReceiptPath(row.number, {format: null}) }>{ row.date }</a>
            )
            return Object.assign({}, row, { date });
        }
    };
  }

  render() {
    return (
      this.props.renderPagedTable(this.pagedTableProps())
    )
  }
}

export default pagedTableLayout(InvoicesPagedTable, getAjaxClient, initialQuery, undefined, undefined, beforePageChange)