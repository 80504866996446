const notifications =  {
    fn: {
        wireButton() {
            // load notifications when dropdown opens
            $('.button-notification a.dropdown-toggle').on('click', function () {
                if (!$(this).parents('.dropdown').is('.open')) {
                    // user is opening window
                    $('.notification-container .count').hide();
                    $.ajax({ url: '/admin/notifications.js' });
                }
            });
        },
        fetchNotifications() {
            if ($('.notification-container').length) {
                $('.turbolinks-progress-bar').hide();
                $.ajax({ url: '/admin/notifications/update_badge.js' }, function() {
                    $('.turbolinks-progress-bar').show();
                });
            }
            setTimeout(notifications.fn.fetchNotifications, 60000);
        }
    }
};

export default notifications;