import { media as ajaxClient } from '../../ajax_clients'
import DirectUploadUppy from "../shared/direct_upload_uppy";
import React from 'react';

const DirectUploader = () => {

    const allowedFileTypes = ['.pdf', '.epub'];

    const successHandler = (file) => {
        return ajaxClient.directUploadMediaCallback(
            file.data.name,
            file.simpleMDMData.uuid,
        );
    }

    return <>
        <DirectUploadUppy
            id="media-uploader"
            allowedFileTypes={ allowedFileTypes }
            maxNumberOfFiles={ 10 }
            successHandler={ successHandler }
            humanNamePlural='media'
        />
    </>
}

export default DirectUploader