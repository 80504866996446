import React from 'react';
import PropTypes from 'prop-types';

export default class ActionDropDown extends React.Component {
    static generateId() {
        return `action-drop-down-${ MDM.fn.generateId() }`
    }

    static renderItem(item, index) {
        const href = item.href || "#";
        return (
            <li role="presentation" key={ item.text + item.tooltip }>
                <a href={ href }
                   role="menuitem"
                   rel="tooltip"
                   title={ item.tooltip }
                   onClick={ item.action }
                >
                    { item.text }
                </a>
            </li>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            id: ActionDropDown.generateId()
        };

        this.filterByPolicy = this.filterByPolicy.bind(this);
        this.renderItems    = this.renderItems.bind(this);
    }

    filterByPolicy(items) {
        if (!this.props.policy) {
            return items;
        }
        return items.filter(item => !item.policy || this.props.policy[item.policy]);
    }

    renderItems(filteredItems) {
        if (filteredItems.some((item) => item.afterDivider)) {
            const beforeDivider = filteredItems.filter((item) => !item.afterDivider).map(ActionDropDown.renderItem);
            const afterDivider  = filteredItems.filter((item) => item.afterDivider).map(ActionDropDown.renderItem);
            return [
                ...beforeDivider,
                <li key="divider" className="divider"/>,
                ...afterDivider
            ]
        } else {
            return filteredItems.map(ActionDropDown.renderItem);
        }
    }

    render() {
        const classes       = ["btn-group", "action-drop-down"].concat(this.props.additionalClasses).join(' ');
        const filteredItems = this.filterByPolicy(this.props.items);
        const renderedItems = this.renderItems(filteredItems);

        return (
            <div className={ classes }>
                <button
                    className="dropdown-toggle btn btn-default"
                    id={ this.state.id }
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={ this.props.disabled || filteredItems.length === 0 }
                >
                    { this.props.text }
                    &nbsp;
                    <span className="caret"/>
                </button>
                <ul aria-labelledby={ this.props.id } className={ this.props.ulClasses.join(' ') }>
                    { renderedItems }
                </ul>
            </div>
        )
    }
}

ActionDropDown.propTypes = {
    text:              PropTypes.string.isRequired,
    items:             PropTypes.arrayOf(
        PropTypes.shape({
            href:         PropTypes.string,
            action:       PropTypes.func,
            text:         PropTypes.string.isRequired,
            tooltip:      PropTypes.string,
            afterDivider: PropTypes.bool
        })
    ).isRequired,
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
    ulClasses:         PropTypes.arrayOf(PropTypes.string),
    disabled:          PropTypes.bool.isRequired,
    policy:            PropTypes.object
};

ActionDropDown.defaultProps = {
    disabled:          false,
    additionalClasses: [],
    ulClasses:         ["dropdown-menu", "dropdown-menu-right"],
};