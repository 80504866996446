import { hide, show } from '../../shared/functions'
const wireAndUpdateForm = () => {

    const radioFields       = Array.from(document.querySelectorAll('[name="software_update_policy_macos[mode]"]'));
    const allowedDeferrals  = document.querySelector('.form-group.software_update_policy_macos_allowed_deferrals')
    const updateRadioFields = () => {
        const checkedField = radioFields.find(field => field.checked)
        if (checkedField.value === 'smart_update') {
            show(allowedDeferrals)
        } else {
            hide(allowedDeferrals)
        }
    };

    radioFields.forEach(field => field.addEventListener('change', updateRadioFields))
    updateRadioFields();
}

export const software_update_policy_macos = {
    new:    function() {
        wireAndUpdateForm();
    },
    show:   function() {
        wireAndUpdateForm();
    },
    create: function() {
        wireAndUpdateForm();
    },
    update: function() {
        wireAndUpdateForm();
    }
};
