import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { useTheme } from "@mui/material/styles"
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui'
import ReCAPTCHA from "react-google-recaptcha"
import { Controller, useForm } from "react-hook-form"
import { ThemeProvider }  from '../shared/simplemdm_theme'
import { users as ajaxClient } from '../../ajax_clients/users'
import Button from './button'
import Container from './container'
import Header from './header'
import { useRailsErrorHandler } from "./hooks"
import Paper, { borderRadius as paperBorderRadius, padding as paperPadding } from './paper'

const CheckedListItem = ({children, ...otherProps}) => (
  <ListItem disablePadding sx={{alignItems:'baseline', paddingTop: 1}} {...otherProps}>
    <ListItemAvatar sx={{minWidth: '40px'}}>
      <img src="/check-circle.svg"/>
    </ListItemAvatar>
    <ListItemText primary={children} />
  </ListItem>
)

const ReCaptchaElement = ({ field, fieldState: {error}, sitekey, ...otherProps }) => {
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (recaptchaRef?.current && typeof error !== 'undefined') {
      recaptchaRef.current.reset()
    }
  }, [error, recaptchaRef])

  return (
      <Box { ...otherProps }>
        <ReCAPTCHA ref={ recaptchaRef } sitekey={ sitekey } onChange={ field.onChange }/>
        <FormHelperText error
                        sx={ { marginLeft: '14px' } }>{ error && (error.type === 'required' ? 'This field is required' : error.message) }</FormHelperText>
      </Box>
  )
}

const ReCaptchaController = (props) => {
  const {control, required, sitekey, clearErrors, ...otherProps} = props

  const render = ({ field, fieldState }) => {
    return (
      <ReCaptchaElement
        field={field}
        fieldState={fieldState}
        sitekey={sitekey}
         {...otherProps}
      />
    )
  }

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{required: required}}
      render={render}
    />
  )
}

function NewUser(props) {
  const theme = useTheme()
  const { recaptchaSiteKey, user } = props
  const [isLoading, setIsLoading] = useState(false)
  const formContext = useForm({defaultValues: {email: user.email, password: ''}})
  const { setError } = formContext
  const handleRailsError = useRailsErrorHandler(setError)

  const onSuccess = useCallback(async ({password, reCaptcha}) => {
    setIsLoading(true)

    try {
      await ajaxClient.create(user, password, reCaptcha)
    } catch(e) {
      handleRailsError(e)
    }

    setIsLoading(false)
  }, [handleRailsError, setIsLoading, user])

  return (
      <Container>
        <Grid item>
          <Paper sx={{padding: 0}}>
            <Grid columns={16} container maxWidth={{ xs: 450, sm: 850 }}>
              <Grid item padding={paperPadding} xs={16} sm={8}>
                <FormContainer formContext={formContext} onSuccess={onSuccess}>
                  <Grid container direction='column' justifyContent="center" alignItems="center">
                    <Header>Create an account</Header>
                    <p style={{paddingBottom: 10, textAlign: 'center'}}>Set your password to finalize your registration an start your trial.</p>
                    <TextFieldElement
                      disabled
                      id="email"
                      label="Email"
                      name="email"
                      sx={{ paddingBottom: 1, width: "100%" }}
                      variant="outlined"
                    />
                    <TextFieldElement
                      id="password"
                      label="Password"
                      name="password"
                      placeholder="Create a password"
                      required
                      sx={{ paddingBottom: 2, width: "100%" }}
                      type='password'
                      variant="outlined"
                    />
                    <ReCaptchaController
                      control={formContext.control}
                      clearErrors={formContext.clearErrors}
                      label='Verification'
                      name='reCaptcha'
                      required
                      sitekey={recaptchaSiteKey}
                      width='100%'
                    />
                    <Button
                      disabled={isLoading}
                      fullWidth
                      sx={{marginTop: 1}}
                      type="submit"
                      variant="contained">
                      Finalize Registration
                    </Button>
                  </Grid>
                </FormContainer>
              </Grid>
              <Grid item backgroundColor={theme.palette.primary.main} color='white' padding={paperPadding} xs={16} sm={8} >
                <Box paddingTop={{ xs: '8px', sm: paperPadding }} margin={1} fontWeight={700}>Included in your trial:</Box>
                <List disablePadding sx={{paddingBottom: 5}}>
                  <CheckedListItem>Free for 30 days</CheckedListItem>
                  <CheckedListItem>No credit card required</CheckedListItem>
                  <CheckedListItem>Unlimited devices, unrestricted functionality</CheckedListItem>
                  <CheckedListItem>Cloud based, no equipment or installation required</CheckedListItem>
                  <CheckedListItem>Responsive, expert technical support</CheckedListItem>
                </List>
                <Box backgroundColor="#1B1E21" borderRadius={paperBorderRadius} padding="15px" fontWeight={700}>
                  <p>&ldquo;We wanted to have a robust MDM that would just react very quickly. And that was basically the selling point for us with SimpleMDM.&rdquo;</p>
                  <p>&ndash; DAVID KRAUSHUBER, SR. SYSTEMS ADMINISTRATOR, FREE NOW</p>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
  )
}

export default (props) => <ThemeProvider typography={{fontFamily:'"Inter", "Helvetica", "Arial", sans-serif'}}><NewUser {...props}/></ThemeProvider>
