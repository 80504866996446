import selectionActionButton from '../admin/selection_action_button';

let currentId = 0;

export const ready = (callback) => {
    if (document.readyState !== 'loading') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
};

export const removeOldFlashes = () => {
    $('.flash > div').fadeOut('fast', function() {
        $(this).remove();
    });
};

export const preloadImages = (imageSrcArray) => {
    $.each(imageSrcArray, function(index, imgSrc) {
        const img = new Image();
        img.src   = imgSrc;
    });
};

export const selectTab = (tab) => {
    if ($('.nav-tabs').length > 0) {
        if (window.location.hash.length > 0) {
            const anchor = window.location.hash.substr(1);
            tab          = $('.nav-tabs a[href="#' + anchor + '"]');
        } else {
            tab = $('.nav-tabs a:first');
        }
        tab.tab('show');
        setTimeout(function() {
            window.scrollTo(0, 0);
        });
    }
};

export const wireImagePreview = () => {
    $('.image-preview-file-button input').on('change', function() {

        let filename        = null;
        const filenameMatch = $(this).val().match(/\\([^\\]+)$/);

        if (filenameMatch) {
            filename = filenameMatch.slice(-1)[0];
        }

        $(this).parent().siblings('.image-preview-filename').html(filename);
    });
};

// DEPRECATED: use toggleDOMElement instead
export const toggleElement = (toggle, element) => {
    const inputSelector = 'input, select, textarea';
    const input = $(element.filter(inputSelector), element.children(inputSelector));
    toggle.change(function() {
        // radio buttons will always be checked but we have one "true" and one "false" radio button
        if (this.checked && this.value !== "false") {
            element.show();
            input.prop('disabled', false);
        } else {
            element.hide();
            input.prop('disabled', true);
        }
    });
    toggle.trigger('change');
};

// This is a replacement for toggleElement that doesn't use jQuery
export const toggleDOMElement = (toggle, element) => {
    const inputSelector = 'input, select, textarea';
    const input = element.querySelectorAll(inputSelector) || element.children.querySelectorAll(inputSelector);
    toggle.addEventListener('change', function() {
        // radio buttons will always be checked but we have one "true" and one "false" radio button
        if (this.checked && this.value !== "false") {
            element.style.display = "block";
            input.disabled = false;
        } else {
            element.style.display = "none";
            input.disabled = true;
        }
    });
    toggle.dispatchEvent(new Event('change'));
};

export const updateCheckboxShadowElement = ($element) => {
    $element.change(function() {
        const escapedName    = $element.attr('name')
            .replace('[', '\\[')
            .replace(']', '\\]')
        const $hiddenElement = $("input[type=hidden][name=" + escapedName + "]");
        $hiddenElement.val($element.is(':checked'));
    });
};

export const generateId = () => {
    currentId += 1;
    return currentId;
};

export const truncate = (string, length = 50) => {
    if (!string) {
        return ""
    }

    if (string.length > length) {
        return string.substring(0, length - 3) + '...';
    } else {
        return string;
    }
};

const selfBindReservedMethods = ['setState', 'replaceState', 'render', 'constructor'];
export const bindMethods      = (instance) => {
    Reflect.ownKeys(Reflect.getPrototypeOf(instance)).forEach(propertyName => {
        if (typeof instance[propertyName] === 'function' && !selfBindReservedMethods.some(method => method === propertyName)) {
            instance[propertyName] = instance[propertyName].bind(instance);
        }
    });
};
export const callWithIds      = (collection, func) => (func(collection.map(item => item.id)));

export const snakeToCamel = (string) => {
    return string.replace(/(_\w)/g, function(m) {
        return m[1].toUpperCase();
    });
};

export const camelToSnake = (string) => {
    return string.replace(/[\w]([A-Z])/g, function(m) {
        return m[0] + "_" + m[1];
    }).toLowerCase();
};

export const dig = (path, object) => {
    return path.reduce((accumulator, value) => {
        return accumulator && accumulator[value] ? accumulator[value] : null
    }, object)
}

// meant to be used with an array's .filter method
export const filterUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const hide = (element) => {
    if (element !== null) {
        if (typeof element.forEach === 'function') {
            element.forEach(hide)
        } else {
            element.classList.add('hidden')
        }
    }
}
export const show = (element) => {
    if (element !== null) {
        if (typeof element.forEach === 'function') {
            element.forEach(show)
        } else if (element !== null) {
            element.classList.remove('hidden')
        }
    }
}

export const isEmpty = (object) => {
    if (typeof object === 'undefined' || object === null) {
        return true;
    }
    return Object.keys(object).length === 0;
}

export const sortStrings = (stringA, stringB) => {
    const stringAUpper = stringA.toUpperCase();
    const stringBUpper = stringB.toUpperCase();
    if (stringAUpper < stringBUpper) {
        return -1;
    }
    if (stringAUpper > stringBUpper) {
        return 1;
    }
    return 0;
}

export const escapeRegex  = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
export const boolToString = bool => {
    if (typeof bool === 'boolean') {
        return bool ? "Yes" : "No";
    } else {
        return bool;
    }
}

// Javascript implementation of RouteStatusService#v2_route
export const v2Route = (path, query = {}) => {
    const baseUrl = window.location.origin
    const url = new URL(`/admin/v2/${path}`, baseUrl)

    if (Object.keys(query).length > 0) {
        const searchParams = new URLSearchParams(query)
        url.search = searchParams.toString()
    }
    return url.toString()
}


export default {
    boolToString,
    bindMethods,
    callWithIds,
    camelToSnake,
    dig,
    escapeRegex,
    filterUnique,
    generateId,
    hide,
    isEmpty,
    show,
    preloadImages,
    ready,
    removeOldFlashes,
    selectTab,
    selectionActionButton,
    snakeToCamel,
    sortStrings,
    toggleElement,
    toggleDOMElement,
    truncate,
    updateCheckboxShadowElement,
    wireImagePreview,
};
