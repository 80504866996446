import allSettled from 'promise.allsettled';

export const userPermissions = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminUserPermissionPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminUserPermissionsPath(idOrOptions));
        }
    },

    update(id, userPermission) {
        return $.ajax({
            method: 'PATCH',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminUserPermissionPath(id),
            data: JSON.stringify({
                userPermission: userPermission
            })
        });
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id =>  $.ajax({
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminUserPermissionPath(id)
        })));
    },
};