import React from "react";
import PropTypes from 'prop-types';
import { devices as ajaxClient } from '../../ajax_clients/devices'
import { bindMethods } from "../../shared/functions";
import FormGroup from "../forms/form_group";

export default class AssignGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            groupId: props.groups.length > 0 ? props.groups[0].id : null,
        }
        bindMethods(this);
    }

    selectGroup(e) {
        this.setState({ groupId: e.target.value })
    }

    cancel(e) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    submit(e) {
        e.preventDefault();
        ajaxClient.assignToGroup(this.props.deviceIds, this.state.groupId).then(() => {
            if (this.props.onAssignGroup) {
                this.props.onAssignGroup()
            }
        }).catch(({ responseJSON }) => {
            if (responseJSON.error) {
                addFlashAlert('error', responseJSON.error.message)
                if (responseJSON.error.modelErrors && responseJSON.error.modelErrors.group_id) {
                    this.setState({ error: responseJSON.error.modelErrors.group_id[0] })
                }
            }

            if (this.props.onError) {
                this.props.onError(responseJSON.error)
            }
        })
    }

    renderGroups() {
        return this.props.groups.filter(group => group.name !== 'Quarantine').map(group => {
            return <option key={ group.id } value={ group.id }>{ group.name } </option>
        })
    }

    render() {

        return <div className="assign-group multi-select-panel">
            <h1>Group Assignment</h1>

            <div className="well">
                <form>
                    <FormGroup title="Group" error={ this.state.error }>
                        <select onChange={ this.selectGroup }>
                            { this.renderGroups() }
                        </select>
                    </FormGroup>

                    <div className="buttons">
                        <a href="#cancel" className="btn btn-default" onClick={ this.cancel }>Cancel</a>
                        <a href="#submit" className="btn btn-primary" onClick={ this.submit }>Assign</a>
                    </div>
                </form>
            </div>
        </div>
    }
}

AssignGroup.propTypes = {
    groups:    PropTypes.arrayOf(PropTypes.object).isRequired,
    deviceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAssignGroup: PropTypes.func.isRequired,
    onCancel:  PropTypes.func.isRequired,
    onError:   PropTypes.func,
}

