import allSettled from 'promise.allsettled';
import React from 'react';
import AssignmentGroup from "../assignment_group";
import * as ajaxClients from '../../../ajax_clients';
import { bindMethods, sortStrings, truncate } from '../../../shared/functions'
import ActionDropDown from "../../shared/action_drop_down";
import { renderCompatibilityLabel } from '../../catalog/apps_paged_table'

export default class AssignmentGroupAssignedAssets extends React.Component {
    static renderAvailabilityLabel(asset) {
        return (
            <td className="licensing metadata">
                { asset.availability }
            </td>
        )
    }

    static renderVersionString(asset) {
        let version            = asset.version;
        const startsWithNumber = /^\d/;
        if (typeof version === 'string' && startsWithNumber.test(version)) {
            return `v${ version }`
        }
        return version || "";
    }

    static renderVersion(asset) {
        return (
            <td className="version metadata">
                <span className="only-version">{ this.renderVersionString(asset) }</span>
            </td>
        )
    }

    static renderPlatformSupport(asset) {
        return (
            <td className="platform metadata">
                { asset.platformSupport }
            </td>
        )
    }

    static renderIcon(asset) {
        return (
            <td className="icon">
                <span className="img-holder">
                    <img src={ asset.iconUrl }/>
                </span>
            </td>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            replacing: false
        }
        bindMethods(this);
    }

    unassignApp(app, e) {
        e.preventDefault();
        this.props.updateAssignmentGroup(AssignmentGroup.quickDelete(this.props.assignmentGroup, 'appIds', app.id));
        ajaxClients.assignmentGroups.unassignApp(this.props.assignmentGroup.id, app.id)
            .then(this.props.updateAssignmentGroup);
    }

    unassignMedia(media, e) {
        e.preventDefault();
        this.props.updateAssignmentGroup(AssignmentGroup.quickDelete(this.props.assignmentGroup, 'mediaIds', media.id));
        ajaxClients.assignmentGroups.unassignMedia(this.props.assignmentGroup.id, media.id)
            .then(this.props.updateAssignmentGroup);
    }

    replaceAppVersion(currentAppVersion, newAppVersion, e) {
        e.preventDefault();
        if (!this.state.replacing) {
            this.setState({ replacing: true }, () => {
                allSettled([
                    ajaxClients.assignmentGroups.unassignApp(this.props.assignmentGroup.id, currentAppVersion.id),
                    ajaxClients.assignmentGroups.assignApp(this.props.assignmentGroup.id, newAppVersion.id)
                ]).then(() => ajaxClients.assignmentGroups.read(this.props.assignmentGroup.id))
                    .then(data => {
                        this.setState({ replacing: false })
                        return this.props.updateAssignmentGroup(data);
                    })
            })
        }
    }

    renderVersionSelect(app) {
        const apps = app.appGroup.apps;

        const items = apps.map(otherApp => {
            const clickHandler  = (e) => {
                e.preventDefault()
                this.replaceAppVersion(app, otherApp, e)
            }
            const versionString = this.constructor.renderVersionString(otherApp)
            return {
                href:   `#${ versionString }`,
                action: clickHandler,
                text:   versionString,
            }
        })
        return (
            <td className="version metadata">
                <ActionDropDown text={ this.constructor.renderVersionString(app) } items={ items }/>
            </td>
        )
    }

    renderApp(app) {
        let removeLink;
        if (this.props.assignmentGroup.permissions.removeMedia) {
            removeLink = (
                <a className="remove-link" href="#" onClick={ (e) => this.unassignApp(app, e) }>
                    <span className="glyphicon glyphicon-remove"/>
                </a>
            )
        }
        let version;
        if (app.appGroup && app.appGroup.apps.length > 1) {
            version = this.renderVersionSelect(app)
        } else {
            version = AssignmentGroupAssignedAssets.renderVersion(app)
        }

        return (
            <tr key={ `${ app.id }-app` }>
                <td>{ removeLink }</td>

                { AssignmentGroupAssignedAssets.renderIcon(app) }

                <td className="title title-elipses">
                    <a href={ AdminRoutes.adminAppPath(app.id, {format: null}) }>{ truncate(app.name, 27) }</a>
                </td>

                <td>{ renderCompatibilityLabel(app) }</td>
                { version }
                { AssignmentGroupAssignedAssets.renderPlatformSupport(app) }
                { AssignmentGroupAssignedAssets.renderAvailabilityLabel(app) }

            </tr>
        )
    }

    renderMedia(media) {
        let removeLink;
        if (this.props.assignmentGroup.permissions.removeMedia) {
            removeLink = (
                <a className="remove-link" href="#" onClick={ (e) => this.unassignMedia(media, e) }>
                    <span className="glyphicon glyphicon-remove"/>
                </a>
            )
        }

        return (
            <tr key={ `${ media.id }-media` }>
                <td>{ removeLink }</td>

                { AssignmentGroupAssignedAssets.renderIcon(media) }

                <td className="title">
                    <div className="title-elipses">
                        <a href={ AdminRoutes.adminMediaPath(media.id, {format: null}) }>{ truncate(media.name, 27) }</a>
                    </div>
                </td>
                <td></td>
                { AssignmentGroupAssignedAssets.renderVersion(media) }
                { AssignmentGroupAssignedAssets.renderPlatformSupport(media) }
                { AssignmentGroupAssignedAssets.renderAvailabilityLabel(media) }

            </tr>
        )
    }

    render() {
        const sortAssets = (assetA, assetB) => {
            return sortStrings(`${assetA.name}-${assetA.id}`, `${assetB.name}-${assetB.id}`)
        }

        const apps  = this.props.assignmentGroup.apps.sort(sortAssets);
        const media = this.props.assignmentGroup.media.sort(sortAssets);
        if (apps.length === 0 && media.length === 0) {
            if (this.props.allDataLoaded) {
                return <em>no apps or media</em>;
            } else {
                return <em>loading apps and media...</em>;
            }
        } else {
            return (
                <table className="assets-or-devices">
                    <tbody>
                    { apps.map(this.renderApp) }
                    { media.map(this.renderMedia) }
                    </tbody>
                </table>

            )
        }
    }
}