import React from 'react';
import PropTypes from 'prop-types'
import PasswordRevealInput from "../forms/password_reveal_input";
import * as ajaxClients from '../../ajax_clients';

class APIKeyPasswordRevealInput extends React.Component {

    constructor(props) {
        super(props);
        this.revealHandler = this.revealHandler.bind(this);
        this.hideHandler = this.hideHandler.bind(this);
        this.state = {
            apiKey: null
        }
    }

    revealHandler(e) {
        e.preventDefault();
        ajaxClients.apiKeys.reveal(this.props.id).then(({ apiKey }) => {
            this.setState({ apiKey });
        })
    }

    hideHandler(e) {
        e.preventDefault();
        this.setState({ apiKey: null })
    }

    render() {
        return <PasswordRevealInput
            label={ 'Secret Access Key'}
            revealedValue={ this.state.apiKey }
            revealHandler={ this.revealHandler }
            hideHandler={ this.hideHandler}
        />
    }
}

APIKeyPasswordRevealInput.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

export default APIKeyPasswordRevealInput;