import React from 'react'
import {default as MuiButton} from '@mui/material/Button'

const Button = (props) => {
  const {sx: sxOverrides, ...otherProps} = props
  const sx = {
    fontSize:'1rem',
    fontWeight:700,
    paddingY: '16px',
    ...sxOverrides,
  }

  return (
    <MuiButton
      sx={sx}
      {...otherProps}
    />
  )
}

export default Button
