export const deviceInstalledMedia = {
    read(deviceId, idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminDeviceInstalledMediaPath(deviceId, idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminDeviceInstalledMediaIndexPath(deviceId, idOrOptions));
        }
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.destroyMultipleAdminInstalledMediaIndexPath(),
            data:     JSON.stringify({ ids })
        });

    }

};
