import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import { useUppy } from '@uppy/react'
import { Dashboard } from '@uppy/react'
import React, { useCallback, useEffect } from "react"
import { directMultipartUpload as directMultipartUploadClient } from '../../ajax_clients'
import { visitURL } from "../../shared/query"

const DirectUploadUppy = ({ id, successHandler, humanNamePlural, allowedFileTypes, maxNumberOfFiles = 2, optionOverrides = {} }) => {
    const uppy = useUppy(() => new Uppy({
        id,
        restrictions: {
            allowedFileTypes,
            minNumberOfFiles: 1,
            maxNumberOfFiles,
            maxFileSize: 10737418240 // 10GB
        },
        autoProceed: true,
        ...optionOverrides,
    }).use(AwsS3Multipart, {
        getChunkSize(_file) { return Math.pow(1024, 3) }, // 1GB
        async createMultipartUpload(file) {
            const { data } = await directMultipartUploadClient.create(file.name)
            uppy.setFileMeta(file.id, { simpleMDMData: data })

            return {
                key: data.key,
                uploadId: data.uploadId,
            }
        },
        abortMultipartUpload(_file, { key, uploadId }) {
            return directMultipartUploadClient.destroy(uploadId, key)
        },
        signPart(_file, { uploadId, key, partNumber }) {
            return directMultipartUploadClient.createPart(uploadId, key, partNumber)
        },
        listParts(_file, { key, uploadId }) {
            return directMultipartUploadClient.parts(uploadId, key)
        },
        completeMultipartUpload(_file, { key, uploadId, parts }) {
            return directMultipartUploadClient.complete(uploadId, key, parts)
        },
    }))

    const errorHandler = useCallback((_error) => {
        addFlashAlert('error', "There was an error processing your file. Please contact support for assistance")
    }, [])

    const localSuccessHandler = useCallback(({ successful }) => {
        successful.forEach((file) => {
            file.simpleMDMData = file.meta.simpleMDMData
            file.preview = file.meta.simpleMDMData.iconUrl

            successHandler(file).catch(errorHandler)
        })
    }, [successHandler])

    useEffect(() => {
        uppy.on('error', errorHandler)
        uppy.on('complete', localSuccessHandler)

        return () => {
            uppy.off('error', errorHandler)
            uppy.off('complete', localSuccessHandler)
        }
    }, [errorHandler, localSuccessHandler, uppy])

    const doneButtonHandler = () => {
        visitURL(AdminRoutes.adminCatalogPath({ format: null }))
    }

    return (
        <div>
            <Dashboard
                uppy={uppy}
                proudlyDisplayPoweredByUppy={false}
                doneButtonHandler={doneButtonHandler}
                locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropPasteFiles: `Drag and drop ${allowedFileTypes.join(', ')} files in this box or %{browse} to upload ${humanNamePlural}.`,
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse',
                        myDevice: 'Browse Files',
                    },
                }}
            />
        </div>
    )
}

export default DirectUploadUppy