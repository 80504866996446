export const erases = {
    show() {
       this.updateActivationLock();
    },

    create() {
        this.updateActivationLock();
    },

    updateActivationLock() {
        let activationLockDisable = document.getElementById('device_erase_pin_disable_activation_lock_true');
        let activationLockEnable = document.getElementById('device_erase_pin_disable_activation_lock_false');

        let returnServiceEnable = document.getElementById('device_erase_pin_return_to_service_true');
        let returnServiceDisable = document.getElementById('device_erase_pin_return_to_service_false');

        let wifiNetworkListContainer = document.getElementById('erase_wifi_network_list');

            returnServiceEnable.onchange = function() {
                if ( returnServiceEnable.checked ) {
                    if(activationLockDisable){
                        activationLockDisable.checked = true;
                        activationLockEnable.disabled = true;
                    }
                    wifiNetworkListContainer.style.display = "block"
                }
            }
            returnServiceDisable.onchange = function() {
                if ( returnServiceDisable.checked ) {
                    if(activationLockDisable){
                        activationLockEnable.disabled = false;
                    }
                    wifiNetworkListContainer.style.display = "none"
                }
            }
    
            returnServiceEnable.onchange();
            returnServiceDisable.onchange();
    }
}
