import passwordRevealInput from '../../shared/password_reveal_input'

export const devices = {
	index() {
		MDM.fn.selectionActionButton.initialize('devices');
		// Do not keep "export devices" button depressed after clicking it

		$(".export-devices-button").mouseup(function(){
		    $(this).blur();
		});
	},

	show() {
		passwordRevealInput()
	},

	update() {
		passwordRevealInput()
	},

	edit_admin_password() {
		passwordRevealInput()
	},

	set_admin_password() {
		passwordRevealInput()
	}
};