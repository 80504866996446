import React from 'react';
import { bindMethods } from "../../shared/functions";
import PagedTable from "../paged_table";

export default class AppNotificationSettingsPagedTable extends React.Component {
    constructor(props) {
        super(props);
        bindMethods(this);
        this.state = {
            checked: []
        }
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Identifier", key: "bundleIdentifier" },
                { displayName: "Notifications Enabled?", key: "notificationsEnabled" },
            ],
            data:      this.props.data,
            onCheckAll: this.onCheckAll,
            onCheck: this.onCheck,
            showCheck: this.props.policy.update === true,
            renderRow: (appNotificationSetting, index) => {
                const onClick              = (e) => {
                    e.preventDefault();
                    this.props.editApp(index)
                }
                const name                 = <a href={ `#appNotification${ index }` }
                                                onClick={ onClick }>{ appNotificationSetting.name }</a>
                const notificationsEnabled = appNotificationSetting.notificationsEnabled ? "yes" : "no"
                return { ...appNotificationSetting, name, notificationsEnabled }
            }
        }
    }

    onCheck(index, object, checked) {
        this.props.checkAppNotificationSetting(index, checked)
    }

    onCheckAll(checked) {
        this.props.checkAppNotificationSetting('all', checked)
    }

    deleteSelected(e) {
        if (confirm("This will delete the selected app notification settings. Are you sure?")) {
            e.preventDefault()
            const indexes = []
            this.props.data.forEach((item, index) => {
                if (item.checked) {
                    indexes.push(index)
                }
            })
            this.props.deleteApps(indexes)
        }
    }

    renderTopSection() {
        const deleteButtonClasses = ["btn", "btn-default", "selection-actions-button"]
        if (!this.props.data.some(item => item.checked)) {
            deleteButtonClasses.push("disabled")
        }

        if (this.props.policy.update) {
            return <div className="after-header-buttons">
                <a href="#add-app" onClick={ this.props.addApp } className="btn btn-default">
                    Add App
                </a>&nbsp;
                <a href="#remove-apps" onClick={ this.deleteSelected } className={ deleteButtonClasses.join(' ') }>
                    Delete
                </a>

            </div>
        }
    }

    render() {
        const notFound        = <div className="alert alert-info centered results-message" role="alert">No app
            notification
            settings have been defined yet.</div>
        const pagedTableProps = this.pagedTableProps()
        return <React.Fragment>
            { this.renderTopSection() }
            {
                this.props.data.length === 0 ? notFound : <PagedTable { ...pagedTableProps }/>
            }
        </React.Fragment>
    }
}
