export const scripts = {
    new() {
        this.wireAndUpdateForm();
    },
    show() {
        this.wireAndUpdateForm();
    },
    create() {
        this.wireAndUpdateForm();
    },
    update() {
        this.wireAndUpdateForm();
    },
    wireAndUpdateForm() {
        const scriptTextField = document.getElementById('script_content')
        const editor          = ace.edit("editor")

        editor.setTheme("ace/theme/tomorrow")
        editor.session.setUseWorker(false)
        editor.session.setMode("ace/mode/sh")
        editor.session.setNewLineMode('unix')
        editor.setReadOnly(scriptTextField.disabled)

        editor.getSession().setValue(scriptTextField.value)
        editor.getSession().on('change', () => {
            scriptTextField.value = editor.getSession().getValue()
        })
    }
};