import React from 'react';
import { groups as ajaxClient, profiles as profilesClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, truncate } from '../../shared/functions';
import { formatAssignments } from "../devices/profile_assignments_paged_table";
import StatusLabel from "../profiles/status_label";
import ActionDropDown from "../shared/action_drop_down";
import allSettled from "promise.allsettled";

const getAjaxClient = ({ groupId }) => ({
    read() {
        return ajaxClient.readProfileAssignments(groupId).then(formatAssignments)
    },
    destroyMultiple: (ids) => allSettled(ids.map(id => ajaxClient.unassignProfile(groupId, id)))
});

class GroupProfileAssignmentsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Unassign",
                policy: 'assignProfiles'
            }
        ];

        return <ActionDropDown
            text="Actions"
            items={ items }
            policy={ this.props.policy }
            disabled={ !this.props.hasCheckedItems() }
        />
    }

    profileAssignmentPath() {
        return AdminRoutes.adminGroupAssignProfilesPath(this.props.groupId, {format: null})
    }

    removeItems(e) {
        this.props.removeItems(e, 'This will remove the selected profiles from all devices assigned to this group. Are you sure?')
    }

    renderAssignProfileButton() {
        return <a className="btn btn-default pull-right"
                  href={ this.profileAssignmentPath() }>Assign Profile</a>
    }

    pagedTableProps() {
        const { declarative } = this.props.policy;
        const headers = [
            { displayName: "Name", key: "name", show: true },
            { displayName: "Type", key: "displayName", show: true },
            { displayName: "Declarative Enabled", key: "declarative", show: declarative },
            { displayName: "", key: "statusReason", show: true },
        ]

        return {
            headers: headers.filter(header => header.show),
            showCheck: this.props.policy.assignProfiles === true,
            renderRow: (row) => ({
                ...row,
                declarative: row.declarative ? 'Yes' : 'No',
                name: <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>,
                statusReason: row.statusReason ? <StatusLabel  { ...row } /> : null
            }),
        }
    }

    render() {
        let actions = <React.Fragment>
            { this.renderActionButton() }
            { this.renderAssignProfileButton() }
        </React.Fragment>;


        const assignLink = this.props.policy.assignProfiles ? <><a href={ this.profileAssignmentPath() }>Assign the first profile</a>.</> : null;
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No profiles are currently assigned. {assignLink}
            </div>
        )

        return (
            <div id="profile-assignments-table">
                { this.props.renderTopSection(null, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(GroupProfileAssignmentsPagedTable, getAjaxClient, undefined, null);