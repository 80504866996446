import React from 'react'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import { bindMethods } from '../../shared/functions'

export default class AdminNotificationReminderSelector extends React.Component {

    constructor(props) {
        super(props)
        this.allowMultiple = typeof this.props.selected == 'object'
        this.state = {
            open: false
        }
        bindMethods(this)
    }

    onSelect(value) {
        this.props.onSelect(this.props.notification, value)
    }

    renderCheckBoxItem(item, index, checked) {
        return (
            <li role="presentation" key={ index }>
                <div className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={ checked }
                            onChange={ () => this.onSelect(item.value) }
                        />
                        { item.label }
                    </label>
                </div>
            </li>
        )
    }

    renderMenuItem(item, index, active, extraClasses = null) {
        return (
            <MenuItem key={ index } active={ active } onSelect={ () => this.onSelect(item.value) } className={extraClasses}>
                { item.label }
            </MenuItem>
        )
    }

    renderItem(item, index) {
        if (this.allowMultiple) {
            const checked = this.props.selected.includes(item.value)
            if (item.value == 0) {
                return this.renderMenuItem(item, index, checked, 'dropdown-item-never')
            } else {
                return this.renderCheckBoxItem(item, index, checked)
            }
        } else {
            const checked = this.props.selected == item.value
            return this.renderMenuItem(item, index, checked)
        }
    }

    render() {
        let { type, intervalOptions } = this.props.notification
        let title = 'never'
        let selected = this.props.selected

        if ( this.allowMultiple ) {
            selected = selected.sort((a,b) => a - b)
            title = intervalOptions.filter(item => selected.includes(item.value)).map(item => item.label).join(', ')
        } else {
            title = intervalOptions.find(item => item.value == selected).label
        }

        return (
            <DropdownButton
                id={`dropdown-${type}`}
                bsSize="small"
                title={ title }
            >
                { intervalOptions.map((option, i) => this.renderItem(option, i)) }
            </DropdownButton>
        )
    }
}
