export const email_providers = {
    new() {
        this.wireAndUpdateForm();
    },
    show() {
        this.wireAndUpdateForm();
    },
    create() {
        this.wireAndUpdateForm();
    },
    update() {
        this.wireAndUpdateForm();
    },
    wireAndUpdateForm() {
        const oauthInputs = $('#oauth-urls');
        $('#email_provider_oauth_enabled').change(function() {
            if($(this).val() === 'true') {
                oauthInputs.show();
            } else {
                oauthInputs.hide();
            }
        }).trigger('change');

        $('#email_provider_account_type').change(this.updateForm);
        this.updateForm();
    },
    updateForm() {
        const accountType    = $('#email_provider_account_type').val();
        const exchangeFields  = $('.exchange-fields');
        const emailOnlyFields = $('#email-only-fields');
        if (accountType === "Exchange") {
            exchangeFields.show();
            emailOnlyFields.hide();
        } else {
            exchangeFields.hide();
            emailOnlyFields.show();
        }
    }
};