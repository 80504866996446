export const sessions = {
	fn: {
		setTimestamp() {
			$('body').attr('data-loaded-at', new Date());
		},
		checkTimestamp() {
			const reloadTime = Date.parse($('body').attr('data-loaded-at'));
			const currentTime = new Date();

			if(currentTime - reloadTime > 19*60*1000) {
				window.location.reload(true);
			} else {
				setTimeout(this.fn.checkTimestamp, 30000);
			}
		},
	},
	show() {
		this.fn.setTimestamp();
		this.fn.checkTimestamp();
	},
	create() {
		this.fn.setTimestamp();
		this.fn.checkTimestamp();
	}
};