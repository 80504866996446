import React from "react";
import PropTypes from 'prop-types';
import { devices as ajaxClient } from '../../ajax_clients'
import { bindMethods } from "../../shared/functions";
import FormGroup from "../forms/form_group";

export default class SendMessage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            message: "",
        }
        bindMethods(this);
    }

    setMessage(e) {
        this.setState({ message: e.target.value })
    }

    cancel(e) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    submit(e) {
        e.preventDefault();
        ajaxClient.sendMessage(this.props.deviceIds, this.state.message).then(() => {
            if (this.props.onSendMessage) {
                this.props.onSendMessage()
            }
        }).catch(({ responseJSON }) => {
            if (responseJSON.error) {
                addFlashAlert('error', responseJSON.error.message)
                if (responseJSON.error.modelErrors && responseJSON.error.modelErrors.message) {
                    this.setState({error: responseJSON.error.modelErrors.message[0] })
                }
            }

            if(this.props.onError) {
                this.props.onError(responseJSON.error)
            }
        })
    }

    render() {

        return <div className="send-message multi-select-panel">
            <h1>Message Devices</h1>

            <div className="well">
                <p>Send a message via direct push to devices that have the SimpleMDM app installed.</p>
                <form>
                    <FormGroup title="Message" error={ this.state.error } required={ true }>
                        <textarea name="message" rows="4" value={ this.state.message } onChange={ this.setMessage }/>
                    </FormGroup>

                    <div className="buttons">
                        <a href="#cancel" className="btn btn-default" onClick={ this.cancel }>Cancel</a>
                        <a href="#submit" className="btn btn-primary" onClick={ this.submit }>Message Devices</a>
                    </div>
                </form>
            </div>
        </div>
    }
}

SendMessage.propTypes = {
    deviceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func,
}

