import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class PrivacyEventReceivers extends React.Component {

    constructor(props) {
        super(props);
        this.state        = {
            data: props.data,
        };

        this.headers      = [
            {
                displayName: 'Identifier Type',
                key:         'identifier_type',
                options:     [
                    { displayName: 'bundle ID', value: 'bundleID' },
                    { displayName: 'path', value: 'path' }
                ]
            },
            { displayName: 'Identifier', key: 'identifier' },
            { displayName: 'Code Requirement', key: 'codeRequirement' },
            {
                displayName: 'Access',
                key:         'allowed',
                options:     [
                    { displayName: "Allow", value: "true" },
                    { displayName: "Block", value: "false" },
                ]
            },
        ];
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem    = this.onAddItem.bind(this);
    }

    onChangeItem(updatedItem, updatedIndex) {
        this.setState({
            data: this.state.data.map((row, index) => index === updatedIndex ? updatedItem : row)
        })
    }

    onRemoveItem(removedItem, removedIndex) {
        this.setState({
            data: this.state.data.filter((item, index) => index !== removedIndex)
        })
    }

    onAddItem() {
        const newItem = {
            identifierType: "bundleID",
            identifier: "",
            codeRequirement: "",
            allowed:           "true"
        };

        this.setState({
            data: [...this.state.data, newItem]
        })
    }

    render() {
        return <DynamicList
            fieldName={ 'privacy_identity[privacy_event_receivers]' }
            headers={ this.headers }
            rows={ this.state.data }
            policy={ this.props.policy }
            onChangeItem={ this.onChangeItem }
            onRemoveItem={ this.onRemoveItem }
            onAddItem={ this.onAddItem }
        />
    }
}