export const shared_actions = {
    show() {
        this.wireProfileScope()
    },

    new() {
        this.wireProfileScope()
    },

    wireProfileScope() {
        const profileScopeDiv = document.querySelector('.profile-scope')
        if (profileScopeDiv) {
            const macOSCheckBox = profileScopeDiv.querySelector('input[value="macOS"]')
            if (macOSCheckBox) {
                this.toggleMacOSSettings(macOSCheckBox)
                macOSCheckBox.addEventListener('change', () => this.toggleMacOSSettings(macOSCheckBox))
            }
        }
    },

    toggleMacOSSettings(checkBox) {
        if (!checkBox.closest('form').disabled) {
            document.querySelectorAll('.macos-scope select').forEach(select => select.disabled = !checkBox.checked)
        }
    },
};