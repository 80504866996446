const createAceEditor = (aceEditorElement) => {
    const aceEditor  = ace.edit(aceEditorElement)
    aceEditor.setTheme("ace/theme/tomorrow")
    aceEditor.setReadOnly(aceEditorElement.classList.contains('disabled'))
    aceEditor.session.setUseWorker(false);
    return aceEditor
}

const wireScriptEditors = () => {
    document.querySelectorAll('.script-editor').forEach(scriptEditor => {
        const aceEditorElement = scriptEditor.querySelector('.ace-editor');
        const aceEditor        = createAceEditor(aceEditorElement)
        aceEditor.session.setMode('ace/mode/sh');

        const scriptHolder = scriptEditor.querySelector('.script-holder')
        aceEditor.getSession().setValue(scriptHolder.value);
        aceEditor.getSession().on('change', () => {
            scriptHolder.value = aceEditor.getSession().getValue()
        });
    })
}

const wireCustomManagedConfig = () => {
    const useManagedConfigPlist = document.getElementById('app_use_managed_config_plist')
    if (useManagedConfigPlist === null) { return }

    const managedConfigFields     = document.querySelectorAll('#app_managed_config_plist_upload, #app_managed_config_plist')
    const aceEditorElement        = document.getElementById('ace-editor-manged-config')
    const aceEditor               = createAceEditor(aceEditorElement)
    const scriptHolder            = document.getElementById('app_managed_config_plist')
    const configFormElement       = document.querySelector('.config-form')
    const configUploadFormElement = document.querySelector('.config-upload-form')

    aceEditor.session.setMode('ace/mode/xml');

    aceEditor.getSession().setValue(scriptHolder.value);
    aceEditor.getSession().on('change', () => {
        scriptHolder.value = aceEditor.getSession().getValue()
    });

    useManagedConfigPlist.addEventListener('change', (e) => {
        const disabled = !e.target.checked

        managedConfigFields.forEach(field => field.disabled = disabled)
        if (disabled) {
            aceEditorElement.classList.add('disabled')
        } else {
            aceEditorElement.classList.remove('disabled')
        }
        configFormElement.hidden = !disabled
        configUploadFormElement.hidden = disabled
        aceEditor.setReadOnly(disabled)
    })
}

const wireCustomPkgInfo = () => {
    const useCustomPkgInfo      = document.getElementById('app_use_custom_pkginfo')
    if (useCustomPkgInfo === null) { return }

    const aceEditorElement      = document.querySelector('.ace-editor-pkginfo')
    const scriptHolder          = document.getElementById('app_munki_pkginfo_content')
    const customPkgInfo         = document.getElementById('custom_pkginfo').value
    const defaultPkgInfo        = document.getElementById('default_pkginfo').value
    const pkginfoFields         = document.querySelectorAll('#app_munki_pkginfo_plist, #app_munki_pkginfo_content, #app_pkginfo_variable_support')

    const aceEditor             = createAceEditor(aceEditorElement)
    const defaultPkgInfoSession = ace.createEditSession(defaultPkgInfo)
    const customPkgInfoSession  = ace.createEditSession(customPkgInfo)

    defaultPkgInfoSession.setMode("ace/mode/xml")
    customPkgInfoSession.setMode("ace/mode/xml")
    defaultPkgInfoSession.setUseWorker(false)
    customPkgInfoSession.setUseWorker(false)

    if (useCustomPkgInfo.checked) {
        aceEditor.setSession(customPkgInfoSession)
    } else {
        aceEditor.setSession(defaultPkgInfoSession)
    }
    customPkgInfoSession.on('change', () => {
        scriptHolder.value = customPkgInfoSession.getValue()
    })

    useCustomPkgInfo.addEventListener('change', (e) => {
        const disabled              = !e.target.checked
        pkginfoFields.forEach(field => field.disabled = disabled)
        if (disabled) {
            aceEditorElement.classList.add('disabled')
            aceEditor.setSession(defaultPkgInfoSession)
        } else {
            aceEditorElement.classList.remove('disabled')
            aceEditor.setSession(customPkgInfoSession)
        }
        aceEditor.setReadOnly(disabled)
    })
}

const wireNoPkg = () => {
    const aceEditorElement = document.querySelector('.ace-editor-pkginfo');
    createAceEditor(aceEditorElement);

    const aceEditor = createAceEditor(aceEditorElement)
    const scriptHolder = document.getElementById("app_munki_pkginfo_content")
    const customPkgInfo = document.getElementById('custom_pkginfo').value
    const customPkgInfoSession = ace.createEditSession(customPkgInfo)

    customPkgInfoSession.setUseWorker(false)
    customPkgInfoSession.setMode("ace/mode/xml")
    aceEditor.setSession(customPkgInfoSession)

    customPkgInfoSession.on('change', () => {
        scriptHolder.value = customPkgInfoSession.getValue()
    })

    scriptHolder.value = customPkgInfoSession.getValue()
}

export const apps = {
    show() {
        MDM.fn.selectionActionButton.initialize('installs');
        MDM.fn.selectionActionButton.initialize('licenses');
        wireScriptEditors()
        wireCustomPkgInfo()
        wireCustomManagedConfig()
    },
    update() {
        this.show();
    },
    new_app_store_app() {
        const showSearching = function() {
            show(document.querySelectorAll('.searching .progress'))
            $('.searching p').text('searching the Apple iTunes Store');
            $('.itunes-search-results').animate({ opacity: 0 }, 'fast').hide();
            $('.searching').animate({ opacity: 1 }, 'fast').show();
        };

        $('input#q').keydown(function(e) {
            if (parseInt(e.keyCode) === 13) {
                showSearching();
            }
        });

        $('form').bind("submit", showSearching);
        $('.nav-tabs a:first').tab('show');
    },
    new_nopkg_app() {
        wireNoPkg()
    },

    create_nopkg() {
        wireNoPkg()
    },

    update_nopkg() {
        wireNoPkg()
    }
};