const wireAndUpdateForm = () => {

    const radioFields = $('[name="software_update_policy_ios[update_immediately]"]');
    const disabledForm = $('input[name="software_update_policy_ios[name]"]').prop('disabled')

    const updateForm = function() {
        const radioValue   = radioFields.filter(':checked').val();
        const selectFields = $('.update-window select, .update-window input');

        if (radioValue === "true" || disabledForm) {
            selectFields.prop('disabled', 'disabled');
        } else {
            selectFields.prop('disabled', false);
        }
    };

    $(radioFields).on('change', updateForm);

    updateForm();

}

export const software_update_policy_ios = {
    new:    function() {
        wireAndUpdateForm();
    },
    show:   function() {
        wireAndUpdateForm();
    },
    create: function() {
        wireAndUpdateForm();
    },
    update: function() {
        wireAndUpdateForm();
    }
};
