
export const homeScreenLayouts = {
    create(data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ homeScreenLayout: data }),
        		url:         AdminRoutes.adminHomeScreenLayoutsPath()
				});
    },

    update(id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  homeScreenLayout: data }),
            url:         AdminRoutes.adminHomeScreenLayoutPath(id)
        });
    },

    delete(id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminHomeScreenLayoutPath(id)
        });

    },

    duplicate(id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminHomeScreenLayoutPath(id)
        });
    }
};

export const deviceHomeScreenLayouts = {
    create(deviceId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ homeScreenLayout: data }),
        		url:         AdminRoutes.adminDeviceHomeScreenLayoutsPath(deviceId)
   		  });
    },

    update(deviceId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  homeScreenLayout: data }),
            url:         AdminRoutes.adminDeviceHomeScreenLayoutPath(deviceId, id)
        });
    },

    delete(deviceId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminDeviceHomeScreenLayoutPath(deviceId, id)
        });

    },

    duplicate(deviceId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminDeviceHomeScreenLayoutPath(deviceId, id)
        });
    }
};

export const groupHomeScreenLayouts = {
    create(groupId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ homeScreenLayout: data }),
            url:         AdminRoutes.adminGroupHomeScreenLayoutsPath(groupId)
    	  });
    },

    update(groupId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  homeScreenLayout: data }),
            url:         AdminRoutes.adminGroupHomeScreenLayoutPath(groupId, id)
        });
    },

    delete(groupId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminGroupHomeScreenLayoutPath(groupId, id)
        });
    },

    duplicate(groupId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminGroupHomeScreenLayoutPath(groupId, id)
        });
    }
};

export const allHomeScreenLayouts = {
  default: homeScreenLayouts,
  device: deviceHomeScreenLayouts,
  group: groupHomeScreenLayouts
}
