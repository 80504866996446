import React from 'react';
import { enrollmentCodes as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds, truncate } from "../../shared/functions";

const getAjaxClient = () => {
    return {
        ...ajaxClient,
        destroyMultiple: (enrollmentCodes) => callWithIds(enrollmentCodes, ajaxClient.destroyMultiple),
        enableRemoteDesktop: (enrollmentCodes) => callWithIds(enrollmentCodes, ajaxClient.enableRemoteDesktop),
        disableRemoteDesktop: (enrollmentCodes) => callWithIds(enrollmentCodes, ajaxClient.disableRemoteDesktop)
    }
};

class EnrollmentCodesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    renderFeatureIcon(feature) {
        switch (feature) {
            case('Welcome Screen'):
                return <span key='home' className="glyphicon glyphicon-home" title={ feature }/>;
            case('Authentication'):
                return <span key='lock' className="glyphicon glyphicon-lock" title={ feature }/>;
            case('User Enrollment'):
                return <span key='user' className="glyphicon glyphicon-user" title={ feature }/>;
            case('Enable Remote Desktop'):
                return <span key='remote-desktop' className="glyphicon glyphicon-facetime-video" title={ feature }/>;
            case('Account Driven'):
                return <span key='remote-desktop' className="glyphicon glyphicon-text-background" title={ feature }/>;
        }
    }

    enableRemoteDesktop(e) {
        e.preventDefault()

        const confirmMessage = "Enable the Apple Remote Desktop service on macOS 10.14.4+ at the time of enrollment. Devices that are already enrolled will not be affected. Are you sure?"

        if (confirm(confirmMessage)) {
            this.props.callWithItems('enableRemoteDesktop');
        }
    }

    disableRemoteDesktop(e) {
        e.preventDefault()

        const confirmMessage = "Do not enable the Apple Remote Desktop service on macOS 10.14.4+ at the time of enrollment. Devices that are already enrolled will not be affected. Are you sure?"

        if (confirm(confirmMessage)) {
            this.props.callWithItems('disableRemoteDesktop');
        }
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroyMultiple',
            },
            {
                href:   "#enable_remote_desktop",
                action: this.enableRemoteDesktop,
                text:   "Enable Remote Desktop",
                policy: 'update',
            },
            {
                href:   "#disable_remote_desktop",
                action: this.disableRemoteDesktop,
                text:   "Disable Remote Desktop",
                policy: 'update',
            },
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    renderAlertMessage({ alertMessage }) {
        if (alertMessage) {
            const { level, message } = alertMessage;
            return (
                <span className={ `label label-${ level } label-rejected` }>
                    <span className="glyphicon glyphicon-alert"/>
                    &nbsp;
                    { message }
                </span>
            )
        }
    }

    renderOneTimeLink({ device }) {
        if (device.name && device.id) {
            return (
                <React.Fragment>
                    <span className="glyphicon glyphicon-phone" title="One Time Enrollment"/>
                    (<a href={ AdminRoutes.adminDevicePath(device.id, {format: null}) }>{ device.name }</a>)
                </React.Fragment>
            )
        }
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Name", key: "name" },
                { displayName: "Type", key: "type" },
                { displayName: "Features", key: "features", classNames: ['expand'] },
            ],
            renderRow: (row) => {
                const name = <a href={ AdminRoutes.adminEnrollmentCodePath(row.id, {format: null}) }>{ truncate(row.name, 50) }</a>;
                const type = <React.Fragment>
                    { row.enrollmentType }
                    { this.renderAlertMessage(row) }
                </React.Fragment>;

                const features = <React.Fragment>
                    { row.features.map(this.renderFeatureIcon) }
                    { this.renderOneTimeLink(row) }
                </React.Fragment>;

                return { ...row, name, type, features }
            }
        };
    }

    renderAddEnrollmentCodeButton() {
        if (this.props.policy.create) {
            const items = [
                { href: AdminRoutes.newAdminDEPAccountsPath({format: null}), text: "Automated Enrollment (DEP)" },
                { href: AdminRoutes.newAdminEnrollmentCodePath({format: null}), text: "Group Enrollment" },
                { href: AdminRoutes.newAdminDevicePath({format: null}), text: "One-Time Enrollment" },
            ];
            return <ActionDropDown text="Add Enrollment" items={ items }/>;
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No enrollments codes found.
            </div>
        );

        const message = <p>Enrollments provide a means of adding devices to your SimpleMDM account.
            Enrollment codes can be a link your users visit from their devices. They can also link your account to
            Automated Enrollment within Apple Business Manager.</p>;
        const actions = (
            <div className="button-list-container">
                { this.renderAddEnrollmentCodeButton() }
                { this.renderActionButton() }
            </div>
        );
        return (
            <div id="enrollment-codes-table">
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }

}

export default pagedTableLayout(EnrollmentCodesPagedTable, getAjaxClient);