import { toggleDOMElement } from "../../shared/functions";
export const software_update_settings = {
  new() {
      this.show()
  },

  update() {
      this.show()
  },

  create() {
      this.show()
  },

  show() {
    const enableCombinedOsUpdateDeferral = document.getElementById('software_update_setting_deferrals_enable_combined_period_in_days')
    const enableMajorPeriodInDays = document.getElementById('software_update_setting_deferrals_enable_major_period_in_days')
    const enableMinorPeriodInDays = document.getElementById('software_update_setting_deferrals_enable_minor_period_in_days')
    const enableSystemPeriodInDays = document.getElementById('software_update_setting_deferrals_enable_system_period_in_days')

    const combinedOsUpdateDeferral = document.querySelector('.software_update_setting_deferrals_combined_period_in_days')
    const majorPeriodInDays = document.querySelector('.software_update_setting_deferrals_major_period_in_days')
    const minorPeriodInDays = document.querySelector('.software_update_setting_deferrals_minor_period_in_days')
    const systemPeriodInDays = document.querySelector('.software_update_setting_deferrals_system_period_in_days')

    toggleDOMElement(enableCombinedOsUpdateDeferral, combinedOsUpdateDeferral)
    toggleDOMElement(enableMajorPeriodInDays, majorPeriodInDays)
    toggleDOMElement(enableMinorPeriodInDays, minorPeriodInDays)
    toggleDOMElement(enableSystemPeriodInDays, systemPeriodInDays)
  }
};