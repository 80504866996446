export const script_jobs = {
    show() {
        this.wireAndUpdateForm();
    },
    wireAndUpdateForm() {
        const scriptTextField = document.getElementById('script-content')
        const editor          = ace.edit("editor")

        editor.setTheme("ace/theme/tomorrow")
        editor.setReadOnly(true)
        editor.session.setUseWorker(false)
        editor.session.setMode("ace/mode/sh")
        editor.session.setNewLineMode('unix')

        editor.getSession().setValue(scriptTextField.value)
    }
};