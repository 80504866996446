import allSettled from "promise.allsettled";

export const deviceAccounts = {
    read(deviceId, query) {
        return $.getJSON(AdminRoutes.adminDeviceDeviceAccountsPath(deviceId, query))
    },

    destroyMultiple(deviceAccounts) {
        if(deviceAccounts.some(deviceAccount => !deviceAccount.originalRecord)) {
            throw "Missing original record url"
        }
        return allSettled(deviceAccounts.map(deviceAccount => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      deviceAccount.originalRecord
        })));
    }
};