import React from "react"
import {default as SharedJoyride} from '../shared/joyride'

const JOYRIDE_STEPS = [
  {
    content: <><p>The assignment page lets you assign apps and media to both devices and device groups.</p><p>You already have your first assignment group for the SimpleMDM mobile app. This  group assigns the SimpleMDM app to the device group named "Default". When devices join this group, the SimpleMDM app will be pushed to them automatically.</p><p>You can also manually install apps and media for a group by clicking the <em>Actions</em> button and selecting <em>Install Apps and Media</em>.</p><p><strong>Note:</strong> the SimpleMDM app is used to track device location. It is not required for all enrolled devices.</p><p>Click <strong>Done</strong> or press the return key to return to the devices view.</p></>,
  }
]

export default function Joyride() {
  return (
    <SharedJoyride
      nextPath={AdminRoutes.adminDevicesPath({ format: null, outro: true })}
      steps={JOYRIDE_STEPS}
    />
  )
}
