
export const notificationSettings = {
    create(data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ notificationSetting: data }),
        		url:         AdminRoutes.adminNotificationSettingsPath()
				});
    },

    update(id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  notificationSetting: data }),
            url:         AdminRoutes.adminNotificationSettingPath(id)
        });
    },

    delete(id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminNotificationSettingPath(id)
        });

    },

    duplicate(id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminNotificationSettingPath(id)
        });
    }
};

export const deviceNotificationSettings = {
    create(deviceId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ notificationSetting: data }),
        		url:         AdminRoutes.adminDeviceNotificationSettingsPath(deviceId)
   		  });
    },

    update(deviceId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  notificationSetting: data }),
            url:         AdminRoutes.adminDeviceNotificationSettingPath(deviceId, id)
        });
    },

    delete(deviceId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminDeviceNotificationSettingPath(deviceId, id)
        });

    },

    duplicate(deviceId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminDeviceNotificationSettingPath(deviceId, id)
        });
    }
};

export const groupNotificationSettings = {
    create(groupId, data) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({ notificationSetting: data }),
            url:         AdminRoutes.adminGroupNotificationSettingsPath(groupId)
    	  });
    },

    update(groupId, id, data) {
        return $.ajax({
            method:      'PUT',
            contentType: "application/json",
            dataType:    'json',
            data:        JSON.stringify({  notificationSetting: data }),
            url:         AdminRoutes.adminGroupNotificationSettingPath(groupId, id)
        });
    },

    delete(groupId, id) {
        return $.ajax({
            method:      'DELETE',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.adminGroupNotificationSettingPath(groupId, id)
        });
    },

    duplicate(groupId, id) {
        return $.ajax({
            method:      'POST',
            contentType: "application/json",
            dataType:    'json',
            url:         AdminRoutes.duplicateAdminGroupNotificationSettingPath(groupId, id)
        });
    }
};

export const allNotificationSettings = {
  default: notificationSettings,
  device: deviceNotificationSettings,
  group: groupNotificationSettings
}
