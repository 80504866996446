import React from 'react';
import PropTypes from 'prop-types';

import Spinner from "../shared/spinner";
import { bindMethods, escapeRegex } from "../../shared/functions";

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state   = {
            search:            props.initialValue || "",
            focused:           false,
            selectedItemIndex: null
        };
        this.timeout = null;

        bindMethods(this)
    }

    onSearchChange(e) {
        const search = e.target.value.replace(this.props.regexCleaner, '');
        this.setState({ search, selectedItemIndex: null, focused: true }, this.triggerSearch);
    }

    triggerSearch(immediately = false) {
        const search = () => {
            let searchRegexp;
            let searchString;
            if (this.state.search === "") {
                searchRegexp = null;
                searchString = null;
            } else {
                searchRegexp = new RegExp(escapeRegex(this.state.search), 'i');
                searchString = this.state.search;
            }

            this.props.onSearchChange(searchRegexp, searchString);
        }
        clearTimeout(this.timeout);
        if (immediately) {
            search()
        } else {
            this.timeout = setTimeout(search, this.props.timeout);
        }

    }

    onFocus() {
        this.setState({ focused: true, selectedItemIndex: null }, this.triggerSearch);
    }

    onBlur() {
        this.setState({ focused: false, selectedItemIndex: null });
    }

    onKeyDown(e) {
        const upKey    = 38;
        const downKey  = 40;
        const enterKey = 13;
        const escKey   = 27;

        switch (e.keyCode) {
            case upKey:
                this.previousItem(e);
                break;
            case downKey:
                this.nextItem(e);
                break;
            case enterKey:
                e.preventDefault();
                if (this.props.searchOnEnter) {
                    this.triggerSearch(true)
                } else {
                    this.chooseItem();
                }
                break;
            case escKey:
                this.onBlur();
                break;
        }
    }

    nextItem(e) {
        if (!this.props.children) {
            return;
        }

        e.preventDefault();

        let selectedItemIndex;
        if (this.state.selectedItemIndex == null) {
            selectedItemIndex = 0;
        } else {
            selectedItemIndex = (this.state.selectedItemIndex + 1) % this.props.children.length;
        }
        this.setState({ selectedItemIndex, focused: true });
    }

    previousItem(e) {
        if (!this.props.children) {
            return;
        }

        e.preventDefault();

        let selectedItemIndex = this.state.selectedItemIndex - 1;
        if (this.state.selectedItemIndex <= 0) {
            selectedItemIndex = null;
        }
        this.setState({ selectedItemIndex, focused: true });
    }

    chooseItem() {
        const selectedItemIndex = this.state.selectedItemIndex;
        const children          = this.props.children;
        if (children && selectedItemIndex !== null && children[selectedItemIndex]) {
            const child = children[selectedItemIndex];
            if (child.props && typeof child.props.onChooseItem === 'function') {
                child.props.onChooseItem()
            }
        }
        this.setState({ selectedItemIndex: null, focused: !this.state.focused }, this.triggerSearch);
    }

    clear(e) {
        if (e) {
            e.target.blur();
            e.preventDefault();
        }
        this.setState({ search: "", focused: false, selectedItemIndex: null }, this.triggerSearch);
    }

    addPropsToChildren() {
        const children               = React.Children.map(this.props.children, (child, index) => {
            const isSelected = this.state.selectedItemIndex === index;
            return React.cloneElement(child, { isSelected, clearSearch: this.clear })
        });
        const clearSelectedItemIndex = () => this.setState({ selectedItemIndex: null });

        if (children && children.length > 0) {
            return (
                <div
                    className="btn-group-vertical results"
                    role="group"
                    onClick={ this.onBlur }
                    onMouseOver={ clearSelectedItemIndex }>
                    { children }
                </div>
            )
        } else {
            return null;
        }
    }

    renderSearchGlyph() {
        if (this.props.searching) {
            return <Spinner/>
        } else {
            return <div className="glyphicon glyphicon-search"/>
        }
    }

    renderClearButton() {
        return <button className="btn btn-default btn-clear"
                       onClick={ this.clear }
                       disabled={ this.props.disabled }>
            Clear
        </button>;
    }

    render() {
        return (
            <div className="search-bar">
                <div className="search-and-results">
                    { this.props.renderSearchGlyph ? this.renderSearchGlyph() : null }
                    <input
                        disabled={ this.props.disabled }
                        className={ this.props.inputClass }
                        type="text"
                        placeholder={ this.props.placeholderText }
                        value={ this.state.search }
                        onChange={ this.onSearchChange }
                        onFocus={ this.onFocus }
                        onClick={ this.onFocus }
                        onKeyDown={ this.onKeyDown }
                        onBlur={ this.onBlur }
                    />
                    { this.state.focused ? this.addPropsToChildren() : null }
                </div>
                { this.props.renderClearButton ? this.renderClearButton() : null }
            </div>
        )
    }
}

SearchBar.propTypes = {
    onSearchChange:    PropTypes.func.isRequired,
    placeholderText:   PropTypes.string,
    initialValue:      PropTypes.string,
    searching:         PropTypes.bool,
    timeout:           PropTypes.number,
    renderClearButton: PropTypes.bool,
    renderSearchGlyph: PropTypes.bool,
    searchOnEnter:     PropTypes.bool,
    disabled:          PropTypes.bool,
};

const defaultRegexCleaner = /[\^\$\*\+\?\[\{\\\|]+/;
SearchBar.defaultProps    = {
    placeholderText:   "",
    inputClass:        "",
    initialValue:      "",
    regexCleaner:      defaultRegexCleaner,
    searching:         false,
    timeout:           0,
    renderClearButton: true,
    renderSearchGlyph: true,
    searchOnEnter:     false
};

export default SearchBar;
