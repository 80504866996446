import React from 'react';
import {
    devices as devicesClient,
    groups as groupsClient,
    depAccounts as depAccountsClient,
} from '../../ajax_clients';
import pagedTableLayout, { PagedTableContext } from '../paged_table/layout';
import { SearchBar } from "../search";
import { bindMethods, callWithIds, isEmpty, truncate } from "../../shared/functions";
import { pushURL, replaceURL, visitURL } from "../../shared/query";
import ActionDropDown from "../shared/action_drop_down";
import SendMessage from "./send_message";
import AssignGroup from "./assign_group";
import Restart from "./restart";
import PagedTableEditor from "./paged_table_editor";
import SetTimeZone from "./set_time_zone";
import OSUpdate from "./os_update";
import PasswordReveal from "../shared/password_reveal";
import allSettled from "promise.allsettled";
import { v2Route } from '../../shared/functions';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const getAjaxClient = () => {
    return {
        ...devicesClient,
        read: (options) => {
            return devicesClient.read(options).then(response => {
                return { ...response, data: response.data }
            })
        }
    }
}
const initialQuery = { order: 'at', order_d: 'desc', page: 1, per: 20, saveQuery: true, search: null, group: "" };

// shared with deleted devices table
export const renderGroupSelect = (groups, selected, profile, onChooseGroup) => {
    let options = []
    options.push(<option key="all" value="">All Device Groups</option>)
    const sortedGroups = groups.filter(group => group.name !== 'Quarantine')
        .concat(groups.filter(group => group.name === 'Quarantine'))

    sortedGroups.forEach(group => {
        const isQuarantineGroup = group.name === 'Quarantine';
        const name = isQuarantineGroup ? 'Quarantined Devices' : group.name
        const id = isQuarantineGroup ? 'quarantined' : group.id
        options.push(<option key={ id } value={ id }>{ name }</option>)
    })
    options.push(<option key="spacer" value="" disabled={ true }>-----------</option>)
    options.push(<option key="awaiting-enrollment" value="awaiting-enrollment">Awaiting Enrollment</option>)
    options.push(<option key="deleted-devices" value="deleted-devices">Deleted Devices</option>)

    if (profile) {
        options.push(<option key="profile-filter"
                             value={ `profile-${ profile.id }` }>{ `Profile: ${ truncate(profile.name, 35) }` }</option>)
    }
    return (
        <select value={ selected } onChange={ onChooseGroup } className="form-control filter">
            { options }
        </select>
    );

}

class DevicesPagedTable extends React.Component {
    static contextType = PagedTableContext;

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            depDevicesBySerial: {},
            editPagedTable: false,
            display: null
        }
        bindMethods(this)
    }

    componentDidMount() {
        groupsClient.read({ per: 'all' }).then(({ data: groups }) => {
            this.setState({ groups })
        })
        if (isEmpty(this.state.depDevicesBySerial) && this.props.query && this.props.query.group === 'awaiting-enrollment') {
            this.loadDEPDevices()
        }
    }

    onSearchChange(searchRegex, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({
                search,
                page: 1
            })
        }
    }

    onChooseGroup(e) {
        const filterId = e.target.value;
        if (filterId === 'deleted-devices') {
            const search = this.props.query.search
            visitURL(AdminRoutes.adminDeletedDevicesPath({ search, format: null }))
        } else if (filterId.startsWith('profile-')) {
            this.props.setQuery({
                group: null,
                page: 1,
                profile_id: filterId.split('-')[1]
            })
        } else {
            this.props.setQuery({
                group: filterId,
                page: 1,
                profile_id: null
            });
            if (isEmpty(this.state.depDevicesBySerial) && filterId === 'awaiting-enrollment') {
                this.loadDEPDevices()
            }
        }
    }

    loadDEPDevices() {
        depAccountsClient.read({ per: 'all' }).then(({ data: depAccounts }) => {
            const depDevicesBySerial = {};
            depAccounts.forEach(depAccount => {
                depAccount.depDevices.forEach(depDevice => {
                    depDevice = {
                        ...depDevice,
                        enrollmentCode: depAccount.enrollmentCode
                    }
                    depDevicesBySerial[depDevice.appleSerialNumber] = depDevice
                })
            });
            this.setState({ depDevicesBySerial })
        })
    }

    renderGroupSelect() {
        const profileId = this.props.query.profile_id
        const selected = profileId ? `profile-${ profileId }` : this.props.query.group
        return renderGroupSelect(this.state.groups, selected, this.props.profile, this.onChooseGroup);
    }

    renderActions() {
        const actions = [];

        actions.push(
            <a key="enroll-devices" className="btn btn-primary"
               href={ AdminRoutes.adminEnrollmentCodesPath({ format: null }) }>Enroll
                Devices</a>
        )

        actions.push(
            <a key="export-devices" className="btn btn-default export-devices-button"
               href={ AdminRoutes.adminDevicesPath({ format: 'csv' }) }>
                Export Devices
            </a>
        )

        let actionItems = [
            {
                href: "#assignToGroup",
                action: this.assignToGroup,
                text: "Assign To Group",
                policy: 'multipleAssignGroup',
            }
        ]
        if (this.props.query.group !== 'awaiting-enrollment') {
            actionItems = actionItems.concat([
                {
                    href: "#sendMessage",
                    action: this.showSendMessage,
                    text: "Send Message",
                    policy: 'sendMessage',
                },
                {
                    href: "#updateOS",
                    action: this.showUpdateOS,
                    text: "Update OS Version",
                    policy: 'updateOS',
                },
                {
                    href: "#setTimeZone",
                    action: this.showSetTZ,
                    text: "Set Time Zone",
                    policy: 'setTimeZone',
                },
                {
                    href: "#enableRemoteDesktop",
                    action: this.enableRemoteDesktop,
                    text: "Enable Remote Desktop",
                    policy: 'remoteDesktopActions',
                },
                {
                    href: "#disableRemoteDesktop",
                    action: this.disableRemoteDesktop,
                    text: "Disable Remote Desktop",
                    policy: 'remoteDesktopActions',
                },
                {
                    href: "#restart",
                    action: this.restart,
                    text: "Restart",
                    policy: 'restart',
                },
                {
                    href: "#shutDown",
                    action: this.shutdown,
                    text: "Shut Down",
                    policy: 'shutdown',
                },
                {
                    href: "#remove",
                    action: this.destroyMultiple,
                    text: "Remove",
                    policy: 'destroyMultiple',
                    afterDivider: true
                },
                {
                    href: "#runScript",
                    action: this.runScript,
                    text: "Run Script",
                    policy: 'runScripts',
                }
            ]);
        }

        actions.push(
            <ActionDropDown key="device-actions" policy={ this.props.policy } text="Actions" items={ actionItems }
                            disabled={ !this.props.hasCheckedItems() }/>
        )
        return actions;
    }


    shutdown(e) {
        e.preventDefault();
        if (confirm("This will shutdown the selected devices. Are you sure?")) {
            callWithIds(this.props.checkedItems(), devicesClient.shutdown)
        }
    }

    destroyMultiple(e) {
        e.preventDefault()
        if (confirm("This will unenroll and delete all selected devices. Are you sure?")) {
            callWithIds(this.props.checkedItems(), devicesClient.destroyMultiple)
        }
    }

    runScript(e) {
        e.preventDefault()
        const deviceIds = this.props.checkedItems()
            .filter(({ mac }) => mac)
            .map(({ id }) => id)

        if (deviceIds.length === 0) {
            window.addFlashAlert('error', 'Scripts can only be run on macOS devices.')
        } else {
            window.location = v2Route("jobs/new", { device_ids: deviceIds })
        }
    }

    enableRemoteDesktop(e) {
        e.preventDefault()
        const callChecked = this.props.checkedItems().map(({ id }) => {
            devicesClient.enableRemoteDesktop(id)
        })
        allSettled(callChecked).then(() => {
            window.addFlashAlert('notice', 'Enable Remote Desktop commands have been queued.')
        })
    }

    disableRemoteDesktop(e) {
        e.preventDefault()
        const callChecked = this.props.checkedItems().map(({ id }) => {
            devicesClient.disableRemoteDesktop(id)
        })
        allSettled(callChecked).then(() => {
            window.addFlashAlert('notice', 'Enable Remote Desktop commands have been queued.')
        })
    }

    renderSearchBar() {
        return <SearchBar
            searchOnEnter={ true }
            timeout={ 600 }
            searching={ this.props.searching }
            key={ this.props.query.device_id }
            initialValue={ this.props.query.search }
            placeholderText='Search by device name, serial number, IMEI, MAC address, or phone number.'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    togglePagedTableEditor(e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ editPagedTable: !this.state.editPagedTable })
    }

    pagedTableProps() {
        let headers;
        const isAwaitingEnrollmentGroup = this.props.query && this.props.query.group === "awaiting-enrollment";
        if (isAwaitingEnrollmentGroup) {
            headers = [
                { displayName: "Device Name", key: "name", orderable: true },
                { displayName: "Initial Group", key: "group", orderable: true },
                { displayName: "Model", key: "model", orderable: false },
                { displayName: "Status", key: "enrollmentStatus", orderable: true },
                { displayName: "Serial Number", key: "serialNumber", orderable: false },
                { displayName: "Enrollment", key: "enrollment", orderable: false }
            ]
        } else {
            headers = this.context.currentUser?.preferences?.deviceColumns || []
            if (this.props.query && this.props.query.group === 'quarantined') {
                headers.push({ displayName: "Violations", key: "violations", orderable: false })
            }
        }

        return {
            headers,
            renderRow: (row) => {
                const depDevice = this.state.depDevicesBySerial[row.serialNumber]
                const enrollmentCode = depDevice ? depDevice.enrollmentCode : row.enrollmentCode

                const name = <a
                    href={ AdminRoutes.adminDevicePath(row.id, { format: null }) }>{ row.name || row.serialNumber }</a>
                let deviceGroup = row.enrollmentStatus === 'awaiting enrollment' ? row.initialGroup : row.group
                const group = isEmpty(deviceGroup) ? "-" :
                    <a href={ AdminRoutes.adminGroupPath(deviceGroup.id, { format: null }) }>{ deviceGroup.name }</a>
                const violations = row.violations.map((violation, index) =>
                    <React.Fragment id={ index }>{ violation }<br/></React.Fragment>
                )
                const enrollment = enrollmentCode && !isEmpty(enrollmentCode) ?
                    <a href={ AdminRoutes.adminEnrollmentCodePath(enrollmentCode.id, { format: null }) }>{ enrollmentCode.name }</a> : null;
                const model = isAwaitingEnrollmentGroup && depDevice ? depDevice.appleModel : row.model
                const customAttributes = row.customAttributes.reduce((obj, customAttribute) => {
                    if(customAttribute.secret && customAttribute.value !== "••••••••••••") {
                        const dataFetcher = () => new Promise((resolver) => resolver([customAttribute.value]))
                        obj[customAttribute.name] = <PasswordReveal permitted={ this.props.policy.viewCustomAttributeSecrets && this.props.policy.viewCustomAttributeValues } dataFetcher={ dataFetcher }  />
                    } else {
                        obj[customAttribute.name] = customAttribute.value
                    }
                    return obj
                }, {});
                const lastSeenAt = <OverlayTrigger
                    placement="left"
                    overlay={ <Tooltip id={ "last-seen-tooltip" }>{ row.lastSeenAt }</Tooltip> }>
                    <span>{ row.lastSeenFriendly }</span>
                </OverlayTrigger>
                return { ...customAttributes, ...row, name, group, violations, enrollment, model, lastSeenAt }
            },
            togglePagedTableEditor: isAwaitingEnrollmentGroup ? null : this.togglePagedTableEditor,
        }
    }

    renderDevicesPagedTable() {
        let notFound;
        if (this.props.noDevices) {
            notFound = <div className="alert alert-info centered results-message" role="notice">
                Welcome to SimpleMDM! To get started, <strong><a
                href={ AdminRoutes.adminEnrollmentCodesPath({ format: null }) }>enroll
                your first device</a></strong>.
            </div>
        } else {
            notFound = <div className="alert alert-warning centered results-message" role="alert">
                no devices found
            </div>
        }

        const pagedTableEditor = this.state.editPagedTable ?
            <PagedTableEditor close={ this.togglePagedTableEditor }/> : null

        const closePagedTableEditor = (e) => {
            if (this.state.editPagedTable) {
                this.togglePagedTableEditor(e);
            }
        }

        return (
            <div onClick={ closePagedTableEditor }>
                <div className="special-container">
                    <h1><span className="glyphicon glyphicon-phone devices-icon"/>Devices</h1>
                    <div className="top-buttons">
                        { this.renderActions() }
                    </div>
                </div>
                <div className="devices">
                    <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                        { this.renderGroupSelect() }
                        { this.renderSearchBar() }
                    </div>
                    { pagedTableEditor }
                    { this.props.noDevices || this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                </div>
            </div>
        )
    }

    onAssignGroupSuccess() {
        this.displayDevicesTable()
        this.props.readData();
    }

    onOSUpdateSuccess() {
        this.displayDevicesTable()
    }

    onSetTimeZoneSuccess() {
        this.displayDevicesTable()
    }

    onCancelDialog() {
        this.displayDevicesTable()
    }

    renderOSUpdate() {
        return <OSUpdate
                         devices={ this.props.checkedItems() }
                         onCancel={ this.onCancelDialog }
                         onOSUpdate={ this.onOSUpdateSuccess }/>
    }

    renderAssignGroup() {
        return <AssignGroup groups={ this.state.groups }
                            deviceIds={ this.selectedDeviceIds() }
                            onCancel={ this.onCancelDialog }
                            onAssignGroup={ this.onAssignGroupSuccess }/>
    }

    renderSetTimeZone() {
        return <SetTimeZone
            devices={this.props.checkedItems()}
            onCancel={this.onCancelDialog}
            onFinish={this.onSetTimeZoneSuccess} />
    }

    selectedDeviceIds() {
        return this.props.checkedItems().map(item => item.id);
    }

    renderSendMessage() {
        return <SendMessage deviceIds={ this.selectedDeviceIds() }
                            onCancel={ this.onCancelDialog }
                            onSendMessage={ this.displayDevicesTable }/>
    }

    onRestartSuccess() {
        this.displayDevicesTable()
        this.props.readData();
    }

    renderRestart() {
        return <Restart deviceIds={ this.selectedDeviceIds() }
                        onCancel={ this.onCancelDialog }
                        onRestart={ this.onRestartSuccess }/>
    }


    displayDevicesTable(e) {
        this.pushRoute(AdminRoutes.adminDevicesPath({ ...this.props.query, format: null }), e)
    }

    assignToGroup(e) {
        this.pushRoute(AdminRoutes.assignGroupAdminDevicesPath({ ...this.props.query, format: null }), e)
    }

    showSendMessage(e) {
        this.pushRoute(AdminRoutes.sendMessageAdminDevicesPath({ ...this.props.query, format: null }), e)
    }

    showUpdateOS(e) {
        this.pushRoute(AdminRoutes.updateOSAdminDevicesPath({ ...this.props.query, format: null }), e)
    }

    showSetTZ(e) {
        this.pushRoute(AdminRoutes.setTimeZoneAdminDevicesPath({ ...this.props.query, format: null }), e)
    }

    restart(e) {
        this.pushRoute(AdminRoutes.restartAdminDevicesPath({ ...this.props.query, format: null }), e)
    }

    pushRoute(route, e) {
        if (e) {
            e.preventDefault()
        }
        pushURL(route)
        this.forceUpdate()
    }

    render() {
        const format = { format: null };
        const deviceCheck = (success) => {
            if (this.selectedDeviceIds().length === 0) {
                // window.addFlashAlert('error', `No devices selected`)
                replaceURL(AdminRoutes.adminDevicesPath({ ...this.props.queryParams, ...format }))
                return this.renderDevicesPagedTable();
            } else {
                return success()
            }
        }

        switch (window.location.pathname) {
            case AdminRoutes.assignGroupAdminDevicesPath(format):
                return deviceCheck(this.renderAssignGroup)
            case AdminRoutes.restartAdminDevicesPath(format):
                return deviceCheck(this.renderRestart)
            case AdminRoutes.sendMessageAdminDevicesPath(format):
                return deviceCheck(this.renderSendMessage)
            case AdminRoutes.updateOSAdminDevicesPath(format):
                return deviceCheck(this.renderOSUpdate)
            case AdminRoutes.setTimeZoneAdminDevicesPath(format):
                return deviceCheck(this.renderSetTimeZone)
            default:
                return this.renderDevicesPagedTable()
        }
    }
}

export default pagedTableLayout(DevicesPagedTable, getAjaxClient, initialQuery);