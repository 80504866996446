import React from 'react';
import { profiles as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, truncate } from '../../shared/functions';
import SearchBar from "../search/search_bar";

const getAjaxClient = () => ({
    ...ajaxClient,
    destroyMultiple: (profiles) => {
        return ajaxClient.destroyMultiple(profiles).then((responses) => {
            const failedResponses = responses.filter(({ status }) => status === 'rejected');
            const failureCount    = failedResponses.length;
            if (failureCount > 0) {
                const profile = failureCount > 1 ? 'profiles' : 'profile';
                window.addFlashAlert('error', `Failed to remove ${ failureCount } ${ profile }.`)
            } else {
                window.addFlashAlert('notice', 'Selected profiles have been removed')
            }

            return responses;
        })
    }
});

class ProfilesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.props.removeItems,
                text:   "Delete",
                policy: 'destroyMultiple'
            }
        ];
        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    onChooseProfileFilter(e) {
        this.props.setQuery({
            profile_filter: e.target.value,
            page:           1
        });
    }

    renderProfileFilter() {
        return (
            <select onChange={ this.onChooseProfileFilter }
                    value={ this.props.query.profile_filter }
                    className="form-control filter">
                <option value="all">All Profiles</option>
                <option value="hide-device-assigned">Hide Device-Assigned</option>
            </select>
        );
    }

    renderSearchBar() {
        return <SearchBar
            timeout={ 600 }
            searching={ this.props.searching }
            initialValue={ this.props.query.search }
            placeholderText='Filter by name or type'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    onSearchChange(searchRegex, search) {
        if (search != this.props.query.search) {
            this.props.setQuery({ search, page: 1, profile_filter: "all" })
        }
    }

    pagedTableProps() {
        const headers = [
            { displayName: "Name", key: "name" },
            { displayName: "Type", key: "type", orderable: true },
        ]

        if (this.props.policy.declarative === true) {
            headers.push({ displayName: "Declarative Enabled", key: "declarative" })
        }

        headers.push({ displayName: "Groups Assigned", key: "groups" })
        headers.push({ displayName: "Devices Assigned", key: "devices" })

        return {
            headers:   headers,
            showCheck: this.props.policy.configureProfiles === true,
            renderRow: (row) => {

                const name        = <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>;
                const type        = row.displayName;
                const groups      = this.deviceOrGroupLink(row, 'Group')
                const devices     = this.deviceOrGroupLink(row, 'Device')
                const declarative = row.declarative ? 'Yes' :  null
                return Object.assign({}, row, { name, groups, devices, type, declarative });
            },
        }
    }

    deviceOrGroupLink(row, itemType) {
        const count = row[`${itemType.toLowerCase()}Count`]

        if (count > 0) {
            const url = AdminRoutes[`admin${itemType}sPath`]({
                format:    null,
                saveQuery: false,
                profile_id: row.id
            })
            return <a href={ url }>{ count }</a>
        } else {
            return count.toString()
        }
    }

    render() {
        let notFound;

        if (this.props.query?.search == null) {
        notFound = <div className="centered alert alert-info" role="alert">
                No profiles have been created yet.
            </div>
        } else {
            notFound = <div className="centered alert alert-warning" role="alert">
                no profiles found
            </div>
        }

        const message = (
            <div className="row">
                <div className="col-xs-12"><p>Profiles can be assigned to both device groups and directly to devices.</p></div>
            </div>
        )

        const actions = (
            <React.Fragment>
                { this.renderActionButton() }
                <a href={ AdminRoutes.newAdminProfilePath({format: null}) } className="btn btn-primary pull-right">Create Profile</a>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <div className="special-container">
                    <h1><span className="glyphicon glyphicon-duplicate configs-icon"/>Profiles</h1>
                    <div className="top-buttons">
                        { actions }
                    </div>
                </div>

                <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                    { this.renderProfileFilter() }
                    { this.renderSearchBar() }
                </div>

                <div className="well">
                    <div id="profiles-table">
                        { message }
                        { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const initialQuery = {
    search:         null,
    order_d:        'asc',
    order:          'type',
    page:           1,
    per:            20,
    profile_filter: "all",
    saveQuery:      true
};
export default pagedTableLayout(ProfilesPagedTable, getAjaxClient, initialQuery);