import React from 'react'
import Grid from '@mui/material/Grid'
import { useTheme } from "@mui/material/styles"

const Container = (props) => {
  const theme = useTheme()

  return (
    <Grid
      backgroundColor={theme.simplemdm.colors.grey}
      container
      height="100%"
      justifyContent="center"
      {...props}
    />
  )
}

export default Container
