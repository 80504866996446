import React from 'react';
import { vppTokens as ajaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { callWithIds, bindMethods } from '../../shared/functions';

const getAjaxClient = () => ({
    ...ajaxClient,
    destroyMultiple: (vppTokens) => {
        return callWithIds(vppTokens, ajaxClient.destroyMultiple).then((responses) => {
            const failedResponses = responses.filter(({ status }) => status === 'rejected');
            const failureCount    = failedResponses.length;
            if (failureCount > 0) {
                const vppToken = failureCount > 1 ? 'vpp tokens' : 'vpp token';
                window.addFlashAlert('error', `Failed to remove ${ failureCount } ${ vppToken }.`)
            } else {
                window.addFlashAlert('notice', 'Selected vpp tokens have been removed')
            }

            return responses;
        })
    }
});

class VppTokensPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:         "#delete",
                action:       this.props.removeItems,
                text:         "Delete",
                policy:       'destroy',
            }
        ];
        return <ActionDropDown policy={ this.props.policy } text="Actions" items={ items }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    renderAdditionalActions() {
        const additionalActions = [];
        if (this.props.policy.create) {
            additionalActions.push(
                <a key="add-token" href={ AdminRoutes.newAdminVPPTokenPath({format: null}) } className="pull-right btn btn-primary">
                    Add Token
                </a>
            )
        }

        if (this.canRefresh()) {
            additionalActions.push(
                <a key="sync" href={ AdminRoutes.refreshAdminVPPTokensPath({format: null}) } className="pull-right btn btn-default">
                    Sync with Apple
                </a>
            )
        }

        return additionalActions;
    }

    canRefresh() {
        return this.props.policy.refresh && this.props.data.length > 0
    }

    tokenIdentifierHeader() {
        if (this.props.v2Enabled) {
            return { displayName: "Location Name", key: "locationName" }
        } else {
            return { displayName: "Apple ID", key: "appleId" }
        }
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Organization Name", key: "organizationName" },
                this.tokenIdentifierHeader(),
                { displayName: "Licenses", key: "licenses" },
                { displayName: "Status", key: "status" },
                { displayName: "", key: "renew" },
            ],
            renderRow: (row) => {
                let status;
                if (row.tokenRejected) {
                    status = (
                        <span className="label label-danger label-rejected">
                            <span className="glyphicon glyphicon-alert"/>
                            Rejected by Apple
                        </span>
                    )
                } else if (row.expired) {
                    status = (
                        <span className="label label-danger label-rejected">
                            <span className="glyphicon glyphicon-alert"/>
                            Expired { row.expirationDate }
                        </span>
                    )
                } else {
                    status = `Expires ${ row.expirationDate }`
                }
                const renew =  <a href={ AdminRoutes.adminVPPTokenPath(row.id, {format: null}) } className='btn btn-sm btn-default pull-right'>
                    Renew
                </a>;
                return { ...row, status, renew }
            }
        }
    }

    renderMessage() {
        let message = (
            <p>
                Managed distribution allows you to assign and remove app licenses to iOS devices using
                Apple's Volume Purchase Program.
                If you are enrolled in Apple's Volume Purchase Program, you can retrieve your managed
                distribution
                token
                from the <a
                href="https://business.apple.com/#/main/preferences/paymentsandbilling/appsandbooks"
                target="_blank"
                rel="noopener no-referrer">
                Volume Purchase Program Portal</a>.
            </p>
        );
        if (this.props.v2Enabled) {
           message = (
               <React.Fragment>
                   { message }
                   <p>
                       <strong>Note:</strong>
                       If you purchase licenses from Apple after adding your token, it can take a few minutes before they appear in SimpleMDM.
                   </p>
               </React.Fragment>
           )
        } else if (this.props.policy.create || this.canRefresh()) {
            message = (
                <React.Fragment>
                    { message }
                    <p>
                        <strong>Note:</strong>
                        If you purchase licenses from Apple after adding your token, you will need to click
                        the <em>Sync with Apple</em> button above to make
                        SimpleMDM aware of the change.
                    </p>
                </React.Fragment>
            )
        }
        return message;
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No VPP tokens have been added yet.
            </div>
        );

        const actions = (
            <div className="button-list-container">
                { this.renderAdditionalActions() }
                { this.renderActionButton() }
            </div>
        );

        return (
            <div id="vpp-tokens-table">
                { this.props.renderTopSection(this.renderMessage(), actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(VppTokensPagedTable, getAjaxClient);