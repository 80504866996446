import React from 'react';
import PropTypes from 'prop-types'
class PasswordRevealInput extends React.Component {

    constructor(props) {
        super(props);
        this.isRevealed         = this.isRevealed.bind(this);
        this.renderRevealButton = this.renderRevealButton.bind(this);
        this.renderValue        = this.renderValue.bind(this);
    }

    isRevealed() {
        return !!this.props.revealedValue;
    }

    renderRevealButton() {
        if (this.isRevealed()) {
            return <a onClick={ this.props.hideHandler } href="#">hide</a>
        } else {
            return <a onClick={ this.props.revealHandler } href="#">reveal</a>
        }
    }

    renderValue() {
        if (this.isRevealed()) {
            return this.props.revealedValue;
        } else {
            return this.props.initialValue;
        }
    }

    render() {
        return (
            <div className="form-group password_reveal optional readonly">
                <label className="col-sm-2 control-label password_reveal optional">
                    { this.props.label }
                </label>
                <div className="col-sm-10">
                    <input
                        value={ this.renderValue() }
                        className="password_reveal optional readonly form-control"
                        readOnly="readonly"
                        type={ this.isRevealed() ? "text" : "password" }
                    />
                    { this.renderRevealButton() }
                </div>
            </div>
        )
    }
}

PasswordRevealInput.propTypes    = {
    label:         PropTypes.string.isRequired,
    initialValue:  PropTypes.string.isRequired,
    revealedValue: PropTypes.string,
    revealHandler: PropTypes.func.isRequired,
    hideHandler:   PropTypes.func.isRequired,
};

PasswordRevealInput.defaultProps = {
    initialValue: "1234567890abcdefghijkl".repeat(3)
};

export default PasswordRevealInput;