const wireAndUpdateForm = function() {
    $('.firewall_enable_firewall, .firewall_block_all_incoming')
        .find('select')
        .on('change', toggleEnableFirewallElements);
    toggleEnableFirewallElements();
};

const toggleEnableFirewallElements = function() {
    toggleEnableFirewall();
    toggleBlockAllIncoming();
    toggleDisplayOfFirewallApplications();
};

const isFirewallEnabled = function() {
    return $('.firewall_enable_firewall select').val() === 'true';
};

const areAllIncomingBlocked = function() {
    return $('.firewall_block_all_incoming select').val() === 'true';
};

const toggleEnableFirewall = function() {
    const $formGroups = $('.form-group').not('.firewall_enable_firewall, .firewall_name, .firewall_is_device_profile');
    hideAndDisableFormGroups($formGroups, !isFirewallEnabled());
};

const toggleBlockAllIncoming = function() {
    const areBlocked = areAllIncomingBlocked();
    if (areBlocked) {
        $('.firewall_enable_stealth_mode select').val('true').find('option:not(:selected)').attr('disabled', true);
    } else {
        $('.firewall_enable_stealth_mode option:not(:selected)').attr('disabled', false);
    }
};

const toggleDisplayOfFirewallApplications = function() {
    const shouldHide = (!isFirewallEnabled()) || areAllIncomingBlocked();
    hideAndDisableFormGroups($('.firewall-applications'), shouldHide);
};

const hideAndDisableFormGroups = function($formGroups, hide) {
    if (hide) {
        $formGroups.hide().find('select, input').attr('disabled', true);
    } else {
        $formGroups.show().find('select, input').attr('disabled', false);
    }
};

export const firewalls = {
    new:    function() {
        wireAndUpdateForm();
    },
    show:   function() {
        wireAndUpdateForm();
    },
    create: function() {
        wireAndUpdateForm();
    },
    update: function() {
        wireAndUpdateForm();
    }
};
