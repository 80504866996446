import React from 'react';
import PropTypes from 'prop-types';
import { bindMethods } from "../../shared/functions";

export default class Requirements extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this)
    }

    render() {
        if (this.props.requirements.length === 0 && this.props.requirementNotes.length === 0) {
            return null
        }
        let requirements = null;
        if (this.props.requirements && this.props.requirements.length > 0) {
            requirements = <tr className="requirements">
                <td>Requires</td>
                <td>
                    { this.props.requirements.map(requirement => <div key={ requirement }>{ requirement }</div>) }
                </td>
            </tr>
        }

        let requirementNotes = null;
        if (this.props.requirementNotes.length > 0) {
            requirementNotes = <tr className="notes">
                <td>Note</td>
                <td>
                    { this.props.requirementNotes.map(note => <p key={ note }>{ note }</p>) }
                </td>
            </tr>
        }
        return <div id="requirements-and-notes" className="alert alert-info">
            <table>
                <tbody>
                    { requirements }
                    { requirementNotes }
                </tbody>
            </table>
        </div>
    }
}


Requirements.proptypes = {
    requirementNotes: PropTypes.arrayOf(PropTypes.string).isRequired,
    requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
}