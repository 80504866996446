import { accounts } from "./accounts.js";
import { accountProviders } from "./account_providers";
import { adminNotifications } from "./admin_notifications";
import { apiKeys } from './api_keys';
import { appGroups } from './app_groups';
import { appIdentifiers } from './app_identifiers';
import { appInstalledApplications } from './app_installed_applications';
import { apps } from './apps';
import { appVolumePurchaseCodes } from './app_volume_purchase_codes';
import { appVppLicenses } from './app_vpp_licenses';
import { assignmentGroups } from './assignment_groups';
import { customAttributes } from "./custom_attributes";
import { depAccounts } from './dep_accounts';
import { depDevices } from './dep_devices';
import { deviceAccounts } from './device_accounts';
import { deviceInstalledApplications } from "./device_installed_applications";
import { deviceInstalledMedia } from "./device_installed_media";
import { deviceUsers } from './device_users';
import { devices } from './devices';
import { deletedDevices } from './deleted_devices';
import { directMultipartUpload } from './directMultipartUpload';
import { enrollmentCodes } from './enrollment_codes';
import { groups } from './groups';
import { logs } from './logs';
import { media } from './media'
import { mediaInstalledMedia } from './media_installed_media'
import { mediaVppLicenses } from './media_vpp_licenses'
import { profiles } from "./profiles";
import { scripts } from "./scripts";
import { scriptCommands } from "./script_commands";
import { scriptJobs } from "./script_jobs";
import { timeZones } from "./time_zones";
import { userPermissions } from './user_permissions'
import { users } from './users'
import { userRoles } from './user_roles'
import { vppTokens } from "./vpp_tokens";
import { webhookEndpoints } from "./webhook_endpoints";

export {
    accounts,
    accountProviders,
    adminNotifications,
    apiKeys,
    appGroups,
    appIdentifiers,
    appInstalledApplications,
    apps,
    appVppLicenses,
    appVolumePurchaseCodes,
    assignmentGroups,
    customAttributes,
    depDevices,
    depAccounts,
    deviceAccounts,
    deviceInstalledApplications,
    deviceInstalledMedia,
    deviceUsers,
    devices,
    deletedDevices,
    directMultipartUpload,
    enrollmentCodes,
    groups,
    logs,
    media,
    mediaInstalledMedia,
    mediaVppLicenses,
    profiles,
    scripts,
    scriptCommands,
    scriptJobs,
    timeZones,
    users,
    userPermissions,
    userRoles,
    vppTokens,
    webhookEndpoints,
}

