import React from 'react';
import { appVppLicenses as appAjaxClient, mediaVppLicenses as mediaAjaxClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds } from '../../shared/functions';
import pluralize from 'pluralize';

const getAjaxClient = ({ appId, mediaId }) => {
    const callWithAssetIdAndIds = (func, assetId) => {
        return (vppLicenses) => callWithIds(vppLicenses, (ids) => func(assetId, ids));
    };
    if (appId) {
        return {
            read:            (query) => appAjaxClient.read(appId, query),
            destroyMultiple: callWithAssetIdAndIds(appAjaxClient.destroyMultiple, appId),
        }
    } else if (mediaId) {
        return {
            read:            (query) => mediaAjaxClient.read(mediaId, query),
            destroyMultiple: callWithAssetIdAndIds(mediaAjaxClient.destroyMultiple, mediaId),
        }
    }
};

class VppLicensesPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Revoke Licenses",
                policy: 'destroy',
            }
        ];
        return <div className="button-list-container">
            <ActionDropDown text="Actions"
                            items={ items }
                            policy={ this.props.policy }
                            disabled={ !this.props.hasCheckedItems() }/>
        </div>
    }

    removeItems(e) {
        this.props.removeItems(e, "This will revoke the selected licenses. Are you sure?");
    }

    pagedTableProps() {
        const props = {
            headers:   [
                { displayName: 'Associated Devices', key: 'associatedDevices' },
                { displayName: 'Assignment Type', key: 'assignmentType' },
            ],
            showCheck: this.props.policy.edit && this.props.policy.destroy,
            renderRow: (row) => {
                let associatedDevices;

                if (row.associatedDevices.length === 0) {
                    associatedDevices = row.associatedByDevice ? "Unmanaged device" : "Unknown Apple ID"
                } else {
                    associatedDevices = row.associatedDevices.map(associatedDevice => <a
                        href={ AdminRoutes.adminDevicePath(associatedDevice.id, {format: null}) } key={associatedDevice.id}>{ associatedDevice.name }</a>)
                }
                return Object.assign({}, row, { associatedDevices });
            },
        };

        return props
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                No assigned licenses found
            </div>
        );

        const message = <p>
            { this.props.licensesAvailable } of { this.props.totalLicenses } { pluralize('license', this.props.totalLicenses) } available. Licenses in use:
        </p>

        let actions = this.renderActionButton();

        return (
            <React.Fragment>
                { this.props.renderTopSection(message, actions, { leftSize: 10, rightSize: 2 }) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(VppLicensesPagedTable, getAjaxClient, undefined, null);