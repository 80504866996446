export const media = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminMediaPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminMediaIndexPath(idOrOptions));
        }
    },

    directUploadMediaCallback(fileName, uuid) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.newMediaCallbackAdminMediaIndexPath(),
            data:     JSON.stringify({ fileName, uuid })
        });
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.destroyMultipleAdminMediaIndexPath(),
            data:     JSON.stringify({ ids })
        });

    }

};
