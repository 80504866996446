import allSettled from "promise.allsettled";

export const profiles = {
    read(options) {
        return $.getJSON(AdminRoutes.adminProfilesPath(options));
    },

    readForDevice(deviceId, options) {
        return $.getJSON(AdminRoutes.adminDeviceProfilesPath(deviceId, options));
    },

    readForGroup(groupId, options) {
        return $.getJSON(AdminRoutes.adminGroupProfilesPath(groupId, options));
    },

    types(options) {
        return $.getJSON(AdminRoutes.typesAdminProfilesPath(options));
    },

    destroyMultiple(profiles) {
        return allSettled(profiles.map(profile => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      profile.originalRecord
        })));
    }
};