import React from 'react';
import pagedTableLayout from '../paged_table/layout';
import { bindMethods } from '../../shared/functions';
import {
    profiles as profilesClient,
    devices as devicesClient,
    groups as groupsClient
} from '../../ajax_clients';
import { requirementColumns } from "./profile_utilities";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const getAjaxClient = ({ assignableObject }) => {
    const client                  = {
        read: profilesClient.types
    };
    if (assignableObject) {
        let objectClient
        objectClient                  = assignableObject.type === 'group' ? groupsClient : devicesClient;
        client.readProfileAssignments = () => objectClient.readProfileAssignments(assignableObject.id)
    }
    return client
};

class NewProfilePagedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileAssignments: {},
            loading:            false
        }
        bindMethods(this);
    }


    componentDidMount() {
        this.loadAssignedProfiles()
    }

    loadAssignedProfiles() {
        if (this.props.ajaxClient.readProfileAssignments) {
            this.setState({ loading: true }, () => {
                this.props.ajaxClient.readProfileAssignments().then(({ data }) => {
                    const profileAssignments = {}
                    data.forEach(profileType => {
                        profileType.hasDirectAssignment      = profileType.profilesToInstall.some(profile => profile.directAssignment);
                        profileType.hasSingularInstallation  = profileType.hasDirectAssignment && profileType.singletonProfile
                        profileAssignments[profileType.type] = profileType
                    });

                    this.setState({ profileAssignments, loading: false });
                })
            })
        }
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Type", key: "displayName" },
                { displayName: "macOS", key: "macos" },
                { displayName: "iOS", key: "ios" },
                { displayName: "tvOS", key: "tvos" }
            ],
            showCheck: false,
            renderRow: (row) => {
                const incompatibleProfile     = !row.installableTo && this.props.assignableObject === 'device'
                const assignment              = this.state.profileAssignments[row.type]
                const hasSingularInstallation = assignment?.hasDirectAssignment && row.singletonProfile
                const disabled                = incompatibleProfile || hasSingularInstallation
                let displayName
                if (disabled) {
                    let disabledText;
                    if (incompatibleProfile) {
                        disabledText = "Profile is not compatible with this device."
                    } else if (hasSingularInstallation) {
                        disabledText = "Devices only support a single profile of this type and one has already been assigned."
                    }

                    displayName = <OverlayTrigger placement="right" overlay={ <Tooltip id={ row.id }>{ disabledText }</Tooltip> }>
                        <span>{ row.displayName }</span>
                    </OverlayTrigger>
                } else {
                    displayName = <a href={ row.newProfilePath }>{ row.displayName }</a>
                }
                const requirements = requirementColumns(row)
                return { ...requirements, displayName }
            },
        }
    }

    render() {
        const assignableObject = this.props.assignableObject;
        const objectName       = assignableObject ? <small>{ assignableObject.name }</small> : null
        const backPath         = assignableObject ? assignableObject.location : AdminRoutes.adminProfilesPath({format: null})
        return (
            <React.Fragment>
                <h1>
                    <span className="glyphicon glyphicon-duplicate configs-icon"/>
                    Create Profile { objectName }
                </h1>

                <div id="top-buttons">
                    <a className="btn btn-default back-button" href={ backPath }>
                        <i className="glyphicon glyphicon-chevron-left"/> Back
                    </a>
                </div>

                <div className="well">
                    <div id="assign-profiles-table">
                        { this.props.renderPagedTable(this.pagedTableProps()) }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(NewProfilePagedTable, getAjaxClient);