export const pushCertificates = {
  create(appleId, file) {
    const formData = new FormData()
    if (appleId != null) {
      formData.append("push_certificate[apple_id]", appleId)
    }
    if (file != null) {
      formData.append("push_certificate[file]", file)
    }

    return $.post({
      cache: false,
      contentType: false,
      data: formData,
      method: 'POST',
      processData: false,
      url: AdminRoutes.adminPushCertificatePath()
    })
  },
}
