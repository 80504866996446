import React from 'react';
import DynamicList from "../shared/dynamic_list";

export default class VendorConfig extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    this.headers = [
      { displayName: 'Key', key: 'key' },
      {
        displayName: 'Value Type',
        key: 'valueType',
        options: [
          'boolean',
          'date',
          'float',
          'float array',
          'integer',
          'integer array',
          'string',
          'string array',
        ].map(type => ({ displayName: type, value: type }))
      },
      { displayName: 'Value', key: 'value' },
    ];
    this.onChangeItem = this.onChangeItem.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
  }

  onChangeItem(updatedItem, updatedIndex) {
    this.setState({
      data: this.state.data.map((row, index) => index === updatedIndex ? updatedItem : row)
    })
  }

  onRemoveItem(_removedItem, removedIndex) {
    this.setState({
      data: this.state.data.filter((_item, index) => index !== removedIndex)
    });
  }

  onAddItem() {
    const newItem = {
      key: "",
      value: ""
    };

    this.setState({
      data: [...this.state.data, newItem]
    })
  }

  render() {
    return <DynamicList
      fieldName={'web_content_filter[vendor_config]'}
      headers={this.headers}
      rows={this.state.data}
      policy={this.props.policy}
      onChangeItem={this.onChangeItem}
      onRemoveItem={this.onRemoveItem}
      onAddItem={this.onAddItem}
    />
  }
}