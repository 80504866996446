import React from 'react';
import PropTypes from 'prop-types';

class InfoTip extends React.Component {
    render() {
        return  <i className="infotip glyphicon glyphicon-question-sign"
                   rel="tooltip"
                   data-toggle="tooltip"
                   data-placement={ this.props.position }
                   title={ this.props.message } />
    }
}


InfoTip.propTypes = {
    message: PropTypes.string.isRequired,
    position: PropTypes.string
};

InfoTip.defaultProps = {
    position: 'top'
};

export default InfoTip;
