import React from 'react';
import { userPermissions as userPermissionsClient, userRoles as userRolesClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import pagedTableLayout from '../paged_table/layout';
import Select from '../shared/select';
import { bindMethods, callWithIds } from "../../shared/functions";

const getAjaxClient = () => {
    return {
        ...userPermissionsClient,
        destroyMultiple: (userPermissions) => {
            return callWithIds(userPermissions, userPermissionsClient.destroyMultiple)
                .then((responses) => {
                    const failedResponses = responses.filter(({ status }) => status === 'rejected');
                    const failureCount    = failedResponses.length;
                    if (failureCount > 0) {
                        const user = failureCount > 1 ? 'users' : 'user';
                        window.addFlashAlert('error', `Failed to remove ${ failureCount } ${ user }.`)
                    } else {
                        window.addFlashAlert('notice', 'Selected users have been removed')
                    }
                    return responses;
                })
        },
    }
};

class UsersPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userRoles: []
        };
        bindMethods(this)
    }

    componentDidMount() {
        this.readUserRoles();
    }

    readUserRoles() {
        userRolesClient.read({ per: 'all' })
            .then(
                ({ data }) => this.setState({ userRoles: data })
            )
    }

    removeItems(e) {
        const checkedItems = this.props.checkedItems();
        const hasSsoUsers  = checkedItems.some(item => item.user.sso);
        let confirmMessage;
        if (hasSsoUsers) {
            confirmMessage = "This will delete the selected users from your account. For SSO (SAML) users, you will need to use your identity provider to block future access.\n\nWould you like to continue?"
        } else {
            confirmMessage = "This will delete the selected users from your account.\n\nWould you like to continue?"
        }

        this.props.removeItems(e, confirmMessage);
    }

    disableTwoFactor(e) {
        e.preventDefault()

        const checkedItems = this.props.checkedItems()
        const hasSsoUsers  = checkedItems.some(item => item.user.sso)

        let confirmMessage;
        if (hasSsoUsers) {
            confirmMessage = "This will disable two factor authentication for the selected users. SSO (SAML) users will not be affected.\n\nAre you sure?"
        } else {
            confirmMessage = "This will disable two factor authentication for the selected users.\n\nAre you sure?"
        }

        if (confirm(confirmMessage)) {
            this.props.callWithItems('disable2FaMultiple');
        }
    }

    renderActionButton() {
        if (!this.props.policy.edit) {
            return null;
        }
        const items = [
            {
                href:   "#delete",
                action: this.removeItems,
                text:   "Revoke Access"
            }
        ];

        return <ActionDropDown text="Actions" items={ items } policy={ this.props.policy }
                               disabled={ !this.props.hasCheckedItems() }/>
    }

    selectRoleForPermission(selectedPermission, selectedRoleId) {
        const matchingRole = this.state.userRoles.find((role) => role.id === selectedRoleId);
        const index        = this.props.data.findIndex(permission => permission.id === selectedPermission.id);
        userPermissionsClient.update(selectedPermission.id, { userRole: matchingRole })
            .then(({ data }) => this.props.updateItem(index, data));
    }

    renderRoleSelect(permission, mappedRoles) {
        if (mappedRoles.length === 0) {
            return permission.userRole.name
        } else {
            return <Select
                options={ mappedRoles }
                selectedValue={ permission.userRole.id.toString() }
                onChange={ (selectedRoleId) => this.selectRoleForPermission(permission, parseInt(selectedRoleId)) }
            />
        }
    }

    pagedTableProps() {
        const showTwoFactor = this.props.policy.show2fa
        const headers = [
            { displayName: "Email", key: "email" },
            { displayName: "Type", key: "type" },
            { displayName: "Role", key: "role" }
        ]
        if (showTwoFactor) {
            headers.push({ displayName: "Two Factor Authentication", key: 'twoFactor' })
        }
        return {
            headers: headers,
            renderRow: (row) => {
                const mappedRoles = this.state.userRoles.map(({ id, name: text }) => ({ text, value: id.toString() }));

                const rowValues = {
                    ...row,
                    email:      row.user.email,
                    role:       row.owner ? 'Owner' : this.renderRoleSelect(row, mappedRoles),
                    checked:    row.checked && !row.checkDisabled
                }

                if (showTwoFactor) {
                    const { user: { otpEnabled, sso } } = row
                    rowValues.twoFactor = sso ? '' : (otpEnabled ? 'Enabled' : 'Disabled')
                }
                return rowValues
            }
        };
    }

    renderChangeOwnerButton() {
        if (this.props.policy.showOwner) {
            return <a href={ AdminRoutes.ownerAdminUserManagementPath({format: null}) }
                          className="pull-right btn btn-default">Change Owner</a>;
        }
    }

    render() {
        const notFound = (
            <div className="centered alert alert-info" role="alert">
                Unable to load users.
            </div>
        );

        const message = <p>You can grant other individuals access to this SimpleMDM account.</p>;
        let actions   = <React.Fragment>
            { this.renderActionButton() }
            { this.renderChangeOwnerButton() }
        </React.Fragment>;

        return (
            <div id="apps-table">
                { this.props.renderTopSection(message, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(
    UsersPagedTable,
    getAjaxClient,
    undefined,
    AdminRoutes.adminUserPermissionsPath({format: null})
);