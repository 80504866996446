import allSettled from "promise.allsettled";

export const scripts = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminScriptPath(Object.assign({}, idOrOptions, {format: 'json'})));
        } else {
            return $.getJSON(AdminRoutes.adminScriptsPath(Object.assign({}, idOrOptions, {format: 'json'})));
        }
    },

    destroyMultiple(ids) {
        return allSettled(ids.map(id => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      AdminRoutes.adminScriptPath(id)
        })));
    }

};