import Turbolinks from "turbolinks";

export const pushURL = (url) =>
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid());

export const replaceURL = (url) =>
    Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid());

export const visitURL = (url) =>
    Turbolinks.controller.visit(url);

const urlForSearchParams = (params) => {
    const { search, hash } = window.location;
    if (!/^\?/.test(params)) {
        params = "?" + params;
    }

    if (params === "?" && search === "") {
        return;
    }
    return `${ window.location.pathname }${ params }${ hash }`;
};

export const parseQuery = (query = window.location.search) => {
    const params = new URLSearchParams(query);
    return Object.fromEntries([...params.entries()]);
};

export const queryObjectToParams = (queryObject) => {
    queryObject = Object.assign({}, queryObject);
    Object.keys(queryObject).forEach((key) => {
        if (queryObject[key] === undefined || queryObject[key] === null) {
            delete queryObject[key];
        }
    });
    return "?" + $.param(queryObject).replace("%20", "+");
};

export const setQueryHistory = (params, replace = false) => {
    const url      = urlForSearchParams(params);
    let { search } = window;
    if (params !== search) {
        if (replace || search === "") {
            replaceURL(url)
        } else {
            pushURL(url);
        }
    }
};




