export const appInstalledApplications = {
    read(appId, options) {
        return $.getJSON(AdminRoutes.adminAppInstalledApplicationsPath(appId, options));
    },

    readForAppGroup(appGroupId, options) {
        return $.getJSON(AdminRoutes.adminAppGroupInstalledApplicationsPath(appGroupId, options));
    },

    destroyMultiple(appId, ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.destroyMultipleAdminAppInstalledApplicationsPath(appId),
            data:        JSON.stringify({ ids })
        })
    },

    destroyMultipleForAppGroup(appGroupId, ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.destroyMultipleAdminAppGroupInstalledApplicationsPath(appGroupId),
            data:        JSON.stringify({ ids })
        })
    },

    updateMultiple(appGroupId, ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.updateMultipleDevicesAdminAppGroupInstalledApplicationsPath(appGroupId),
            data:        JSON.stringify({ ids })
        })
    },

};
