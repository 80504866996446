import passwordRevealInput from '../../shared/password_reveal_input'

export const web_content_filters = {
    new() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },
    show() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },
    create() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },
    update() {
        this.fn.wireAndUpdateForm()
        passwordRevealInput()
    },
    fn: {
        updateBuiltinFormFields() {
            // inputs
            let allowList
            /* This has to be elaborate because jquery cant get .val() in cases where
                           the form has been disabled (due to user permissions) */
            const selectObject = document.getElementById('web_content_filter_allow_list')
            if (selectObject.value != null) {
                allowList = selectObject.value === "true"
            } else {
                allowList = selectObject.querySelector('option[selected]').value
            }

            const autoFilterVal = document.getElementById('web_content_filter_auto_filter')?.checked

            // form elements
            const autoFilter  = document.querySelector('.web_content_filter_auto_filter')
            const permitted   = document.querySelector('.web_content_filter_permitted_urls')
            const denyListed  = document.querySelector('.web_content_filter_deny_list_urls')
            const bookmarks   = document.querySelector('.bookmarks-container')

            // The 'new' action doesn't include this part of the form
            if (autoFilter !== null) {
                if (allowList) {
                    autoFilter.hidden = true
                    permitted.hidden = true
                    denyListed.hidden = true
                    bookmarks.hidden = false
                } else {
                    autoFilter.hidden = false
                    if (autoFilterVal) {
                        permitted.hidden = false
                    } else {
                        permitted.hidden = true
                    }
                    denyListed.hidden = false
                    if (bookmarks) {
                        bookmarks.hidden = true
                    }
                }
            }
        },
        updatePluginFormFields() {
            const filterPackets = document.getElementById('web_content_filter_filter_packets')
            const filterSockets = document.getElementById('web_content_filter_filter_sockets')
            const packetOptions = document.querySelector('.packet-filter-options')
            const socketOptions = document.querySelector('.socket-filter-options')

            packetOptions.hidden = !filterPackets.checked
            socketOptions.hidden = !filterSockets.checked
        },
        toggleMacOSSupport(enabled) {
           const macOSProfileScope = document.getElementById('web_content_filter_allowed_platforms_macos')
           const macOSVersionScope = document.querySelectorAll('.macos-scope select')
           const macOSUserProfile  = document.querySelector('.web_content_filter_is_device_profile')

           macOSProfileScope.disabled = !enabled
           macOSUserProfile.hidden = !enabled

           if (!enabled) {
               macOSProfileScope.checked = false
               macOSVersionScope.forEach((item) => item.disabled = true)
           }
        },
        updateForm() {
            // Filter type adjusts which form fields are shown
            const filterType     = document.getElementById('web_content_filter_filter_type')
            const builtInOptions = document.getElementById('builtin-options')
            const pluginOptions  = document.getElementById('plugin-options')
            const bookmarks      = document.querySelector('.bookmarks-container')

            if (filterType.value == 'BuiltIn') {
                builtInOptions.hidden = false
                pluginOptions.hidden = true
                this.toggleMacOSSupport(false)
                this.updateBuiltinFormFields()
            } else {
                builtInOptions.hidden = true
                pluginOptions.hidden = false
                if (bookmarks) {
                    bookmarks.hidden = true
                }
                this.toggleMacOSSupport(true)
                this.updatePluginFormFields()
            }
        },
        wireAndUpdateForm() {
            this.updateForm()

            document.getElementById('web_content_filter_filter_type').addEventListener('change', this.updateForm.bind(this))

            document.querySelectorAll('#web_content_filter_allow_list, #web_content_filter_auto_filter').forEach((element) => {
              element.addEventListener('change', this.updateBuiltinFormFields.bind(this))
            })

            document.querySelectorAll('#web_content_filter_filter_packets, #web_content_filter_filter_sockets').forEach((element) => {
              element.addEventListener('change', this.updatePluginFormFields.bind(this))
            })
        }
    }
}