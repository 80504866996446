import passwordRevealInput from '../../shared/password_reveal_input'

const wireAndUpdateForm         = () => {

    const generatePassword = $('#firmware_password_generate_password');

    function updateForm() {
        const passwordInput = $('div.form-group.firmware_password_password')

        if (generatePassword.is(':checked')) {
            passwordInput.hide();
        } else {
            passwordInput.show();
        }
    }

    generatePassword.change(updateForm);

    updateForm();

}
export const firmware_passwords = {
    new:    function() {
        wireAndUpdateForm();
        passwordRevealInput()
    },
    show:   function() {
        wireAndUpdateForm();
        passwordRevealInput()
    },
    create: function() {
        wireAndUpdateForm();
        passwordRevealInput()
    },
    update: function() {
        wireAndUpdateForm();
        passwordRevealInput()
    }
};
