import React from "react";
import { bindMethods, dig } from "../../shared/functions";
import Requirements from "../shared/requirements";
import FormGroup from "../forms/form_group";
import Checkbox from "../forms/checkbox";
import AppNotificationSetting from "./app_notification_setting";
import AppNotificationSettingsPagedTable from "./paged_table";
import { allNotificationSettings as ajaxClients } from "../../ajax_clients/profile_data/notification_settings";
import {
  getAjaxClient,
  successHandler,
  errorHandler,
  backPath,
} from "../profiles/profile_utilities";
import { visitURL } from "../../shared/query";
import ActionDropDown from "../shared/action_drop_down";
import ProfileScope from "../profiles/profile_scope";

export default class NotificationSetting extends React.Component {
  constructor(props) {
    super(props);
    const { name, description, isDeviceProfile, appNotificationSettings } =
      props.data;

    this.state = {
      name,
      description,
      isDeviceProfile,
      appNotificationSettings,
      minimumMacosVersion: props.data.minimumMacosVersion,
      maximumMacosVersion: props.data.maximumMacosVersion,
      allowedMacosArchitecture: props.data.allowedMacosArchitecture,
      allowedPlatforms: props.data.allowedPlatforms,
      reinstallAfterOSUpdate: props.data.reinstallAfterOSUpdate,
      editedAppIndex: null,
    };
    this.ajaxClient = getAjaxClient(props.assignableObject, ajaxClients);
    bindMethods(this);
  }

  setName(e) {
    this.setState({ name: e.target.value });
  }

  setDescription(e) {
    this.setState({ description: e.target.value });
  }

  setIsDeviceProfile(e) {
    const isDeviceProfile = e.target.checked;
    this.setState({ isDeviceProfile });
  }

  deleteEditedApp() {
    if (this.state.editedAppIndex !== -1) {
      this.deleteApps(this.state.editedAppIndex);
    }
    this.editApp(null);
  }

  deleteApps(indexes) {
    if (!Array.isArray(indexes)) {
      indexes = [indexes];
    }
    const appNotificationSettings = this.state.appNotificationSettings.filter(
      (_appNotificationSetting, index) => !indexes.includes(index)
    );
    this.setState({ appNotificationSettings });
  }

  addApp(e) {
    e.preventDefault();
    const newAppNotificationSetting =
      AppNotificationSetting.newAppNotificationSetting();
    this.setState({ newAppNotificationSetting }, () => this.editApp(-1));
  }

  editApp(editedAppIndex) {
    this.setState({ editedAppIndex });
  }

  checkAppNotificationSetting(index, checked) {
    const appNotificationSettings = this.state.appNotificationSettings.map(
      (appNotificationSetting, index2) => {
        if (index === "all" || index === index2) {
          return Object.assign({}, appNotificationSetting, { checked });
        } else {
          return appNotificationSetting;
        }
      }
    );
    this.setState({ appNotificationSettings });
  }

  completeAddApp(appNotificationSetting) {
    let appNotificationSettings;

    if (this.state.editedAppIndex === -1) {
      appNotificationSettings = [
        ...this.state.appNotificationSettings,
        appNotificationSetting,
      ];
    } else {
      appNotificationSettings = this.state.appNotificationSettings.map(
        (existingAppNotificationSetting, index) =>
          index === this.state.editedAppIndex
            ? appNotificationSetting
            : existingAppNotificationSetting
      );
    }
    this.setState({ appNotificationSettings });
    this.editApp(null);
  }

  cancelAddApp() {
    this.editApp(null);
  }

  onSave(e) {
    e.preventDefault();
    this.setState({ errors: null });
    let notificationSetting = {
      name: this.state.name,
      description: this.state.description,
      isDeviceProfile: this.state.isDeviceProfile,
      appNotificationSettings: this.state.appNotificationSettings,
      minimumMacosVersion: this.state.minimumMacosVersion.version,
      maximumMacosVersion: this.state.maximumMacosVersion.version,
      allowedMacosArchitecture: this.state.allowedMacosArchitecture,
      allowedPlatforms: this.state.allowedPlatforms.map((os) => os.displayName),
      reinstallAfterOSUpdate: this.state.reinstallAfterOSUpdate,
    };

    if (this.props.data.id) {
      this.ajaxClient
        .update(this.props.data.id, notificationSetting)
        .then(successHandler(this))
        .catch(errorHandler(this));
    } else {
      this.ajaxClient
        .create(notificationSetting)
        .then(successHandler(this))
        .catch(errorHandler(this));
    }
  }

  renderButtons() {
    const path = backPath(this.props.assignableObject);
    const backButton = (
      <a className="btn btn-default" href={path}>
        Back
      </a>
    );
    const saveButtons = (
      <React.Fragment>
        <a className="btn btn-default" href={path}>
          Cancel
        </a>
        <a
          href="#save"
          onClick={this.onSave}
          className="btn btn-primary save-button"
        >
          Save
        </a>
      </React.Fragment>
    );
    if (this.props.policy.update) {
      return (
        <div className="buttons">
          {this.props.policy.update ? saveButtons : backButton}
        </div>
      );
    }
  }

  clone(e) {
    e.preventDefault();
    this.ajaxClient
      .duplicate(this.props.data.id)
      .then(({ clonedResource }) => visitURL(clonedResource))
      .catch(errorHandler(this));
  }

  delete(e) {
    e.preventDefault();
    if (confirm("Are you sure?")) {
      this.ajaxClient
        .delete(this.props.data.id)
        .then(successHandler(this))
        .catch(errorHandler(this));
    }
  }

  renderActionItems() {
    const items = [
      { href: "#clone", action: this.clone, text: "Clone" },
      {
        href: "#delete",
        action: this.delete,
        text: "Delete",
      },
    ];

    if (this.props.policy.update && this.props.data.id) {
      return (
        <div id="top-buttons">
          <ActionDropDown text="Actions" items={items} />
        </div>
      );
    }
  }

  selectMinimumOSVersion(minimumMacosVersion) {
    this.setState({ minimumMacosVersion });
  }

  selectMaximumOSVersion(maximumMacosVersion) {
    this.setState({ maximumMacosVersion });
  }

  selectAllowedMacosArchitecture(allowedMacosArchitecture) {
    this.setState({ allowedMacosArchitecture });
  }

  onChangeReinstallAfterOSUpdate(e) {
    this.setState({ reinstallAfterOSUpdate: e.target.checked });
  }

  selectAllowedOs(osName) {
    const oses = this.state.allowedPlatforms;

    if (oses.find((os) => os.name == osName)) {
      this.setState({
        allowedPlatforms: oses.filter((os) => os.name != osName),
      });
    } else {
      oses.push(
        this.props.supportedPlatformOptions.find(
          (platform) => platform.name == osName
        )
      );
      this.setState({ allowedPlatforms: oses });
    }
  }

  renderIndex() {
    const { requirements, requirementNotes } = this.props;
    const disabled = !this.props.policy.update;
    return (
      <React.Fragment>
        <h1>
          {this.props.data.name} <small>Notification Settings</small>
        </h1>
        {this.renderActionItems()}

        <div className="well">
          <Requirements
            requirements={requirements}
            requirementNotes={requirementNotes}
          />
          <h3>General</h3>
          <form className="simple_form form-horizontal">
            <FormGroup
              title={"Name"}
              required={true}
              error={dig(["state", "errors", "name"], this)}
            >
              <input
                name="name"
                value={this.state.name || ""}
                onChange={this.setName}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup
              title={"Description"}
              error={dig(["state", "errors", "description"], this)}
              hint="Optional description presented to the end user."
            >
              <input
                name="description"
                value={this.state.description || ""}
                onChange={this.setDescription}
                disabled={disabled}
              />
            </FormGroup>
            <Checkbox
              name="is_device_profile"
              value={"is_device_profile"}
              checked={this.state.isDeviceProfile}
              disabled={disabled}
              onChange={this.setIsDeviceProfile}
            >
              For macOS devices, deploy as a device profile instead of a user
              profile.
            </Checkbox>

            <FormGroup disabled={disabled} inputClasses={["checkbox"]}>
              <div>
                <label className="boolean optional">
                  <input
                    disabled={disabled}
                    className="boolean optional"
                    type="checkbox"
                    name="reinstall_after_os_update"
                    checked={this.state.reinstallAfterOSUpdate}
                    onChange={this.onChangeReinstallAfterOSUpdate}
                  />
                  Re-install profile automatically after macOS software updates
                  are detected.
                </label>
              </div>
            </FormGroup>

            <h3>App Notification Settings</h3>

            <AppNotificationSettingsPagedTable
              data={this.state.appNotificationSettings}
              policy={this.props.policy}
              checkAppNotificationSetting={this.checkAppNotificationSetting}
              editApp={this.editApp}
              addApp={this.addApp}
              deleteApps={this.deleteApps}
            />

            <ProfileScope
              selectMinVersion={this.selectMinimumOSVersion}
              selectMaxVersion={this.selectMaximumOSVersion}
              selectAllowedMacosArchitecture={
                this.selectAllowedMacosArchitecture
              }
              selectAllowedOs={this.selectAllowedOs}
              minVersion={this.state.minimumMacosVersion}
              maxVersion={this.state.maximumMacosVersion}
              allowedArchitecture={this.state.allowedMacosArchitecture}
              minimumMacosVersions={this.props.minimumMacosVersions}
              maximumMacosVersions={this.props.maximumMacosVersions}
              allowedMacosArchitectures={this.props.allowedMacosArchitectures}
              allowedPlatforms={this.state.allowedPlatforms}
              supportedPlatformOptions={this.props.supportedPlatformOptions}
              supportedPlatforms={this.props.supportedPlatforms}
              policy={this.props.policy}
              errors={this.state.errors}
            />

            {this.renderButtons()}
          </form>
        </div>
      </React.Fragment>
    );
  }

  renderAppNotificationSetting() {
    let appNotificationSetting;
    const newRecord = this.state.editedAppIndex === -1;

    if (newRecord) {
      appNotificationSetting =
        AppNotificationSetting.newAppNotificationSetting();
    } else {
      appNotificationSetting =
        this.state.appNotificationSettings[this.state.editedAppIndex];
    }
    return (
      <AppNotificationSetting
        appNotificationSetting={appNotificationSetting}
        appNotificationSettings={this.state.appNotificationSettings}
        complete={this.completeAddApp}
        cancel={this.cancelAddApp}
        delete={this.deleteEditedApp}
        policy={this.props.policy}
        newRecord={newRecord}
        appError={this.state.appError}
      />
    );
  }

  render() {
    return this.state.editedAppIndex === null
      ? this.renderIndex()
      : this.renderAppNotificationSetting();
  }
}
