export const appVppLicenses = {
    read(appId, options) {
        return $.getJSON(AdminRoutes.adminAppVPPLicensesPath(appId, options));
    },

    destroyMultiple(appId, ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.revokeMultipleAdminAppVPPLicensesPath(appId),
            data:        JSON.stringify({ ids })
        })
    },
}