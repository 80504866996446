export const apps = {
    read(id) {
        return $.getJSON(AdminRoutes.adminAppPath(id));
    },

    destroy(id) {
        return $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      AdminRoutes.destroyAdminAppsPath(id),
        });
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            url:         AdminRoutes.destroyMultipleAdminAppsPath(),
            data:        JSON.stringify({ ids })
        });

    },

    directUploadAppCallback(appType, fileName, uuid, appId = null) {
        let url;
        if (appId) {
            url = AdminRoutes.updateAppCallbackAdminAppPath(appId)
        } else {
            url = AdminRoutes.newAppCallbackAdminAppsPath()
        }
        return $.ajax({
            url,
            method:      'POST',
            dataType:    'json',
            contentType: "application/json",
            data:        JSON.stringify({ appType, fileName, uuid })
        });
    },
};
