export const apiKeys = {
    read(idOrOptions) {
        if (typeof idOrOptions === 'number') {
            return $.getJSON(AdminRoutes.adminAPIKeyPath(idOrOptions));
        } else {
            return $.getJSON(AdminRoutes.adminAPIKeysPath(idOrOptions));
        }
    },

    reveal(id) {
        return $.getJSON(AdminRoutes.revealAdminAPIKeyPath({ id: id }));
    }
};
