import * as controllers from "./controllers";
import notifications from "./notifications";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";

const router = function() {
    const $body      = $('body');
    const controller = $body.attr('data-controller');
    const action     = $body.attr('data-action');

    if (typeof controllers[controller] == "object" && typeof controllers[controller][action] == "function") {
        controllers[controller][action]();
    }
    if (typeof controllers['shared_actions'][action] == "function") {
        controllers['shared_actions'][action]()
    }
};

Rails.start();
Turbolinks.start();

/* Place code here that should be run with each new page (turbolinks) */
$(function() {
    $.ajaxSetup({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    });

    setTimeout(notifications.fn.fetchNotifications, 60000);
    const turbolinksLoad = function() {
        notifications.fn.wireButton();
        MDM.fn.selectTab();
        $('[rel*=tooltip]').tooltip();

        router();
    };
    document.addEventListener("turbolinks:load", function() {
        turbolinksLoad();
    });
    turbolinksLoad();

    $(window).on("popstate", function(e) {
        if (e.originalEvent.state !== null) {
            if (e.originalEvent.state.manualPushStateRequest) {
                location.reload();
            }
        }
    });

});

// Google Tag Manager trigger for Turbolinks
window.dataLayer = window.dataLayer || [];
$(document).on('page:change', function() {
    const url = window.location.href;
    dataLayer.push({
        'event':      'pageView',
        'virtualUrl': url
    });
});

