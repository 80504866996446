import React from 'react'
import Typography from '@mui/material/Typography'
import { padding as paperPadding } from './paper'

const Header = (props) => {
  return (
    <>
      <img src="/cloud.svg" height={paperPadding}/>
      <Typography
        fontSize='1.5rem'
        fontWeight={700}
        margin={1}
        textAlign='center'
        variant="h1"
        {...props}
      />
    </>
  )
}

export default Header
