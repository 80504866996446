


export const accessibility = {
    show() {
        this.fn.wireForms();
        let sticky_key = document.getElementById("accessibility_sticky_key");
        let slow_key = document.getElementById("accessibility_slow_key");
        let mouse_driver = document.getElementById("accessibility_mouse_driver")
        sticky_key.addEventListener("change", this.fn.wireForms);
        slow_key.addEventListener("change", this.fn.wireForms);
        mouse_driver.addEventListener("change", this.fn.wireForms);
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },
    fn: {
        wireForms() {
            let sticky_key_elements = [];
            const sticky_key = document.getElementById("accessibility_sticky_key");
            sticky_key_elements.push(document.getElementById("accessibility_sticky_key_beep_on_modifier"));
            sticky_key_elements.push(document.getElementById("accessibility_sticky_key_show_window"));

            let slow_key_elements = [];
            const slow_key = document.getElementById("accessibility_slow_key");
            slow_key_elements.push(document.getElementById("accessibility_slow_key_beep_on"));
            slow_key_elements.push(document.getElementById("accessibility_slow_key_delay"));

            let mouse_driver_elements = [];
            const mouse_driver = document.getElementById("accessibility_mouse_driver");
            mouse_driver_elements.push(document.getElementById("accessibility_mouse_driver_initial_delay"));
            mouse_driver_elements.push(document.getElementById("accessibility_mouse_driver_max_speed"));
            mouse_driver_elements.push(document.getElementById("accessibility_mouse_driver_cursor_size"));
            mouse_driver_elements.push(document.getElementById("accessibility_mouse_driver_ignore_trackpad"));

            if(sticky_key.checked){
                enableInput(...sticky_key_elements)
            }else{
                disableInput(...sticky_key_elements);
            }

            if(slow_key.checked){
                enableInput(...slow_key_elements);
            }else{
                disableInput(...slow_key_elements);
            }

            if(mouse_driver.checked){
                enableInput(...mouse_driver_elements)
            }else{
                disableInput(...mouse_driver_elements);
            }
        },
    }
};
// Enable/Disable of sticky key inputs based on if the main "Sticky key" form value is  checked or not

const disableInput = (...elements) => {

    elements.forEach(el => {
        el.disabled = true;
        document.getElementsByClassName(el.id)[0].classList.add("disabled_input");
    });
}

const enableInput = (...elements) => {
    elements.forEach(el => {
        el.disabled = false;
        document.getElementsByClassName(el.id)[0].classList.remove("disabled_input");
    });
};

