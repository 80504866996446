import { toggleDOMElement } from "../../shared/functions";
export const extensible_single_sign_ons = {
    show() {
        this.fn.wireScriptEditor()
        this.fn.wireTypeFields()
        toggleDOMElement(document.getElementById('extensible_single_sign_on_enable_platform_sso'), document.getElementById('platform-sso-fields'))
        toggleDOMElement(document.getElementById('extensible_single_sign_on_use_shared_device_keys'), document.getElementById('shared-device-key-fields'))
    },

    new() {
        this.show();
    },

    update() {
        this.show();
    },

    create() {
        this.show();
    },

    fn: {
        wireScriptEditor() {
            const scriptEditor = document.querySelector('.plist-editor')

            const aceEditorElement = scriptEditor.querySelector('.ace-editor');
            const aceEditor  = ace.edit(aceEditorElement)
            aceEditor.setTheme("ace/theme/tomorrow")
            aceEditor.setReadOnly(aceEditorElement.classList.contains('disabled'))
            aceEditor.session.setUseWorker(false)
            aceEditor.session.setMode('ace/mode/xml');

            const scriptHolder = scriptEditor.querySelector('.script-holder')
            aceEditor.getSession().setValue(scriptHolder.value)
            aceEditor.getSession().on('change', () => {
                scriptHolder.value = aceEditor.getSession().getValue()
            })
        },

        wireTypeFields() {
            this.toggleTypeFields()
            document.getElementById('extensible_single_sign_on_sso_type').addEventListener('change', this.toggleTypeFields)
        },

        toggleTypeFields() {
            const ssoTypeSelect = document.getElementById('extensible_single_sign_on_sso_type')
            const hostsField    = document.querySelector('.extensible_single_sign_on_hosts')
            const urlsField     = document.querySelector('.extensible_single_sign_on_urls')
            const realmField     = document.querySelector('.extensible_single_sign_on_realm')

            if (ssoTypeSelect.value === 'Redirect') {
                urlsField.hidden = false
                hostsField.hidden = true
                realmField.hidden = true
            } else if (ssoTypeSelect.value === 'Credential') {
                urlsField.hidden = true
                hostsField.hidden = false
                realmField.hidden = false
            } else {
                urlsField.hidden = true
                hostsField.hidden = true
                realmField.hidden = true
            }
        }
    }
};