import React, { useState } from 'react'
import DynamicList from "../shared/dynamic_list"

export default function DomainRealmMapping({ data, policy }) {
  const [rows, setRows] = useState(data.rows)
  const headers = [
    { displayName: 'Realm name', key: 'realm' },
    { displayName: 'Domains', key: 'domains', multiline: true }
  ]
  const onChangeItem = (updatedItem, updatedIndex) => {
    setRows(rows.map((row, index) => index === updatedIndex ? updatedItem : row))
  }

  const onRemoveItem = (removedItem, removedIndex) => {
    setRows(rows.filter((item, index) => index !== removedIndex))
  }

  const onAddItem = () => {
    const newItem = {
      realm: '',
      domains: ''
    }

    setRows([...rows, newItem])
  }

  return (
    <DynamicList
      fieldName={ 'extensible_single_sign_on_kerberos[domain_realm_mapping_attributes]' }
      headers={ headers }
      rows={ rows }
      policy={ policy }
      onChangeItem={ onChangeItem }
      onRemoveItem={ onRemoveItem }
      onAddItem={ onAddItem }
    />
  )
}