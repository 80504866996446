import { hide, show } from '../../shared/functions'

const updateForm = () => {
    const select = name => document.querySelectorAll(`div.form-group.vpn_provider_${ name }`);

    // Connection Type

    const ikeV2Fields = [
        'ike_v2_authentication_method',
        'ike_v2_certificate_type',
        'ike_v2_dead_peer_detection_rate',
        'ike_v2_disable_mobike',
        'ike_v2_disable_redirect',
        'ike_v2_enable_certificate_revocation_check',
        'ike_v2_enable_fallback',
        'ike_v2_enable_pfs',
        'ike_v2_encryption_algorithm',
        'ike_v2_extended_auth_enabled',
        'ike_v2_mtu',
        'ike_v2_sa_diffie_hellman_group',
        'ike_v2_sa_encryption_algorithm',
        'ike_v2_sa_integrity_algorithm',
        'ike_v2_sa_lifetime_in_minutes',
        'ike_v2_tls_maximum_version',
        'ike_v2_tls_minimum_version',
        'ike_v2_use_configuration_attribute_internal_ip_subnet',
        'remote_identifier',
        'shared_secret',
    ]

    const connectionTypeFields = ['encryption_level',
        'group_name',
        'shared_secret',
        'prompt_for_password',
        'authentication_type',
        'send_all_traffic'].concat(ikeV2Fields);
    const connectionTypeShow   = {
        'l2tp':               ['authentication_type', 'send_all_traffic', 'shared_secret'],
        'pptp':               ['encryption_level', 'authentication_type', 'send_all_traffic'],
        'ipsec':              ['group_name', 'shared_secret', 'prompt_for_password'],
        'anyconnect':         ['group_name', 'shared_secret'],
        'ike_v2':             ikeV2Fields,
        'netmotion_mobility': ['send_all_traffic']
    };
    const connectionType       = document.getElementById('vpn_provider_connection_type').value;

    connectionTypeFields.forEach((field, index) => {
        const shouldShow = connectionTypeShow[connectionType] && connectionTypeShow[connectionType].indexOf(field) > -1;
        if (shouldShow) {
            show(select(field));
        } else {
            hide(select(field));
        }
    });

    const ikeV2Form             = document.getElementById('ike_v2_form');
    const moveSharedSecretField = (afterAuthField = false) => {
        const sharedSecretField        = document.querySelector('.vpn_provider_shared_secret');
        const ikeV2EAPField  = document.querySelector('.vpn_provider_ike_v2_extended_auth_enabled')
        const promptForPassword        = document.querySelector('.vpn_provider_prompt_for_password')
        const mainForm                 = document.querySelector('.well .simple_form')
        const ikeForm                  = document.getElementById('ike_v2_form')

        if (afterAuthField) {
            ikeForm.insertBefore(sharedSecretField, ikeV2EAPField)
        } else {
            mainForm.insertBefore(sharedSecretField, promptForPassword)
        }
    }
    if (connectionType === 'ike_v2') {
        show(ikeV2Form)
        updateIkeV2Form();
        moveSharedSecretField(true)
    } else {
        hide(ikeV2Form)
        moveSharedSecretField(false)
    }

    const proxyType = document.getElementById('vpn_provider_proxy_type').value;

    document.querySelectorAll('div.form-group.vpn_provider_proxy_server')
    switch (proxyType) {
        case 'none':
            hide(select('proxy_server'));
            hide(select('proxy_port'));
            hide(select('proxy_url'));
            break;
        case 'manual':
            show(select('proxy_server'));
            show(select('proxy_port'));
            hide(select('proxy_url'));
            break;
        case 'auto':
            hide(select('proxy_server'));
            hide(select('proxy_port'));
            show(select('proxy_url'));
            break;
    }
}

const updateIkeV2Form = () => {
    const authMethod = document.getElementById('vpn_provider_ike_v2_authentication_method')
    const eap        = document.getElementById('vpn_provider_ike_v2_extended_auth_enabled')

    const showSelectedAuthMethod = () => {
        const eapSpecificFields = document.getElementById('ike_v2_extended_auth_fields')
        const hint = document.querySelector('.vpn_provider_ike_v2_authentication_method .help-block')
        authMethod.value === 'certificate' ? show(hint) : hide(hint)
        eap.checked ? show(eapSpecificFields) : hide(eapSpecificFields)
    }
    [authMethod, eap].forEach(e => e.addEventListener('change', showSelectedAuthMethod))
    showSelectedAuthMethod()

    const showSharedSecret = () => {
        const sharedSecretField = document.querySelector('.vpn_provider_shared_secret');
        if (authMethod.value === 'shared_secret' || (authMethod.value === 'none' && !eap.checked)) {
            show(sharedSecretField)
        } else {
            hide(sharedSecretField)
        }
    }
    [authMethod, eap].forEach(e => e.addEventListener('change', showSharedSecret))
    showSharedSecret()

    const lifeTime             = document.getElementById('vpn_provider_ike_v2_sa_lifetime_in_minutes')
    const enforceLifeTimeRange = () => {
        if (lifeTime.value > 1440) {
            lifeTime.value = 1440
        } else if (lifeTime.value < 10) {
            lifeTime.value = 10
        }
    }

    lifeTime.addEventListener('change', enforceLifeTimeRange)
    enforceLifeTimeRange()
}

export const vpn_providers = {
    new() {
        this.wireAndUpdateForm();
    },
    show() {
        this.wireAndUpdateForm();
    },
    create() {
        this.wireAndUpdateForm();
    },
    update() {
        this.wireAndUpdateForm();
    },
    wireAndUpdateForm() {
        document.querySelectorAll('#vpn_provider_connection_type, #vpn_provider_proxy_type')
            .forEach(e => e.addEventListener('change', updateForm));
        updateForm();
    }
};